// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-popup-dlg .MuiPaper-root {
    max-width: 800px;
    width: 800px;
    padding: 20px;

}
.dashboard-popup-dlg .MuiPaper-root h2 {
    padding: 1rem;
}
@media (max-width: 769px) {
    .dashboard-popup-dlg .MuiPaper-root {
        margin: 10px;
        padding: 10px;
    }
    .dashboard-popup-dlg .MuiPaper-root h2 {
        padding: 1rem;
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/layouts/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;;AAEjB;AACA;IACI,aAAa;AACjB;AACA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;IACA;QACI,aAAa;QACb,eAAe;IACnB;AACJ","sourcesContent":[".dashboard-popup-dlg .MuiPaper-root {\n    max-width: 800px;\n    width: 800px;\n    padding: 20px;\n\n}\n.dashboard-popup-dlg .MuiPaper-root h2 {\n    padding: 1rem;\n}\n@media (max-width: 769px) {\n    .dashboard-popup-dlg .MuiPaper-root {\n        margin: 10px;\n        padding: 10px;\n    }\n    .dashboard-popup-dlg .MuiPaper-root h2 {\n        padding: 1rem;\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
