// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.getError_md_start {
    font-size: 14px;
    color: red;
}
.bg {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
}
.login-dlg {
    background-color: white;
    padding: 20px 0px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    max-width: 500px;
}
@media (max-width: 769px) {
    .bg > div {
        padding: 10px !important;
    }
    .login-dlg {
        padding: 10px 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/layouts/authentication/auth.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;AACd;AACA;IACI,mDAA6C;IAC7C,sBAAsB;IACtB,4BAA4B;AAChC;AACA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".getError_md_start {\n    font-size: 14px;\n    color: red;\n}\n.bg {\n    background: url(\"../../assets/images/bg.png\");\n    background-size: cover;\n    background-repeat: no-repeat;\n}\n.login-dlg {\n    background-color: white;\n    padding: 20px 0px;\n    border-radius: 20px;\n    display: flex;\n    justify-content: center;\n    max-width: 500px;\n}\n@media (max-width: 769px) {\n    .bg > div {\n        padding: 10px !important;\n    }\n    .login-dlg {\n        padding: 10px 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
