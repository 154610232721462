import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyADQ3kiCDVuk-_ZUdffmr2q8yakxIP1p0I",
  authDomain: "udfx-20e12.firebaseapp.com",
  projectId: "udfx-20e12",
  storageBucket: "udfx-20e12.appspot.com",
  messagingSenderId: "486967821033",
  appId: "1:486967821033:web:6bb2ef4774cedd56356678",
  measurementId: "G-17HT4JRSQ8"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const twitterProvider = new TwitterAuthProvider();
export const facebookProvider = new FacebookAuthProvider();