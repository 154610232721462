import { ArrowDropUp } from "@mui/icons-material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import defaultUserIcon from "assets/images/default/default-user.png";

function TopAffiliateCard({ avatar, username, ranking }) {
    return (
        <ArgonBox p={2} border="1px solid rgba(138, 145, 158, 0.2)" borderRadius="8px">
            <ArgonBox display="flex" justifyContent="flex-end" alignItems="center">
                <ArgonTypography component="span" fontSize="20px" sx={{ color: "#4CD964", lineHeight: "15px" }}>
                    <ArrowDropUp />
                </ArgonTypography>
                <ArgonTypography
                    sx={{
                        color: "#4CD964",
                        fontSize: "14px",
                        lineHeight: "20px",
                        textAlign: "right",
                    }}
                >
                    New rank!
                </ArgonTypography>
            </ArgonBox>
            <ArgonTypography
                sx={{
                    color: "#5B616E",
                    fontSize: "10px",
                    lineHeight: "20px",
                }}
            >
                Congratulations!
            </ArgonTypography>
            <ArgonBox
                mt={1}
                mb={2}
                display="flex"
                justifyContent="space-between"
            >
                <ArgonBox
                    component="img"
                    src={avatar || defaultUserIcon}
                    width="40px"
                    height="40px"
                />
                <ArgonBox>
                    <ArgonTypography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "24px",
                        }}
                    >
                        {username}
                    </ArgonTypography>
                    <ArgonTypography
                        sx={{
                            color: "#5B616E",
                            fontSize: "14px",
                            lineHeight: "20px",
                        }}
                    >
                        Ranking {ranking}
                    </ArgonTypography>
                </ArgonBox>
            </ArgonBox>
        </ArgonBox>
    );
}

export default TopAffiliateCard;
