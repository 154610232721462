// @mui material components
import Card from "@mui/material/Card";


import ArgonBox from "components/ArgonBox";

import PropTypes from "prop-types";

function TabPanel({ value, index, children }) {
  return (
    <ArgonBox hidden={value !== index}>
      {value === index && (
        <Card style={{ overflow: "unset" }}>
          <ArgonBox p={5}>{children}</ArgonBox>
        </Card>
      )}
    </ArgonBox>
  );
}

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default TabPanel;
