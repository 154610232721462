import initialState from '../initialState';
import actionPack from '../actions/actions';
const actions = actionPack.wallet;
export default function walletReducer(state=initialState.wallet, action) {
    switch (action.type) {
        case actions.GET_COINS_BY_NETWORK:
            return {
                ...state,
                coins: action.payload.data
            };
        case actions.GET_NETWORK_LIST:
            return {
                ...state,
                networks: action.payload
            };
        case actions.GET_PAYMENT_OPTIONS:
            return {
                ...state,
                paymentOptions: action.payload
            };
      default:
            return state;
    }
  }