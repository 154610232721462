import React, { useEffect } from "react";
import { LinkedInApi, NodeServer } from "./config";
import axios from "axios";
import ArgonButton from "components/ArgonButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function LinkedIn(props) {
    useEffect(() => {
        if (window.opener && window.opener !== window) {
            const code = getCodeFromWindowURL(window.location.href);
            window.opener.postMessage({ type: "code", code: code }, "*");
            window.close();
        }
        window.addEventListener("message", handlePostMessage);
    }, [window.opener]);

    const handlePostMessage = (event) => {
        if (event.data.type === "code") {
            const { code } = event.data;
            getUserCredentials(code);
        }
    };

    const getCodeFromWindowURL = (url) => {
        const popupWindowURL = new URL(url);
        return popupWindowURL.searchParams.get("code");
    };

    const showPopup = () => {
        const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
        const oauthUrl1 = `${oauthUrl}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUrl}`;
        const width = 450,
            height = 730,
            left = window.screen.width / 2 - width / 2,
            top = window.screen.height / 2 - height / 2;
        window.open(
            oauthUrl1,
            "Linkedin",
            "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left
        );
    };

    const getUserCredentials = (code) => {
        try {
            props.setLoading(true);

            axios
                .get(`${NodeServer.baseURL + NodeServer.getUserCredentials}?code=${code}`)
                .then((res) => {
                    if (res.data.status) {
                        const user = res.data.user;
                        var userInfo = {};

                        userInfo.username = "";
                        userInfo.first_name = "";
                        userInfo.last_name = "";
                        userInfo.email = user.email;
                        userInfo.password = "";
                        userInfo.email_verified = 1;

                        console.log(userInfo);
                        console.log(user);
                        props.registerMutation.mutate(userInfo);
                    }else{
                        props.setLoading(false)
                    }
                })
        } catch (error) {
            props.setLoading(false);
        }
    };

    return (
        <>
            <ArgonButton color="success" size="small" fullWidth>
                <LinkedInIcon />
            </ArgonButton>
        </>
    );
}
