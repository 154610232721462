import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, InputLabel, OutlinedInput } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// icons
import cardIcon1 from "assets/images/icons/credit-card/ic_card_1.png";
import cardIcon2 from "assets/images/icons/credit-card/ic_card_1.png";
import cvvIcon from "assets/images/icons/credit-card/ic_card_1.png";
import calendarIcon from "assets/images/icons/credit-card/ic_calendar.png";

function CreditCard({ open, onClose }) {
    const [amount, setAmount] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [expDate, setExpDate] = useState("");
    const [cvv, setCvv] = useState("");

    const handlePrevious = () => {
        onClose();
    };
    return (
        <Dialog className="dialog" open={open} onClose={onClose}>
            <DialogTitle className="dialog-title">Add A Credit/Debit Card</DialogTitle>
            <DialogContent className="dialog-content">
                <ArgonBox className="form-box">
                    <InputLabel className="label">Card Holder Name</InputLabel>
                    <Grid container spacing={1.5}>
                        <Grid item xs={6} lg={6}>
                            <OutlinedInput
                                className="input"
                                value={firstName}
                                placeholder="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                startAdornment={
                                    <ArgonBox className="input-icon">
                                        <ArgonBox component="img" src={cardIcon1} />
                                    </ArgonBox>
                                }
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <OutlinedInput
                                className="input"
                                value={lastName}
                                placeholder="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                                startAdornment={
                                    <ArgonBox className="input-icon">
                                        <ArgonBox component="img" src={cardIcon1} />
                                    </ArgonBox>
                                }
                            />
                        </Grid>
                    </Grid>
                </ArgonBox>
                <ArgonBox className="form-box" mt={2}>
                    <InputLabel className="label">Card Number</InputLabel>
                    <OutlinedInput
                        className="input"
                        value={cardNumber}
                        placeholder="Card Number"
                        onChange={(e) => setCardNumber(e.target.value)}
                        startAdornment={
                            <ArgonBox className="input-icon">
                                <ArgonBox component="img" src={cardIcon2} />
                            </ArgonBox>
                        }
                    />
                </ArgonBox>
                <ArgonBox className="form-box" mt={2}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={6} lg={6}>
                            <InputLabel className="label">Expiry Date</InputLabel>
                            <OutlinedInput
                                className="input"
                                value={expDate}
                                placeholder="MM-YYYY"
                                onChange={(e) => setExpDate(e.target.value)}
                                startAdornment={
                                    <ArgonBox className="input-icon">
                                        <ArgonBox component="img" src={cardIcon2} />
                                    </ArgonBox>
                                }
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <InputLabel className="label">CVV</InputLabel>
                            <OutlinedInput
                                className="input"
                                value={cardNumber}
                                placeholder="CVV"
                                onChange={(e) => setCardNumber(e.target.value)}
                                startAdornment={
                                    <ArgonBox className="input-icon">
                                        <ArgonBox component="img" src={cardIcon2} />
                                    </ArgonBox>
                                }
                            />
                        </Grid>
                    </Grid>
                </ArgonBox>
                <ArgonBox mt={10}>
                    <ArgonButton className="dialog-button" fullWidth onClick={() => setStep(2)}>
                        Continue
                    </ArgonButton>
                </ArgonBox>
            </DialogContent>
        </Dialog>
    );
}

export default CreditCard;
