import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function FeaturedNodeCard({ logo, name, sales, percent }) {
    return (
        <ArgonBox p={2} border="1px solid rgba(138, 145, 158, 0.2)" borderRadius="8px">
            <ArgonTypography
                sx={{
                    color: "#5B616E",
                    fontSize: "14px",
                    lineHeight: "20px",
                }}
            >
                Total Sales
            </ArgonTypography>
            <ArgonBox
                mt={1}
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <ArgonBox component="img" src={logo} width="70px" height="70px" />
                <ArgonBox>
                    <ArgonTypography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "24px",
                        }}
                    >
                        {name}
                    </ArgonTypography>
                    <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
                        <ArgonTypography
                            sx={{
                                color: "#5B616E",
                                fontSize: "14px",
                                lineHeight: "20px",
                            }}
                        >
                            {sales}
                        </ArgonTypography>
                        <ArgonBox display="flex" alignItems="center">
                            <ArgonTypography
                                sx={{
                                    fontSize: "20px",
                                    color: Number(percent) > 0 ? "#4CD964" : "#D94C4C",
                                    lineHeight: "15px",
                                }}
                            >
                                {Number(percent) > 0 ? <ArrowDropUp /> : <ArrowDropDown />}
                            </ArgonTypography>
                            <ArgonTypography
                                sx={{
                                    color: Number(percent) > 0 ? "#4CD964" : "#D94C4C",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: "600",
                                }}
                            >
                                {percent}%
                            </ArgonTypography>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </ArgonBox>
        </ArgonBox>
    );
}

export default FeaturedNodeCard;
