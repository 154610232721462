import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { light } = colors;

const linearProgress = {
    styleOverrides: {
        root: {
            height: pxToRem(3),
            borderRadius: borderRadius.md,
            overflow: "visible",
            position: "relative",
        },

        colorPrimary: {
            backgroundColor: light.main,
        },

        colorSecondary: {
            backgroundColor: light.main,
        },

        bar: {
            height: "100%",
            borderRadius: borderRadius.sm,
            position: "absolute",
            transform: `translate(0, 0) !important`,
            transition: "width 0.6s ease !important",
        },
    },
};

export default linearProgress;
