import { useState } from "react";
import { useMutation } from "react-query";

import { Grid } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";

import ArgonTypography from "components/ArgonTypography";
import { forgotPassword } from "service/apiCaller";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [isSent, setIsSent] = useState(false);

    const sendCodeMutation = useMutation(forgotPassword, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setIsSent(true);
            }
        },
    });
    const handleSendLink = () => {
        const payload = { email: email };
        sendCodeMutation.mutate(payload);
    };

    return (
        <IllustrationLayout
            title={!isSent ? "Forgot your password?" : "Password reset email sent"}
            description={
                !isSent
                    ? "Please enter the email address you registered with."
                    : "Please check your email to reset your password."
            }
            illustration={{
                image: bgImage,
            }}
        >
            {!isSent ? (
                <ArgonBox component="form" role="form">
                    <ArgonBox>
                        <ArgonInput
                            type="email"
                            placeholder="Email"
                            size="large"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </ArgonBox>
                    <ArgonBox mt={3}>
                        <ArgonButton
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={handleSendLink}
                        >
                            Send
                        </ArgonButton>
                    </ArgonBox>
                </ArgonBox>
            ) : (
                <ArgonBox textAlign="right">
                    <ArgonTypography
                        component={Link}
                        to="/login"
                        variant="button"
                        color="primary"
                        fontWeight="medium"
                    >
                        Go Back to Login
                    </ArgonTypography>
                </ArgonBox>
            )}
        </IllustrationLayout>
    );
}
