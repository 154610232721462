import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Divider } from "@mui/material";


import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Icons
import facebookIcon from "assets/images/icons/logos_facebook.png";
import twitterIcon from "assets/images/icons/logos_twitter.png";
import linkedinIcon from "assets/images/icons/logos_linkedin.png";
import { useAuthContext } from "context/auth";
import SnackbarUtils from "utils/SnackbarUtils";

import ShareImage from "assets/images/share.png";
import * as clipboard from "clipboard-polyfill";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { CLIENT_URL } from "../../../../constant";

const shareButtonStyle = {
    background: "#EFEFF4",
    borderRadius: "8px",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: "600",
    color: "#666666",
};

function BigInviteCard() {
    const { user } = useAuthContext();
    const [friendEmail, setFriendEmail] = useState("");
    const link = CLIENT_URL + "/register/" + user.username;

    const handleCopyLink = () => {
        clipboard.writeText(link).then(
            () => {
                SnackbarUtils.success("Copied!");
            },
            () => {
                SnackbarUtils.error("Failed to copy");
            }
        );
    };

    return (
        <Card>
            <ArgonBox p={3}>
                <ArgonBox
                    p={3}
                    borderRadius="8px"
                    backgroundColor="rgba(84, 107, 234, 0.1) !important"
                    position="relative"
                >
                    <ArgonTypography
                        variant="h3"
                        style={{
                            fontSize: "24px",
                            lineHeight: "36px",
                            fontWeight: "500",
                            color: "black",
                        }}
                        mb={2}
                    >
                        BE AN AFFILIATE ! SHARE YOUR LINK TODAY!
                    </ArgonTypography>
                    <ArgonBox textAlign="left">
                        <ArgonTypography
                            style={{
                                fontSize: "12px",
                                lineHeight: "18px",
                                color: "#6E798C",
                                maxWidth: "300px",
                            }}
                            mb="28px"
                        >
                            Kickstart your affiliate earnings and extend the benefits and rewards to
                            your friends by recommending them. Take the step to become an affiliate
                            today and unlock a world of rewards!
                        </ArgonTypography>
                        <ArgonTypography
                            color="primary"
                            fontWeight="bold"
                            fontSize="14px"
                            lineHeight="19px"
                        >
                            How it works?
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox
                        component="img"
                        src={ShareImage}
                        position="absolute"
                        top={5}
                        right={20}
                        sx={({ breakpoints }) => ({
                            [breakpoints.down("md")]: {
                                display: "none",
                            },
                        })}
                    />
                </ArgonBox>
                <ArgonBox mb={2}>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={9} lg={10}>
                            <ArgonInput
                                color="primary"
                                value={link}
                                style={{ padding: "12px 24px" }}
                                readOnly
                            ></ArgonInput>
                        </Grid>
                        <Grid item xs={3} lg={2}>
                            <ArgonButton
                                style={{ height: "48px", width: "100%" }}
                                color="primary"
                                onClick={handleCopyLink}
                            >
                                Copy link
                            </ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
                <Divider
                    sx={{
                        height: "unset",
                        backgroundImage: "unset !important",
                        "& > span": { fontSize: "12px", lineHeight: "18px", color: "#6E798C" },
                        "&::before, &::after": { borderColor: "#C7C7CC" },
                    }}
                >
                    OR
                </Divider>
                <ArgonBox mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={9} lg={10}>
                            <ArgonInput
                                color="primary"
                                placeholder="Enter the email of your friend"
                                style={{ padding: "12px 24px" }}
                                value={friendEmail}
                                onChange={(event) => setFriendEmail(event.target.value)}
                            ></ArgonInput>
                        </Grid>
                        <Grid item xs={3} lg={2}>
                            <ArgonButton
                                style={{
                                    height: "48px",
                                    width: "100%",
                                    background: "rgba(84, 107, 234, 0.1)",
                                    color: "#546BEA",
                                }}
                            >
                                Send
                            </ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
                <ArgonBox>
                    <Grid container spacing={3}>
                        <Grid item xs={4} lg={4}>
                            <FacebookShareButton url={link} style={shareButtonStyle}>
                                <ArgonBox component="img" src={facebookIcon} mr={1} /> Share
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <TwitterShareButton url={link} style={shareButtonStyle}>
                                <ArgonBox component="img" src={twitterIcon} mr={1} /> Tweet
                            </TwitterShareButton>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <LinkedinShareButton url={link} style={shareButtonStyle}>
                                <ArgonBox component="img" src={linkedinIcon} mr={1} />
                                Post
                            </LinkedinShareButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </ArgonBox>
        </Card>
    );
}

export default BigInviteCard;
