import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";


import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonTypography from "components/ArgonTypography";


import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function Table({ columns, rows, handleDeposit, handleWithdraw, handleTransfer }) {
    const { size, fontWeightBold } = typography;
    const { borderWidth } = borders;
    
    const renderColumns = columns.map(({ name, align, width }, key) => {
        let pl;
        let pr;

        if (key === 0) {
            pl = 3;
            pr = 3;
        } else if (key === columns.length - 1) {
            pl = 3;
            pr = 3;
        } else {
            pl = 1;
            pr = 1;
        }

        return (
            <ArgonBox
                key={`row_${name}`}
                component="th"
                width={width || "auto"}
                pt={1.5}
                pb={1.25}
                pl={align === "left" ? pl : 3}
                pr={align === "right" ? pr : 3}
                textAlign={align}
                fontSize={"16px"}
                fontWeight={fontWeightBold}
                color="#5B616E"
                opacity={1}
                sx={({ palette: { light } }) => ({
                    borderBottom: `${borderWidth[1]} solid ${light.main}`,
                })}
            >
                {name}
            </ArgonBox>
        );
    });

    const renderRows = rows.map((row, key) => {
        const rowKey = `row-${key}`;

        const tableRow = columns.map(({ name, align }) => {
            let template;

            if (Array.isArray(row[name])) {
                template = (
                    <ArgonBox
                        key={uuidv4()}
                        component="td"
                        p={1}
                        sx={({ palette: { light } }) => ({
                            borderBottom: row.hasBorder
                                ? `${borderWidth[1]} solid ${light.main}`
                                : null,
                        })}
                    >
                        <ArgonBox display="flex" alignItems="center" py={0.5} px={1}>
                            <ArgonBox mr={2}>
                                <ArgonAvatar
                                    src={row[name][0]}
                                    name={row[name][1]}
                                    variant="rounded"
                                    size="sm"
                                />
                            </ArgonBox>
                            <ArgonTypography
                                variant="button"
                                fontWeight="medium"
                                sx={{ width: "max-content" }}
                            >
                                {row[name][1]}
                            </ArgonTypography>
                        </ArgonBox>
                    </ArgonBox>
                );
            } else {
                template = (
                    <ArgonBox
                        key={uuidv4()}
                        component="td"
                        p={1}
                        textAlign={align}
                        lineHeight={0.65}
                        sx={({ palette: { light } }) => ({
                            borderBottom: row.hasBorder
                                ? `${borderWidth[1]} solid ${light.main}`
                                : null,
                            verticalAlign: "middle",
                        })}
                    >
                        <ArgonTypography
                            variant="button"
                            fontWeight="regular"
                            color="secondary"
                            sx={{ display: "inline-block", width: "max-content" }}
                        >
                            {row[name]}
                        </ArgonTypography>
                    </ArgonBox>
                );
            }

            return template;
        });

        return <TableRow key={rowKey}>{tableRow}</TableRow>;
    });

    return useMemo(
        () => (
            <TableContainer>
                <MuiTable>
                    <ArgonBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </ArgonBox>
                    <TableBody>{renderRows}</TableBody>
                </MuiTable>
            </TableContainer>
        ),
        [columns, rows]
    );
}

// Setting default values for the props of Table
Table.defaultProps = {
    columns: [],
    rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
