
import Web3 from "web3"
const web3 = new Web3(window.ethereum);
export const toWei = (_amount) => {
    if (_amount.length > 18)
        _amount = _amount.substr(17)
    console.log(_amount)
    return web3.utils.toWei(_amount, "ether");
}
export const fromWei = (_amount) => {
    return web3.utils.fromWei(_amount, "ether");
}
export const stringHexToNumber = (_hexString) => {
    return parseInt(_hexString, 16);
}
export const toHex = (_amount) => {
    return web3.utils.toHex(_amount);
}