import { useState } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";

import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

import { useArgonController, setMiniSidenav } from "context";

// Images
// import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import CustomizedMenus from "../Menu";

function DashboardNavbar({ title, isMini }) {
    const [controller, dispatch] = useArgonController();
    const { miniSidenav } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            // open={Boolean(openMenu)}
            open={false}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            <NotificationItem
                // image={<img src={team2} alt="person" />}
                title={["New message", "from Laur"]}
                date="13 minutes ago"
                onClick={handleCloseMenu}
            />
            <NotificationItem
                image={<img src={logoSpotify} alt="person" />}
                title={["New album", "by Travis Scott"]}
                date="1 day"
                onClick={handleCloseMenu}
            />
            <NotificationItem
                color="secondary"
                image={
                    <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                        payment
                    </Icon>
                }
                title={["", "Payment successfully completed"]}
                date="2 days"
                onClick={handleCloseMenu}
            />
        </Menu>
    );

    return (
        <AppBar sx={navbar}>
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <ArgonBox color="dark">
                    <Breadcrumbs
                        icon="home"
                        title={title && title != "" ? title : route[route.length - 1]}
                        route={route}
                    />
                </ArgonBox>
                {isMini ? null : (
                    <ArgonBox sx={(theme) => navbarRow(theme)}>
                        <ArgonInput
                            placeholder="Search"
                            startAdornment={
                                <Icon fontSize="small" style={{ marginRight: "10px" }}>
                                    search
                                </Icon>
                            }
                        />
                        <ArgonBox display="flex">
                            <IconButton
                                size="small"
                                color="dark"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
                            </IconButton>
                            <IconButton
                                size="small"
                                color="dark"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <Icon>notifications</Icon>
                            </IconButton>

                            <CustomizedMenus />

                            {renderMenu()}
                        </ArgonBox>
                    </ArgonBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: true,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
