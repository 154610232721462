import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";
import { connect } from "react-redux";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { Checkbox, Grid, InputLabel, TextField } from "@mui/material";
import { useMutation } from "react-query";
import { useAuthContext } from "context/auth";
import { registration, login } from "service/apiCaller";
import "../auth.css";
import { CoPresentOutlined } from "@mui/icons-material";

function Illustration(props) {
    const { referral } = useParams();
    const { user, setUser } = useAuthContext();

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [sponsor, setSponsor] = useState(referral);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [error, setError] = useState("");
    const handleSetRememberMe = () => {
        setAgreeTerms(!agreeTerms);
    };

    const loginmutation = useMutation(login, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                props.login(response);
                setUser(
                    {
                        ...user,
                        ...response?.data?.data,
                        isAuthenticated: response?.data?.code == 200 ? true : false,
                        token: response?.data?.tokens?.access,
                        refreshToken: response?.data?.tokens?.refresh,
                        tokenLife: Math.floor(Date.now() / 1000),
                    },
                    () => {
                        if (response?.data?.code == 200) {
                            window.location.replace("/dashboard");
                        } else {
                            window.location.replace("/email-verify");
                        }
                    }
                );
            }
        },
    });

    const mutation = useMutation(registration, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                loginmutation.mutate({
                    email: email,
                    password: password,
                });
            }
        },
    });
    const { isSuccess, isLoading, isError, error: serverError, data: serverResponse } = mutation;

    const validate = () => {
        let errors = {};
        let isValid = true;

        if (sponsor === "") {
            isValid = false;
            errors["sponsor"] = "Required";
        }
        if (firstName === "") {
            isValid = false;
            errors["firstName"] = "Required";
        }
        if (lastName === "") {
            isValid = false;
            errors["lastName"] = "Required";
        }
        if (username === "") {
            isValid = false;
            errors["username"] = "Required";
        }
        if (email === "") {
            isValid = false;
            errors["email"] = "Required";
        }
        if (email !== "") {
            let pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }
        }
        if (password === "") {
            isValid = false;
            errors["password"] = "Required";
        }
        if (confirmPwd === "") {
            isValid = false;
            errors["confirmPwd"] = "Required";
        }
        if (password != confirmPwd) {
            isValid = false;
            errors["confirmPwd"] = "The password and confirm password fields do not match.";
        }
        if (!agreeTerms) {
            isValid = false;
            errors["password"] = "Please read and agree terms & conditions of USDFX.";
        }

        setError(errors);
        return isValid;
    };

    const onClickRegister = () => {
        if (validate()) {
            const postData = {
                invited_by: sponsor,
                first_name: firstName,
                last_name: lastName,
                username: username,
                email: email,
                password: password,
                confirm_password: confirmPwd,
            };
            mutation.mutate(postData);
        }
    };

    const labelStyle = {
        fontSize: "14px",
        lineHeight: "19px",
        fontWeight: "700",
        color: "#717579",
        marginBottom: "4px",
    };

    return (
        <IllustrationLayout
            title="Sign Up"
            description="Create new account of USDFX and do more with Crypto trade confidently "
            illustration={{
                image: bgImage,
                title: '"Attention is the new currency"',
                description:
                    "The more effortless the writing looks, the more effort the writer actually put into the process.",
            }}
        >
            <ArgonBox component="form" role="form">
                {isSuccess && serverResponse.status === "401" ? (
                    <div className="getError_md_start">
                        <p>{serverResponse?.response?.data?.message}</p>
                    </div>
                ) : (
                    isError && (
                        <div className="getError_md_start">
                            <p>{serverError?.response?.data?.message}</p>
                        </div>
                    )
                )}

                <ArgonBox mb={1.25}>
                    <InputLabel sx={labelStyle}>Sponsor Username *</InputLabel>
                    <ArgonInput
                        type="text"
                        placeholder="Sponsor Username"
                        value={sponsor}
                        onChange={(e) => setSponsor(e.target.value)}
                    ></ArgonInput>
                    {error.sponsor && (
                        <div className="getError_md_start">
                            <p>{error.sponsor}</p>
                        </div>
                    )}
                </ArgonBox>
                <Grid container spacing={1.25}>
                    <Grid item xs={6} lg={6}>
                        <ArgonBox>
                            <InputLabel sx={labelStyle}>First Name *</InputLabel>
                            <ArgonInput
                                required
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            ></ArgonInput>
                            {error.firstName && (
                                <div className="getError_md_start">
                                    <p>{error.firstName}</p>
                                </div>
                            )}
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <ArgonBox>
                            <InputLabel sx={labelStyle}>Last Name *</InputLabel>
                            <ArgonInput
                                required
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            ></ArgonInput>
                            {error.lastName && (
                                <div className="getError_md_start">
                                    <p>{error.lastName}</p>
                                </div>
                            )}
                        </ArgonBox>
                    </Grid>
                </Grid>
                <ArgonBox mt="12px" mb={1.25}>
                    <InputLabel sx={labelStyle}>Email *</InputLabel>
                    <ArgonInput
                        required
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {error.email && (
                        <div className="getError_md_start">
                            <p>{error.email}</p>
                        </div>
                    )}
                </ArgonBox>
                <ArgonBox mb={1.25}>
                    <InputLabel sx={labelStyle}>Username *</InputLabel>
                    <ArgonInput
                        required
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    ></ArgonInput>
                    {error.username && (
                        <div className="getError_md_start">
                            <p>{error.username}</p>
                        </div>
                    )}
                </ArgonBox>
                <ArgonBox mb={1.25}>
                    <InputLabel style={labelStyle}>Password *</InputLabel>
                    <ArgonInput
                        required
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error.password && (
                        <div className="getError_md_start">
                            <p>{error.password}</p>
                        </div>
                    )}
                </ArgonBox>
                <ArgonBox mb={2}>
                    <InputLabel style={labelStyle}>Confirm Password *</InputLabel>
                    <ArgonInput
                        required
                        type="password"
                        placeholder="Password"
                        value={confirmPwd}
                        onChange={(e) => setConfirmPwd(e.target.value)}
                    />
                    {error.confirmPwd && (
                        <div className="getError_md_start">
                            <p>{error.confirmPwd}</p>
                        </div>
                    )}
                </ArgonBox>
                <ArgonBox display="flex" alignItems="top">
                    <Checkbox
                        color="primary"
                        checked={agreeTerms}
                        onClick={() => handleSetRememberMe()}
                    />
                    <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        sx={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => handleSetRememberMe()}
                    >
                        I hereby confirm that I have read and agree to the Terms & Conditions,
                        Wallet Terms, Earn Terms, Exchange Terms, and Privacy Policy of USDFX
                    </ArgonTypography>
                </ArgonBox>
                <ArgonBox mt={2}>
                    <ArgonButton
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={!agreeTerms}
                        onClick={onClickRegister}
                    >
                        Register
                    </ArgonButton>
                </ArgonBox>
                <ArgonBox mt={2} textAlign="center">
                    <ArgonTypography variant="button" color="text" fontWeight="regular">
                        Already have an account?{" "}
                        <ArgonTypography
                            component={Link}
                            to="/login"
                            variant="button"
                            color="primary"
                            fontWeight="medium"
                        >
                            Log In
                        </ArgonTypography>
                    </ArgonTypography>
                </ArgonBox>
            </ArgonBox>
        </IllustrationLayout>
    );
}
const WIndicatorPanel = connect(
    mapStoreToProps(components.AUTH),
    mapDispatchToProps(components.AUTH)
)(Illustration);
export default WIndicatorPanel;
