// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transacton-filter {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}
.transacton-filter label {
    font-size: 13px;
    margin-bottom: 8px;
}
.transacton-filter .form-control {
    background: white;
    color: black;
}
.transaction-table table tbody td div {
    width: 100%;
    height: 100%;
}
.transaction-table table tbody td div:after {
    content: "";
    /* border-right: 2px solid red; */
}
.transaction-table .css-1y6zyxn-MuiInputBase-root-MuiTablePagination-select {
    width: 100px !important;
}

.transaction-table .css-1vsmubn-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
    text-transform:initial
}`, "",{"version":3,"sources":["webpack://./src/layouts/transaction/transaction.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,6BAA6B;AACjC;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,iCAAiC;AACrC;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI;AACJ","sourcesContent":[".transacton-filter {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-around;\n}\n.transacton-filter label {\n    font-size: 13px;\n    margin-bottom: 8px;\n}\n.transacton-filter .form-control {\n    background: white;\n    color: black;\n}\n.transaction-table table tbody td div {\n    width: 100%;\n    height: 100%;\n}\n.transaction-table table tbody td div:after {\n    content: \"\";\n    /* border-right: 2px solid red; */\n}\n.transaction-table .css-1y6zyxn-MuiInputBase-root-MuiTablePagination-select {\n    width: 100px !important;\n}\n\n.transaction-table .css-1vsmubn-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {\n    text-transform:initial\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
