import { useState, useEffect, useContext } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useQuery, useMutation } from "react-query";
import { connect } from "react-redux";
const Web3 = require("web3");

import * as clipboard from "clipboard-polyfill";
import Grid from "@mui/material/Grid";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Dialog from "@mui/material/Dialog";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    Divider,
    Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "components/Footer";
import SnackbarUtils from "utils/SnackbarUtils";

import Table from "./walletTable";
import { generateRows, columns } from "./walletTableData";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import {
    getCoinsByNetwork,
    getNetworks,
    getPaymentOptions,
    confirmPayment,
    watchDeposit,
} from "service/apiCaller";
import QRCodeGenerator from "components/QRCodeGenerator";
import { getSettings } from "service/apiCaller";
import { CheckOutlined, FileCopyOutlined } from "@mui/icons-material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import CreditCard from "./components/CreditCard";
import DepositBankSide from "./components/DepositBankSide";
import { ENVIRONMENT, TRANSAK_API_KEY } from "../../constant";
import Bep20 from "contract/bep20";
import TRC20 from "contract/trc20";
import USDFX from "contract/usdfx";
import { SocketContext } from "context/socket";
import { useCountdown } from "react-countdown-circle-timer";
import GreenTick from "assets/images/icons/green-tick.png";
import OmaraPay from "assets/images/logos/omarapay.png";
import "./wallet.css";
import transakSDK from "@transak/transak-sdk";
import ArgonInput from "components/ArgonInput";
import WithdrawDialog from "./components/Withdraw";
import TransferDialog from "./components/Transfer";
const transak = new transakSDK({
    apiKey: TRANSAK_API_KEY,
    environment: ENVIRONMENT == "production" ? ENVIRONMENT : "STAGING",
    hideMenu: true,
    widgetHeight: "800px",
    widgetWidth: "450px",
    themeColor: "58bd7d",
});
const depositDuration = 10 * 60;
function Wallet(props) {
    const [setting, setSetting] = useState(null);
    const [networkId, setNetworkId] = useState(1);
    const [openPaymentOptionDlg, setOpenPaymentOptionDlg] = useState(false);
    const [openBalanceDlg, setOpenBalanceDlg] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [bsPayment, setBsPayment] = useState("");
    const [haveMetaMask, setHaveMetaMask] = useState("");
    const [haveTrustWallet, setHaveTrustWallet] = useState("");
    const [haveTronLink, setHaveTronLink] = useState("");
    const [haveSenderWallet, setHaveSenderWallet] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isMakeDepositProceed, setIsMakeDepositProceed] = useState(false);
    const [isConfirmProceed, setIsConfirmProceed] = useState(false);
    const [accountAddress, setAccountAddress] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [depositBalance, setDepositBalance] = useState(0);
    const [isCopy, setIsCopy] = useState(false);
    const [isDepositConfirmed, setIsDepositConfirmed] = useState(false);
    const [depositHash, setDepositHash] = useState("");
    const [openBankSideDeposit, setOpenBankSideDeposit] = useState(false);

    const [openWithdrawDlg, setOpenWithdrawDlg] = useState(false);
    const [openTransferDlg, setOpenTransferDlg] = useState(false);
    const socket = useContext(SocketContext);
    let ethereum, web3;
    if (window.ethereum) {
        ethereum = window.ethereum;
        web3 = new Web3(window.ethereum);
        window.ethereum.on("accountsChanged", function (accounts) {
            // Handle the new account or disconnect
            if (accounts.length > 0) {
                // User connected a new account, do something with it
                setAccountAddress(accounts[0]);
                setIsConnected(true);
            } else {
                // User disconnected their wallet, handle accordingly
                setAccountAddress(null);
                setOpenBalanceDlg(false);
                setIsConnected(false);
            }
        });
    }
    let rows = [];
    useEffect(() => {
        if (socket) {
            socket.on("deposit-confirm", function (txHash) {
                console.log("deposit confirmed------ ", txHash);
                if (txHash && txHash != "") {
                    setDepositHash(txHash);
                }
                clearDlgState();
                setIsDepositConfirmed(true);
                // setIsMakeDepositProceed(false);
                // SnackbarUtils.success("Deposit success")
            });
            socket.on("deposit-timeout", function (data) {
                console.log("deposit timeout------ ");
                setIsDepositConfirmed(false);
                setIsMakeDepositProceed(false);
            });
        }
    }, [socket]);
    const mutationPaymentConfirm = useMutation(confirmPayment, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success("Deposit success");
            }
            setIsConfirmProceed(false);
            setOpenBalanceDlg(false);
        },
        onError: () => {
            setIsConfirmProceed(false);
            setOpenBalanceDlg(false);
        },
    });
    const mutationWatchDeposit = useMutation(watchDeposit, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success("Deposit success");
            }
        },
        onError: () => {},
    });
    const renderTime = ({ remainingTime }) => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        if (isDepositConfirmed) {
            return <p style={{ color: "#58bd7d" }}>Completed!</p>;
        } else {
            return (
                <div>
                    <p>Deposit in</p>
                    <p style={{ textAlign: "center" }}>
                        {minutes}:{seconds < 10 ? `0${seconds}` : `${seconds}`}
                    </p>
                </div>
            );
        }
    };
    const depositTimeout = () => {
        console.log("deposit time out");
        onClosePaymentOptionDlg();
    };
    const onChangeNetwork = (e) => {
        setNetworkId(e.target.value);
        setAccountAddress(null);
    };
    const onClosePaymentOptionDlg = (e) => {
        clearDlgState();
    };
    const onChangeBalance = (e) => {
        setDepositBalance(e.target.value);
    };
    const clearDlgState = () => {
        setOpenPaymentOptionDlg(false);
        setAccountAddress(null);
        setIsConnected(false);
        setOpenBalanceDlg(false);
        setDepositBalance(0);
        setIsMakeDepositProceed(false);
        setIsDepositConfirmed(false);
    };

    const transferBEP20 = async (contract) => {
        if (!accountAddress || accountAddress == "") {
            SnackbarUtils.error("Please unlock your account first.");
            setIsMakeDepositProceed(false);
            return;
        }
        try {
            const balance = Number(
                Number(depositBalance) +
                    Number(setting.gateway_fee) +
                    Number(setting.blockchain_fee)
            ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
            });
            const value = BigInt(parseInt(balance * Math.pow(10, wallet.decimal)));
            if (ethereum.chainId == "0x38") {
                /* method 1 */
                // await contract.methods
                //     .transfer(wallet.wallet, value)
                //     .send({ from: ethereum.selectedAddress })
                //     .on("transactionHash", function (hash) {})
                //     .on("receipt", (receipt) => {})
                //     .once("confirmation", (res, receipt) => {
                //         setIsMakeDepositProceed(false);
                //     })
                //     .on("error", (error) => {
                //         console.log("error", error);
                //         setIsMakeDepositProceed(false);
                //     });

                /* method 1 end */

                /* method 2 */
                const encodedABI = contract.methods.transfer(wallet.wallet, value).encodeABI();
                const gasLimit = await web3.eth
                    .estimateGas({
                        from: accountAddress,
                        to: wallet.address,
                        data: encodedABI,
                    })
                    .catch((err) => {
                        console.log(err); // eslint-disable-line
                        throw new Error("Error occured while estimating gas.");
                    });
                const gasPrice = await web3.eth.getGasPrice().catch((err) => {
                    console.log(err); // eslint-disable-line
                    throw new Error("Error occured while getting gas price.");
                });
                const bnbTxObject = {
                    from: accountAddress,
                    to: wallet.wallet,
                    value: web3.utils.toHex(gasLimit * gasPrice),
                };
                const usdtTxObject = {
                    from: accountAddress,
                    to: wallet.address,
                    data: contract.methods.transfer(wallet.wallet, value).encodeABI(),
                };

                const batch = new web3.BatchRequest();
                const bnbTx = batch.add(await web3.eth.sendTransaction.request(bnbTxObject));
                const usdtTx = batch.add(await web3.eth.sendTransaction.request(usdtTxObject));
                await batch.execute();
                /* method 2 end */
            } else {
                ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: "0x38" }],
                });
                setIsMakeDepositProceed(false);
            }
        } catch (e) {
            console.log(e);
            setIsMakeDepositProceed(false);
        }
    };
    const transferTRC20ByTronLink = async () => {
        try {
            const tronWeb = window.tronWeb;
            const contractInstance = await tronWeb.contract().at(wallet.address);
            const balance = Number(
                parseFloat(depositBalance) +
                    Number(setting.gateway_fee) +
                    Number(setting.blockchain_fee)
            ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 10,
            });
            console.log(balance);
            /* method 1 - only send usdt */
            // const result = await contractInstance.transfer(wallet.wallet, tronWeb.toSun(balance)).send();
            /* method 1 - only send usdt */
            /* method 2 - only send usdt & trx */
            const trxFee = 15;
            Promise.all([
                tronWeb.trx.sendTransaction(wallet.wallet, tronWeb.toSun(trxFee)),
                contractInstance.transfer(wallet.wallet, tronWeb.toSun(balance)).send(),
            ])
                .then((values) => {
                    console.log(values);
                })
                .catch((err) => {
                    console.log(err);
                    SnackbarUtils.error(err);
                });
            setIsMakeDepositProceed(false);
        } catch (e) {
            console.log(e);
            SnackbarUtils.error(e);
            setIsMakeDepositProceed(false);
        }
    };

    const onDepositConfirm = async () => {
        if (!isConnected) {
            SnackbarUtils.info("Please select your wallet!");
            return;
        }
        try {
            // setIsMakeDepositProceed(true);
            let contractBalance = 0;
            switch (wallet.symbol) {
                case "USDT (TETHER)":
                    const usdtContractInstance = new web3.eth.Contract(Bep20.abi, wallet.address);
                    if (!accountAddress) {
                        setIsMakeDepositProceed(false);
                        SnackbarUtils.error("Your account locked. Connect to your account again.");
                        return;
                    }
                    contractBalance = await usdtContractInstance.methods
                        .balanceOf(accountAddress)
                        .call();
                    if (parseInt(depositBalance) > parseInt(contractBalance)) {
                        setIsMakeDepositProceed(false);
                        SnackbarUtils.error(
                            "You don't have enough balance in your wallet to deposit"
                        );
                        return;
                    }
                    transferBEP20(usdtContractInstance);
                    break;
                case "USDFX":
                    const usdfxContractInstance = new web3.eth.Contract(USDFX.abi, wallet.address);
                    if (!accountAddress) {
                        setIsMakeDepositProceed(false);
                        SnackbarUtils.error("Your account locked. Connect to your account again.");
                        return;
                    }
                    contractBalance = await usdfxContractInstance.methods
                        .balanceOf(accountAddress)
                        .call();
                    if (parseInt(depositBalance) > parseInt(contractBalance)) {
                        setIsMakeDepositProceed(false);
                        SnackbarUtils.error(
                            "You don't have enough balance in your wallet to deposit"
                        );
                        return;
                    }
                    transferBEP20(usdfxContractInstance);
                    break;
                case "TRC20":
                    transferTRC20ByTronLink();
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log("error", error.message);
            SnackbarUtils.error(
                error?.message
                    ? error.message
                    : `Something went wrong while deposit. Please check your metamask connected correct network.`
            );
            setIsMakeDepositProceed(false);
        }
    };
    const onClickDeposit = (target) => {
        settingMutation.mutate();
        setOpenBalanceDlg(true);
        setWallet(target);
    };
    const onClickWithdraw = (target) => {
        setOpenWithdrawDlg(true);
        setWallet(target);
    };
    const onClickTransfer = (target) => {
        setOpenTransferDlg(true);
        setWallet(target);
    };
    const onClickPaymentOption = async (payment) => {
        if (!payment.active_status) {
            return;
        }

        try {
            const network = props.networks.find((n) => n.id == networkId);
            switch (payment.name) {
                case "MetaMask":
                    if (network.name == "TRON Network") {
                        SnackbarUtils.error(
                            `Metamask can't use for tron network. Please switch your network.`
                        );
                        return;
                    }
                    if (!ethereum || !ethereum.isMetaMask) {
                        setHaveMetaMask(false);
                    } else {
                        if (ethereum && accountAddress == null) {
                            await ethereum.request({ method: "eth_requestAccounts" });
                            web3 = new Web3(window.ethereum);
                            const accounts = await web3.eth.getAccounts();
                            setAccountAddress(accounts[0]);
                            setIsConnected(true);
                            SnackbarUtils.success(`${payment.name} is successfully connected!`);
                        }
                    }
                    break;
                case "Trust Wallet":
                    if (!window.trustwallet) {
                        setHaveTrustWallet(false);
                    } else {
                        if (accountAddress == null) {
                            await window.trustwallet.request({ method: "eth_requestAccounts" });
                            web3 = new Web3(window.trustwallet);
                            const accounts = await web3.eth.getAccounts();
                            setAccountAddress(accounts[0]);
                            setIsConnected(true);
                            SnackbarUtils.success(`${payment.name} is successfully connected!`);
                        }
                    }
                    break;
                case "Tron Link":
                    if (network.name != "TRON Network") {
                        SnackbarUtils.error(
                            `Tron link can use for only tron network. Please switch your network.`
                        );
                        return;
                    }
                    if (!window.tronWeb) {
                        setHaveTronLink(false);
                    } else {
                        if (accountAddress == null) {
                            const account = await window.tronWeb.defaultAddress.base58;
                            if (!account) {
                                SnackbarUtils.error(`Please unlock ${payment.name} wallet`);
                                return;
                            } else {
                                setAccountAddress(account);
                                setIsConnected(true);
                                SnackbarUtils.success(`${payment.name} is successfully connected!`);
                            }
                        }
                    }
                    break;
                case "Sender Wallet":
                    if (!window.sender) {
                        setHaveSenderWallet(false);
                    } else {
                        console.log(window.sender);
                        if (accountAddress == null) {
                            window.sender.ethereum
                                .request({ method: "eth_requestAccounts" })
                                .then((accounts) => {
                                    console.log(accounts);
                                    setAccountAddress(accounts[0]);
                                    setIsConnected(true);
                                    SnackbarUtils.success(
                                        `${payment.name} is successfully connected!`
                                    );
                                })
                                .catch((error) => {
                                    if (error.code === 4001) {
                                        // EIP-1193 userRejectedRequest error
                                        console.log("Please connect to MetaMask.");
                                    } else {
                                        console.error(error);
                                    }
                                });
                            // web3 = new Web3(window.sender.ethereum);
                            // const accounts = await web3.eth.getAccounts();
                            // console.log(accounts);
                            // setAccountAddress(accounts[0]);
                            // setIsConnected(true);
                            // SnackbarUtils.success(`${payment.name} is successfully connected!`);
                        }
                    }
                    break;
                case "Credit Card":
                    setBsPayment("CARD");
                    setOpenPaymentOptionDlg(false);
                    break;
                case "Bank Transfer":
                    setBsPayment("BANK");
                    setOpenPaymentOptionDlg(false);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
            if (error.code == "-32002") {
                SnackbarUtils.error(
                    "Already processing connect to wallet. Please unlock your wallet."
                );
                setIsLoading(false);
            } else {
                setIsConnected(false);
                SnackbarUtils.error(
                    "Error occured while connecting wallet. Please unlock your wallet first."
                );
                setIsLoading(false);
            }
        }
    };
    const onClickMakeDeposit = async () => {
        setOpenPaymentOptionDlg(true);
        // if (isConnected) {
        //     settingMutation.mutate();
        //     setOpenPaymentOptionDlg(false);
        //     // setOpenBalanceDlg(true);
        // } else {
        //     SnackbarUtils.info("Please select your wallet!");
        // }
    };
    const settingMutation = useMutation(getSettings, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setSetting(response?.data?.data);
            }
        },
    });
    const onCopyWalletAddress = () => {
        clipboard.writeText(wallet?.wallet).then(
            () => {
                SnackbarUtils.success("Copied!");
                setIsCopy(true);
                setTimeout(() => {
                    setIsCopy(false);
                }, 5000);
            },
            () => {
                SnackbarUtils.error("Failed to copy");
            }
        );
    };
    const { isLoading: isLoadingPaymentOptions, data: paymentoptionRes } = useQuery(
        "payment",
        getPaymentOptions,
        { refetchOnWindowFocus: false }
    );
    if (
        paymentoptionRes &&
        paymentoptionRes.data &&
        paymentoptionRes.data.data &&
        paymentoptionRes.data.data.length > 0
    ) {
        props.getPaymentOptions(paymentoptionRes.data.data);
    }
    const { isLoading: isLoadingNetwork, data: networkRes } = useQuery("network", getNetworks, {
        refetchOnWindowFocus: false,
    });
    if (networkRes && networkRes.data && networkRes.data.data && networkRes.data.data.length > 0) {
        props.getNetworks(networkRes.data.data);
    }

    const { isLoading: isLoadingWallet, data: coins } = useQuery(
        ["coin", networkId],
        getCoinsByNetwork,
        { refetchOnWindowFocus: false }
    );
    if (coins && coins.data && coins.data.data) {
        rows = generateRows(coins.data.data, onClickDeposit, onClickWithdraw, onClickTransfer);
    }

    const openTransak = () => {
        transak.init();
    };

    return (
        <DashboardLayout>
            {(isLoadingWallet || isLoadingNetwork || isLoadingPaymentOptions || isLoading) && (
                <Loader />
            )}
            {
                <Dialog
                    open={openPaymentOptionDlg}
                    onClose={onClosePaymentOptionDlg}
                    className="paymentoption-dlg"
                >
                    <DialogContent className="paymentoption-dlg-content">
                        <ArgonTypography className="paymentoption-title">
                            Select your Wallet:
                        </ArgonTypography>
                        <ArgonBox className="wallet-list">
                            <Grid container spacing={3}>
                                {props.paymentOptions.map(
                                    (payment) =>
                                        payment.type === "Wallet" && (
                                            <Grid item xs={4} key={payment.id}>
                                                <ArgonBox
                                                    className="wallet"
                                                    onClick={() => onClickPaymentOption(payment)}
                                                >
                                                    <ArgonBox className="avatar">
                                                        <ArgonBox className="inner-bg">
                                                            <ArgonBox
                                                                component="img"
                                                                src={payment.logo_url}
                                                                alt={payment.name}
                                                            ></ArgonBox>
                                                        </ArgonBox>
                                                    </ArgonBox>
                                                    <ArgonTypography>
                                                        {payment.name}
                                                    </ArgonTypography>
                                                    <ArgonTypography
                                                        sx={{
                                                            color: "red",
                                                            marginTop: "0 !important",
                                                        }}
                                                    >
                                                        {!payment.active_status && "(Coming soon)"}
                                                    </ArgonTypography>
                                                </ArgonBox>
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </ArgonBox>
                        <ArgonBox className="paymentoption-action">
                            <LoadingButton
                                className="paymentoption-btn"
                                disabled={depositBalance <= 0}
                                loading={isMakeDepositProceed}
                                fullWidth
                                loadingPosition="start"
                                startIcon={
                                    <ArgonTypography sx={{ display: "none" }}>
                                        Hidden Icon
                                    </ArgonTypography>
                                }
                                onClick={onDepositConfirm}
                            >
                                {isMakeDepositProceed
                                    ? "Waiting on confirmation"
                                    : "Deposit Confirm"}
                            </LoadingButton>
                        </ArgonBox>
                        <ArgonTypography mt={5} className="paymentoption-title">
                            Other Payment Options
                        </ArgonTypography>
                        <ArgonBox className="bank-list">
                            <Stack direction="row" spacing={3} justifyContent="center">
                                {props.paymentOptions.map(
                                    (payment) =>
                                        payment.type === "Bank" && (
                                            <ArgonBox
                                                key={payment.id}
                                                className="bank"
                                                onClick={() => onClickPaymentOption(payment)}
                                            >
                                                <ArgonBox className="avatar">
                                                    <ArgonBox className="inner-bg">
                                                        <ArgonBox
                                                            component="img"
                                                            src={payment.logo_url}
                                                            alt={payment.name}
                                                        ></ArgonBox>
                                                    </ArgonBox>
                                                </ArgonBox>
                                                <ArgonTypography>{payment.name}</ArgonTypography>
                                            </ArgonBox>
                                        )
                                )}
                            </Stack>
                        </ArgonBox>
                        <ArgonBox className="paymentoption-action">
                            <ArgonButton
                                className="paymentoption-btn"
                                onClick={ENVIRONMENT == "production" ? openTransak : null}
                            >
                                Buy Crypto
                            </ArgonButton>
                            {ENVIRONMENT == "development" && (
                                <ArgonTypography
                                    sx={{ color: "red", fontSize: "12px", fontWeight: "600" }}
                                >
                                    (Coming soon)
                                </ArgonTypography>
                            )}
                        </ArgonBox>
                    </DialogContent>
                </Dialog>
            }

            <Dialog
                open={openBalanceDlg}
                onClose={() => {
                    clearDlgState();
                }}
                className="balance-dlg"
            >
                <DialogTitle
                    sx={{
                        p: "0rem 2rem 0 2rem",
                        textAlign: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                    }}
                >
                    Top up your Wallet
                </DialogTitle>

                <DialogContent>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <CountdownCircleTimer
                            isPlaying={!isDepositConfirmed}
                            duration={depositDuration}
                            colors={["#58bd7d", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[7, 5, 2, 0]}
                            size={150}
                            onComplete={() => {
                                depositTimeout();
                            }}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div> */}

                    <ArgonBox className="qrcode">
                        <QRCodeGenerator data={wallet?.wallet} />
                    </ArgonBox>
                    <ArgonBox className="wallet-address">
                        <ArgonTypography className="address">
                            {wallet?.wallet || ""}
                        </ArgonTypography>
                        <ArgonTypography
                            className={isCopy ? "copy-btn success" : "copy-btn"}
                            onClick={onCopyWalletAddress}
                        >
                            {isCopy ? <CheckOutlined /> : <FileCopyOutlined />}
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox className="balance-info">
                        <InputLabel className="label">Deposit Balance</InputLabel>
                        <TextField
                            type="number"
                            fullWidth
                            value={depositBalance}
                            onChange={onChangeBalance}
                            sx={{
                                "& > div": {
                                    border: "none !important",
                                    padding: "10px 3px 10px 10px",
                                },
                                "& input": {
                                    padding: 0,
                                    flex: 1,
                                },
                            }}
                        />
                    </ArgonBox>
                    <ArgonBox className="order-details-list">
                        <ArgonTypography className="label">Order Details</ArgonTypography>
                        <ArgonBox className="list-item">
                            <ArgonTypography className="label">
                                Total Credits in your account
                            </ArgonTypography>
                            <ArgonTypography className="value">
                                {setting &&
                                    Number(depositBalance).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 10,
                                    })}{" "}
                                USD
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox className="list-item passive">
                            <ArgonTypography className="label">Gateway fee</ArgonTypography>
                            <ArgonTypography className="value">
                                {setting &&
                                    Number(setting.gateway_fee).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 10,
                                    })}{" "}
                                USD
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox className="list-item passive">
                            <ArgonTypography className="label">Blockchain fee</ArgonTypography>
                            <ArgonTypography className="value">
                                {setting &&
                                    Number(setting.blockchain_fee).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 10,
                                    })}{" "}
                                USD
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox className="list-item">
                            <ArgonTypography className="label">Total</ArgonTypography>
                            <ArgonTypography className="value">
                                {setting &&
                                    Number(
                                        Number(depositBalance) +
                                            Number(setting.gateway_fee) +
                                            Number(setting.blockchain_fee)
                                    ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 10,
                                    })}{" "}
                                USD
                            </ArgonTypography>
                        </ArgonBox>
                    </ArgonBox>
                    <ArgonBox className="action">
                        <ArgonButton
                            className="action-btn"
                            onClick={onClickMakeDeposit}
                            disabled={depositBalance <= 0}
                        >
                            Make Deposit
                        </ArgonButton>
                    </ArgonBox>
                </DialogContent>
            </Dialog>
            <Dialog
                open={isDepositConfirmed}
                onClose={() => {
                    clearDlgState();
                }}
                className="balance-dlg deposit-complete-dlg"
            >
                <img
                    src={OmaraPay}
                    style={{ width: "100px", margin: "0 auto", display: "block" }}
                />
                <DialogTitle
                    sx={{
                        p: "0rem 2rem 0 2rem",
                        textAlign: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                    }}
                >
                    Congratulations
                </DialogTitle>

                <DialogContent>
                    <div className="tick">
                        <img src={GreenTick} />
                        <p>Your Account has been successfully credited</p>
                    </div>
                    <p className="note">
                        Click the <span>Verify on Blockchain</span> to confirm your Depoist.
                    </p>
                    <ArgonBox className="action">
                        <a
                            className="action-btn"
                            style={{
                                width: "100%",
                                display: "block",
                                lineHeight: "50px",
                                cursor: "pointer",
                            }}
                            href={depositHash}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Verify on Blockchain
                        </a>
                    </ArgonBox>
                    <ArgonBox className="action">
                        <a
                            className="action-btn"
                            style={{
                                width: "100%",
                                display: "block",
                                lineHeight: "50px",
                                cursor: "pointer",
                            }}
                            href="/dashboard"
                        >
                            Back to my Dahsboard
                        </a>
                    </ArgonBox>
                </DialogContent>
            </Dialog>

            {bsPayment != "" && (
                <DepositBankSide
                    open={bsPayment != ""}
                    payment={bsPayment}
                    onClose={() => setBsPayment("")}
                    network={networkId}
                    asset={wallet}
                />
            )}

            <Dialog open={haveMetaMask === false} onClose={() => setHaveMetaMask(true)}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    The Metamask Wallet is not installed. Please install the Metamask.
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="primary"
                        onClick={() => {
                            window.open(
                                "//www.cryptocompare.com/wallets/guides/how-to-use-metamask/",
                                "_blank"
                            );
                        }}
                        autoFocus
                    >
                        Install
                    </ArgonButton>
                </DialogActions>
            </Dialog>
            <Dialog open={haveTrustWallet === false} onClose={() => setHaveTrustWallet(true)}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    The Trust Wallet is not installed. Please install the Trust Wallet.
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="primary"
                        onClick={() => {
                            window.open(
                                "//chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph",
                                "_blank"
                            );
                        }}
                        autoFocus
                    >
                        Install
                    </ArgonButton>
                </DialogActions>
            </Dialog>
            <Dialog open={haveTronLink === false} onClose={() => setHaveTronLink(true)}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    The Tron Link is not installed. Please install the Tron Link.
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="primary"
                        onClick={() => {
                            window.open(
                                "//chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec",
                                "_blank"
                            );
                        }}
                        autoFocus
                    >
                        Install
                    </ArgonButton>
                </DialogActions>
            </Dialog>
            <Dialog open={haveSenderWallet === false} onClose={() => setHaveSenderWallet(true)}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    The Sender Wallet is not installed. Please install the Sender Wallet.
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="primary"
                        onClick={() => {
                            window.open(
                                "//chrome.google.com/webstore/detail/sender-wallet/epapihdplajcdnnkdeiahlgigofloibg/related",
                                "_blank"
                            );
                        }}
                        autoFocus
                    >
                        Install
                    </ArgonButton>
                </DialogActions>
            </Dialog>

            <WithdrawDialog
                open={openWithdrawDlg}
                onClose={() => setOpenWithdrawDlg(false)}
                asset={wallet}
            />
            <TransferDialog
                open={openTransferDlg}
                onClose={() => setOpenTransferDlg(false)}
                asset={wallet}
            />

            <DashboardNavbar title="Wallet Deposit" />

            <ArgonBox py={3}>
                <ArgonBox className="select-network">
                    <InputLabel>Select Network</InputLabel>
                    <Select value={networkId} onChange={onChangeNetwork}>
                        {props.networks.map((network) => (
                            <MenuItem key={`network_${network.id}`} value={network.id}>
                                {network.name}
                            </MenuItem>
                        ))}
                    </Select>
                </ArgonBox>
                <ArgonBox mt={3} mb={3}>
                    <Table
                        columns={columns}
                        rows={rows}
                        handleDeposit={onClickDeposit}
                        handleWithdraw={onClickWithdraw}
                        handleTransfer={onClickTransfer}
                    />
                </ArgonBox>
            </ArgonBox>

            <Footer />
        </DashboardLayout>
    );
}
// export default Default;
const Wrapper = connect(
    mapStoreToProps(components.WALLET),
    mapDispatchToProps(components.WALLET)
)(Wallet);
export default Wrapper;
