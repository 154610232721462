import { useState } from "react";
import { useMutation } from "react-query";

import { Grid } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";

import { resendCode, verifyCode, changeEmail } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";
import { useAuthContext } from "context/auth";

export default function EmailVerification() {
    const { user, setUser } = useAuthContext();
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [emailChange, setEmailChange] = useState(false);

    const resendMutation = useMutation(resendCode, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response?.data?.message);
            }
        },
    });
    const handleResendCode = () => {
        resendMutation.mutate();
    };

    const verifyMutation = useMutation(verifyCode, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setUser({ ...user, ...response?.data?.data, isAuthenticated: true }, () => {
                    window.location.replace("/dashboard");
                });
            }
        },
    });
    const handleVerifyCode = () => {
        const payload = { code };
        verifyMutation.mutate(payload);
    };

    const changeEmailMutation = useMutation(changeEmail, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response?.data?.message);
                setEmailChange(false);
            }
        },
    });
    const handleChangeEmail = () => {
        const payload = { email };
        changeEmailMutation.mutate(payload);
    };

    return (
        <IllustrationLayout
            title="Two-Factor Authentification"
            description="We've sent verification code. Please check your email inbox. Please enter the verification code sent to your email address. If you can't find email from us, please resend it or change email."
            illustration={{
                image: bgImage,
                title: '"Attention is the new currency"',
                description:
                    "The more effortless the writing looks, the more effort the writer actually put into the process.",
            }}
        >
            <ArgonBox component="form" role="form">
                <ArgonBox mb="12px">
                    {emailChange ? (
                        <ArgonInput
                            type="email"
                            placeholder="Email"
                            size="large"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    ) : (
                        <ArgonInput
                            type="text"
                            placeholder="- - - - - -"
                            size="large"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    )}
                </ArgonBox>
                <ArgonBox mt={1}>
                    {emailChange ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6} lg={6}>
                                <ArgonButton
                                    sx={{ background: "#EFEFF4" }}
                                    fullWidth
                                    onClick={() => setEmailChange(false)}
                                >
                                    Cancel
                                </ArgonButton>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <ArgonButton color="primary" fullWidth onClick={handleChangeEmail}>
                                    Change
                                </ArgonButton>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={6} lg={6}>
                                <ArgonButton
                                    sx={{ background: "#EFEFF4" }}
                                    fullWidth
                                    onClick={handleResendCode}
                                >
                                    Resend
                                </ArgonButton>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <ArgonButton
                                    sx={{ background: "#EFEFF4" }}
                                    fullWidth
                                    onClick={() => setEmailChange(true)}
                                >
                                    Change Email
                                </ArgonButton>
                            </Grid>
                        </Grid>
                    )}
                </ArgonBox>
                {!emailChange && (
                    <ArgonBox mt={3}>
                        <ArgonButton
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={handleVerifyCode}
                        >
                            Verify
                        </ArgonButton>
                    </ArgonBox>
                )}
            </ArgonBox>
        </IllustrationLayout>
    );
}
