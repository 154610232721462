export const ENVIRONMENT = "development";
export const API_URL = process.env.REACT_APP_API_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const TRANSAK_API_KEY =
    ENVIRONMENT == "production"
        ? process.env.REACT_APP_TRANSAK_API_KEY
        : process.env.REACT_APP_TRANSAK_STAGING_API_KEY;
export const TRANSAK_ENDPOINT =
    ENVIRONMENT == "production"
        ? process.env.REACT_APP_TRANSAK_ENDPOINT
        : process.env.REACT_APP_TRANSAK_STAGING_ENDPOINT;
