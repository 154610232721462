import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    InputLabel,
    Modal,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { getInvestments } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";

function MySubscription() {
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState("");

    const handleModalOpen = (dataIndex) => {
        setWithdrawAmount("");
        setSelectedData(data[dataIndex]);
        setShowModal(true);
    };
    const handleModalClose = () => {
        setSelectedData(null);
        setShowModal(false);
    };

    const handleDialogOpen = () => {
        setShowDialog(true);
    };
    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const validate = () => {
        const numberRegex = /^-?\d*\.?\d+$/;
        if (
            !numberRegex.test(withdrawAmount) ||
            withdrawAmount <= 0 ||
            withdrawAmount > selectedData.earning
        ) {
            SnackbarUtils.warning("Please enter the correct withdraw amount.");
            return false;
        }
        return true;
    };

    const handleCheckWithdraw = () => {
        if (validate()) {
            if (selectedData && selectedData.days > selectedData.period) {
                handleWithdraw();
            } else {
                handleDialogOpen();
            }
        }
    };

    const handleWithdraw = () => {};

    useEffect(() => {
        updateTable(null);
    }, []);

    const mutation = useMutation(getInvestments, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setTotal(response?.data?.data?.count);
                setData(response?.data?.data?.rows);
            }
        },
    });

    const updateTable = (tableState) => {
        let filterOptions = { ...tableState };
        mutation.mutate(filterOptions);
    };

    const columns = [
        {
            name: "type",
            label: "Package",
            options: {
                sort: true,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
            },
        },
        {
            name: "capital",
            label: "Capital",
            options: {
                sort: true,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
            },
        },
        {
            name: "invest_date",
            label: "Date",
            options: {
                sort: true,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
            },
        },
        {
            name: "days",
            label: "Days",
            options: {
                sort: true,
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => {
                    return { align: "right" };
                },
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: true,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
                customBodyRender: (value) => {
                    return value === 1 ? <Chip label="Active" /> : <Chip label="InActive" />;
                },
            },
        },
        {
            name: "earning",
            label: "Earning",
            options: {
                sort: true,
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => {
                    return { align: "right" };
                },
                customBodyRender: (value) => {
                    return value.toFixed(2);
                },
            },
        },
        {
            name: "actions",
            label: "ACTIONS",
            options: {
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return { align: "center" };
                },
                setCellProps: () => {
                    return { align: "center" };
                },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleModalOpen(dataIndex)}
                        >
                            <ArrowForward />
                        </Button>
                    );
                },
            },
        },
    ];

    const option = {
        responsive: "simple",
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        search: false,
        pageSize: 10,
        rowsPerPageOptions: [],
        serverSide: true,
        count: total,
        onTableChange: (action, tableState) => {
            switch (action) {
                case "sort":
                case "changePage": {
                    updateTable(tableState);
                    break;
                }
                default:
                    break;
            }
        },
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none !important",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            paddingBottom: "8px !important",
                            "& > span": {
                                display: "unset !important",
                            },
                            "& > span > button": {
                                padding: "6px 8px !important",
                            },
                            "&:nth-of-type(7)": {
                                width: 100,
                            },
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            padding: "5px 12px !important",
                        },
                    },
                },
                MUIDataTableFooter: {
                    styleOverrides: {
                        root: {
                            "& > tfoot > tr > td": {
                                borderBottom: 0,
                                padding: "0 16px !important",
                            },
                        },
                    },
                },
            },
        });

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Card>
                <ArgonBox px={4} py={5}>
                    <ArgonTypography
                        variant="h3"
                        style={{
                            color: "#000000",
                            fontSize: "24px",
                            fontWeight: "bold",
                            lineHeight: "32px",
                        }}
                    >
                        My Subscription
                    </ArgonTypography>
                    <ArgonBox mt={3}>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable data={data} columns={columns} options={option} />
                        </ThemeProvider>
                    </ArgonBox>
                </ArgonBox>
            </Card>

            <Dialog
                sx={{ "& .MuiPaper-root": { width: 400 } }}
                open={showModal}
                onClose={handleModalClose}
            >
                <ArgonBox p={4}>
                    <ArgonTypography variant="h6" component="h2">
                        Investment
                    </ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={6} mb={2}>
                                <ArgonBox textAlign="center">
                                    <InputLabel sx={{ fontSize: 14 }}>Earning</InputLabel>
                                    <ArgonBox>
                                        <span style={{ fontSize: "14px" }}>USD</span>{" "}
                                        <span style={{ color: "blue" }}>
                                            {selectedData ? selectedData.earning.toFixed(2) : 0}
                                        </span>
                                    </ArgonBox>
                                </ArgonBox>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <ArgonBox textAlign="center">
                                    <InputLabel sx={{ fontSize: 14 }}>Days</InputLabel>
                                    <ArgonBox>
                                        <span style={{ color: "blue" }}>
                                            {selectedData ? selectedData.days : 0}
                                        </span>
                                    </ArgonBox>
                                </ArgonBox>
                            </Grid>
                        </Grid>
                        <ArgonBox mt={1}>
                            <InputLabel sx={{ fontSize: 14, mb: 1 }}>
                                Withdrawable Amount
                            </InputLabel>
                            <ArgonInput
                                type="number"
                                placeholder="Enter Capital to invest"
                                value={withdrawAmount}
                                onChange={(event) => setWithdrawAmount(event.target.value)}
                            />
                        </ArgonBox>
                        <ArgonBox mt={2} textAlign="right">
                            <ArgonButton
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                                onClick={handleCheckWithdraw}
                            >
                                Withdraw
                            </ArgonButton>
                            <ArgonButton color="dark" size="small" onClick={handleModalClose}>
                                Close
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog>

            <Dialog open={showDialog} onClose={handleDialogClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    If you withdraw their investment before the fixed term ends, it may involve
                    penalties or fees depending on the selected fixed term package.
                    <br />
                    Do you want to continue?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton variant="text" color="secondary" onClick={handleDialogClose}>
                        Disagree
                    </ArgonButton>
                    <ArgonButton variant="text" color="primary" onClick={handleWithdraw} autoFocus>
                        Agree
                    </ArgonButton>
                </DialogActions>
            </Dialog>

            <Footer />
        </DashboardLayout>
    );
}

export default MySubscription;
