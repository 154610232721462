// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
    const { palette, boxShadows, transitions, breakpoints, functions } = theme;
    const { miniSidenav } = ownerState;

    const sidebarWidth = 300;
    const { white } = palette;
    const { pxToRem } = functions;

    let bgColor = white.main;

    // styles for the sidenav when miniSidenav={false}
    const drawerOpenStyles = () => ({
        transform: "translateX(0)",
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
            backgroundColor: bgColor,
            left: "0",
            width: sidebarWidth,
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen,
            }),
        },
    });

    // styles for the sidenav when miniSidenav={true}
    const drawerCloseStyles = () => ({
        transform: `translateX(${pxToRem(-320)})`,
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
            backgroundColor: bgColor,
            left: "0",
            width: pxToRem(96),
            overflowX: "hidden",
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.shorter,
            }),
        },
    });

    return {
        "& .MuiDrawer-paper": {
            border: "none",
            backgroundColor: white.main,
            margin: 0,
            borderRadius: 0,
            height: "100%",

            [breakpoints.down("xl")]: {
                boxShadow: boxShadows.md,
            },

            ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
        },
    };
});
