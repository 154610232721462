import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { connect } from "react-redux";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
/* eslint-disable no-unused-vars */

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DetailedStatisticsCard from "components/Cards/StatisticsCards/DetailedStatisticsCard";
import InviteCard from "components/Cards/InviteCard/DefaultInviteCard";
import DefaultPricingCard from "components/Cards/PricingCards/DefaultPricingCard";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";

import { useAuthContext } from "context/auth";
import { getStatisticsInfo, getPackages } from "service/apiCaller";

// Images
import pricingBadgeIcon from "assets/images/icons/pricing-badge-icon.png";
import superNodeIcon from "assets/images/icons/supernode-icon.png";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
// import {initSocket} from 'library/socket'
import "./dashboard.css";
import superFeaturedNodeIcon from "assets/images/icons/pricing/pricing-super-icon.png";
import masterFeaturedNodeIcon from "assets/images/icons/pricing/pricing-master-icon.png";

import FeaturedNodeCard from "./components/FeaturedNodeCard";
import TopAffiliateCard from "./components/TopAffiliateCard";

function Default() {
    const { user } = useAuthContext();
    const [info, setInfo] = useState({});
    const [packages, setPackages] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const getInfoMutation = useMutation(getStatisticsInfo, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setInfo(response.data.data);
            }
        },
    });
    const getPackagesMutation = useMutation(getPackages, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setPackages(response?.data?.data);
            }
        },
    });

    useEffect(() => {
        getInfoMutation.mutate();
        getPackagesMutation.mutate();
    }, []);
    useEffect(() => {
        // initSocket()
    }, [window.location]);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Dialog
                className="dashboard-popup-dlg"
                open={showPopup}
                onClose={() => setShowPopup(false)}
            >
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    Dear Affiliates, <br />
                    We want to extend our warmest greetings to our valued affiliates. The USDFX
                    Development Team would like to express our sincerest gratitude for your
                    continued support and partnership. <br />
                    We are thrilled to inform you that we are currently undergoing a 24-hour upgrade
                    of our Live System. During this time, you will have the opportunity to
                    experience the full range of the latest platform enhancements we have
                    implemented.
                    <br /> <br /> The updates include: <br /> <br />
                    Crypto Wallet Deposits: Seamlessly deposit funds using your crypto wallet.
                    Internal Fund Transfers without Gas Fee: Enjoy the convenience of transferring
                    funds within the platform without incurring any gas fees.
                    <br /> Enhanced User Experience: Immerse yourself in a seamless and
                    user-friendly interface for a smoother experience. User Security: Benefit from
                    enhanced security features such as email authentication, password reset, and
                    improved user profile management.
                    <br />
                    Affiliate Rewards Transfer to Users: Easily transfer affiliate rewards to
                    users&apos; accounts. Profile Update: Users now have the ability to update and
                    manage their profiles effortlessly. <br />
                    But that&apos;s not all! We are also actively working on even more exciting
                    updates. In the pipeline are a decentralized exchange, swap functionality,
                    seamless wallet-to-wallet withdrawals, integration with multiple payment
                    options, and Node Subscription. Stay tuned for these upcoming enhancements!{" "}
                    <br />
                    <br />
                    During this upgrade period, we are committed to providing you with uninterrupted
                    support. If you have any questions, concerns, or need assistance, please do not
                    hesitate to reach out to us. Our dedicated support team is here to assist you
                    every step of the way. Once again, we extend our heartfelt thanks to all our
                    affiliates for your unwavering support and cooperation. Together, we will
                    continue to achieve great success.
                    <br />
                    <br />
                    Sincerely yours, <br />
                    <br />
                    USDFX Development Team
                </DialogTitle>
            </Dialog>
            <ArgonBox>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Portfolio balance"
                            count={{
                                color: "#098551",
                                text: `USD ${Number(user?.balance).toFixed(4) || 0.0}`,
                            }}
                            type={1}
                            action={{ text: "Deposit" }}
                            link={"/wallet"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Locked USDFX"
                            count={{
                                color: "#098551",
                                text: `USDFX ${Number(info?.locked_usdfx).toFixed(2) || 0.0}`,
                            }}
                            type={2}
                            extra={{
                                title: "Total Subscription",
                                count: `$ ${Number(info?.total_subscription).toFixed(2) || 0}`,
                                color: "#546BEA",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Total Earnings"
                            count={{
                                color: "#546BEA",
                                text: `$ ${Number(info?.total_earnings).toFixed(2) || 0.0}`,
                            }}
                            type={2}
                            extra={{
                                title: "Affiliate Rewards",
                                count: `USDT ${Number(user?.affiliate_rewards).toFixed(2) || 0}`,
                                color: "#546BEA",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Pesos Balance"
                            count={{
                                color: "#FFCC00",
                                text: `₱ ${Number(user?.pesos_balance).toFixed(2) || 0}`,
                            }}
                            type={1}
                            action={{ text: "Convert" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Loyalty Rewards Status"
                            count={{ color: "#F79264", text: "Starter" }}
                            value={{ number: 0, type: "" }}
                            icon={pricingBadgeIcon}
                            type={3}
                            action={{ text: "Subscribe" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Your Sales Volume"
                            count={{ color: "#F79264", text: "Sales Volume" }}
                            value={{
                                number: parseInt(info?.sales_volumn) || 0,
                                type: "money",
                            }}
                            icon={pricingBadgeIcon}
                            type={3}
                            action={{ text: "Subscribe" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DetailedStatisticsCard
                            title="Your Node Subscription"
                            count={{ color: "#F79264", text: "SuperNode" }}
                            value={{ number: 0, type: "only-number" }}
                            icon={superNodeIcon}
                            type={3}
                            action={{ text: "Subscribe" }}
                        />
                    </Grid>
                </Grid>
                <ArgonBox mt={3} mb={3}>
                    <InviteCard />
                </ArgonBox>
                <Card sx={{ mb: 3 }}>
                    <ArgonBox p={3}>
                        <ArgonTypography
                            fontSize="24px"
                            lineHeight="36px"
                            fontWeight="medium"
                            sx={{ mb: 3 }}
                        >
                            Overall Statistics
                        </ArgonTypography>
                        <Grid container spacing={3}>
                            <Grid item xs={6} lg={2.4} textAlign="center">
                                <CircularProgressWithLabel
                                    total={info?.total_subscription_count || 100}
                                    value={info?.my_subscription_count || 0}
                                    color="info"
                                />
                                <ArgonTypography fontSize="16px" lineHeight="24px">
                                    Subscription
                                </ArgonTypography>
                            </Grid>
                            <Grid item xs={6} lg={2.4} textAlign="center">
                                <CircularProgressWithLabel
                                    total={info?.total_withdraw_count || 100}
                                    value={info?.my_withdraw_count || 0}
                                    color="success"
                                />
                                <ArgonTypography fontSize="16px" lineHeight="24px">
                                    Withdrawals
                                </ArgonTypography>
                            </Grid>
                            <Grid item xs={6} lg={2.4} textAlign="center">
                                <CircularProgressWithLabel
                                    total={info?.total_referral_count || 100}
                                    value={info?.direct_referral_count || 0}
                                    color="error"
                                />
                                <ArgonTypography fontSize="16px" lineHeight="24px">
                                    Direct Referrals
                                </ArgonTypography>
                            </Grid>
                            <Grid item xs={6} lg={2.4} textAlign="center">
                                <CircularProgressWithLabel
                                    total={info?.total_referral_count || 100}
                                    value={info?.indirect_referral_ccount || 0}
                                    color="error"
                                />
                                <ArgonTypography fontSize="16px" lineHeight="24px">
                                    InDirect Referrals
                                </ArgonTypography>
                            </Grid>

                            <Grid item xs={6} lg={2.4} textAlign="center">
                                <CircularProgressWithLabel
                                    total={info?.total_users_count * 3 || 100}
                                    value={info?.total_users_count || 0}
                                    color="primary"
                                />
                                <ArgonTypography fontSize="16px" lineHeight="24px">
                                    Total Users
                                </ArgonTypography>
                            </Grid>
                        </Grid>
                    </ArgonBox>
                </Card>
                {/* <Card sx={{ mb: 3 }}>
                    <ArgonBox
                        p={3}
                        display="flex"
                        sx={({ breakpoints }) => ({
                            [breakpoints.down("md")]: {
                                flexDirection: "column",
                            },
                        })}
                    >
                        <ArgonBox
                            width="40%"
                            sx={({ breakpoints }) => ({
                                [breakpoints.down("md")]: {
                                    width: "100%",
                                },
                            })}
                        >
                            <ArgonTypography
                                sx={{
                                    mb: 3,
                                    fontSize: "24px",
                                    lineHeight: "36px",
                                    fontWeight: "700",
                                }}
                            >
                                Featured Nodes
                            </ArgonTypography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12} xl={6}>
                                    <FeaturedNodeCard
                                        logo={superFeaturedNodeIcon}
                                        name="Super Node"
                                        sales={3000}
                                        percent={19.06}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} xl={6}>
                                    <FeaturedNodeCard
                                        logo={masterFeaturedNodeIcon}
                                        name="Master Node"
                                        sales={2000}
                                        percent={-1.73}
                                    />
                                </Grid>
                            </Grid>
                        </ArgonBox>
                        <ArgonBox
                            borderRight="1px solid #E8E9EC"
                            mx={3}
                            sx={({ breakpoints }) => ({
                                [breakpoints.down("md")]: {
                                    display: "none",
                                },
                            })}
                        />
                        <ArgonBox
                            flex="1"
                            sx={({ breakpoints }) => ({
                                [breakpoints.down("md")]: {
                                    paddingTop: "24px",
                                },
                            })}
                        >
                            <ArgonTypography
                                sx={{
                                    mb: 3,
                                    fontSize: "24px",
                                    lineHeight: "36px",
                                    fontWeight: "700",
                                }}
                            >
                                Top Affiliates
                            </ArgonTypography>

                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12} xl={4}>
                                    <TopAffiliateCard username="johndoe423" ranking={1} />
                                </Grid>
                                <Grid item xs={12} lg={12} xl={4}>
                                    <TopAffiliateCard username="kleinborris36" ranking={2} />
                                </Grid>
                                <Grid item xs={12} lg={12} xl={4}>
                                    <TopAffiliateCard username="yuriypopov885" ranking={3} />
                                </Grid>
                            </Grid>
                        </ArgonBox>
                    </ArgonBox>
                </Card> */}
                <Grid container spacing={3}>
                    {packages.map((item) => (
                        <Grid item xs={12} lg={3} key={item.id}>
                            <DefaultPricingCard
                                type={item.name}
                                logo={item.logo}
                                percentage={item.percent}
                                days={item.period}
                            />
                        </Grid>
                    ))}
                </Grid>
            </ArgonBox>

            <Footer />
        </DashboardLayout>
    );
}

const WIndicatorPanel = connect(
    mapStoreToProps(components.AUTH),
    mapDispatchToProps(components.AUTH)
)(Default);
export default WIndicatorPanel;
