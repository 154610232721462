import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";


import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import "./../../styles/style.css";
import { getBankInfo, saveBankInfo } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";

function AccountSettingLayout() {
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [ibanNumber, setIbanNumber] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankAddress, setBankAddress] = useState("");
    const [swiftCode, setSwiftCode] = useState("");

    const getBankInfoMutation = useMutation(getBankInfo, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                const accountInfo = response?.data?.data;
                setAccountName(accountInfo.account_name ? accountInfo.account_name : "");
                setAccountNumber(accountInfo.account_number ? accountInfo.account_number : "");
                setIbanNumber(accountInfo.iban_number ? accountInfo.iban_number : "");
                setBankName(accountInfo.bank_name ? accountInfo.bank_name : "");
                setBankAddress(accountInfo.bank_address ? accountInfo.bank_address : "");
                setSwiftCode(accountInfo.swift_code ? accountInfo.swift_code : "");
            }
        },
    });

    useEffect(() => {
        getBankInfoMutation.mutate();
    }, []);

    const updateBankInfoMutation = useMutation(saveBankInfo, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response.data.message);
            }
        },
    });

    const handleUpdateBankInfo = () => {
        const payload = {
            account_name: accountName,
            account_number: accountNumber,
            iban_number: ibanNumber,
            bank_name: bankName,
            bank_address: bankAddress,
            swift_code: swiftCode,
        };

        updateBankInfoMutation.mutate(payload);
    };

    return (
        <ArgonBox>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                    <ArgonTypography fontSize="15px" fontWeight="medium" lineHeight="22px">
                        Bank Details
                    </ArgonTypography>
                    <Grid container pt={3} rowSpacing={3} columnSpacing={4}>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Account Name</InputLabel>
                            <input
                                className="form-control"
                                value={accountName}
                                onChange={(event) => setAccountName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Bank Name</InputLabel>
                            <input
                                className="form-control"
                                value={bankName}
                                onChange={(event) => setBankName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Account Number</InputLabel>
                            <input
                                className="form-control"
                                value={accountNumber}
                                onChange={(event) => setAccountNumber(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Bank Address</InputLabel>
                            <input
                                className="form-control"
                                value={bankAddress}
                                onChange={(event) => setBankAddress(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">IBAN Number</InputLabel>
                            <input
                                className="form-control"
                                value={ibanNumber}
                                onChange={(event) => setIbanNumber(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">SWIFT Code</InputLabel>
                            <input
                                className="form-control"
                                value={swiftCode}
                                onChange={(event) => setSwiftCode(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt="56px" spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <ArgonButton
                                fullWidth
                                className="btn-setting"
                                onClick={handleUpdateBankInfo}
                            >
                                Update Bank Info
                            </ArgonButton>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <ArgonButton fullWidth className="btn-setting">
                                My Bank Details
                            </ArgonButton>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <ArgonButton fullWidth className="btn-setting">
                                Withdraw
                            </ArgonButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <ArgonBox mt="30px" pl={7}>
                        <InputLabel className="form-label">Your Saved Account</InputLabel>
                        <input
                            className="form-control form-control-small"
                            placeholder="Account Name"
                            style={{ marginBottom: "12px" }}
                        />
                        <input
                            className="form-control form-control-small"
                            placeholder="Account Number"
                        />
                    </ArgonBox>
                </Grid>
            </Grid>
        </ArgonBox>
    );
}

export default AccountSettingLayout;
