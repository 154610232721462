// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    opacity: 0;
    border-radius: 10px;
    background-color: transparent;
    background-clip: content-box;
}
*:hover::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    background-clip: content-box;
}
*:hover::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
}
*::-webkit-scrollbar-thumb:hover {
    background-color: #999999;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,6BAA6B;IAC7B,4BAA4B;AAChC;AACA;IACI,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,4BAA4B;AAChC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":["::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n    background-color: transparent;\n    border-radius: 10px;\n}\n::-webkit-scrollbar-thumb {\n    opacity: 0;\n    border-radius: 10px;\n    background-color: transparent;\n    background-clip: content-box;\n}\n*:hover::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n    background-color: #f5f5f5;\n    background-clip: content-box;\n}\n*:hover::-webkit-scrollbar-thumb {\n    background-color: #dcdcdc;\n}\n*::-webkit-scrollbar-thumb:hover {\n    background-color: #999999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
