import initialState from '../initialState';
import actionPack from '../actions/actions';
const actions = actionPack.transaction;
export default function transactionReducer(state=initialState.transaction, action) {
    switch (action.type) {
        case actions.GET_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actions.GET_COINS:
            return {
                ...state,
                coins: action.payload
            };
      default:
            return state;
    }
  }