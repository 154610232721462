import { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Sidenav from "components/Sidenav";

import theme from "assets/theme";
import routes from "routes";
import RouterContainer from "components/RouterContainer";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { SnackbarUtilsConfigurator } from "utils/SnackbarUtils";
import { useArgonController, setMiniSidenav } from "context";

// Images
import brand from "assets/images/dashboard-logo.png";
import brandDark from "assets/images/dashboard-logo.png";

// Icon Fonts
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";
import "assets/css/style.css";

export default function App() {
    const [controller, dispatch] = useArgonController();
    const { miniSidenav, direction, layout, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });

    return (
        <>
            <Helmet>
                <title>Scale Novelty DAO Affiliate</title>
                <meta name="description" content="Scale Novelty DAO Affiliate" />
                <meta property="og:title" content="SNDAO Affiliate" />
                <meta property="og:description" content="Scale Novelty DAO Affiliate" />
                <meta property="og:image" content={brand} />
                <meta property="og:site_name" content="Scale Novelty" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Scale Novelty" />
                <meta name="twitter:description" content="Scale Novelty DAO" />
                <meta name="twitter:image" content={brand} />
            </Helmet>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                action={(snackbarId) => (
                    <IconButton onClick={() => closeSnackbar(snackbarId)}>
                        <Close htmlColor="white" />
                    </IconButton>
                )}
            >
                <SnackbarUtilsConfigurator />
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {layout === "dashboard" && (
                        <Sidenav
                            color={sidenavColor}
                            brand={brandDark}
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                    )}

                    <RouterContainer />
                </ThemeProvider>
            </SnackbarProvider>
        </>
    );
}
