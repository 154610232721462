import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel({ total, value, color }) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                size={100}
                variant="determinate"
                value={100}
                sx={{ color: "#F0F0F0" }}
            />
            <CircularProgress
                size={100}
                variant="determinate"
                value={(value / total) * 100}
                // sx={{ color }}
                color={color}
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="caption" component="div" fontSize="24px" fontWeight="bold">
                    {`${Math.round(value)}`}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;
