const initialState = {
    auth: {
        user: undefined,
        isAuthenticated: false
    },
    wallet: {
        coins: [],
        networks: [],
        paymentOptions: []
    },
    transaction: {
        list: [],
        coins: []
    }
}
export default initialState