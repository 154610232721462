import actionPack from '../actions/actions';

const actions = actionPack.wallet;

const actionCreators = {
    getCoinsByNetwork (payload) {
        return {
            type : actions.GET_COINS_BY_NETWORK,
            payload : payload
        };
    },
    getNetworks (payload) {
        return {
            type : actions.GET_NETWORK_LIST,
            payload : payload
        };
    },
    getPaymentOptions (payload) {
        return {
            type : actions.GET_PAYMENT_OPTIONS,
            payload : payload
        };
    }
};

export default actionCreators;