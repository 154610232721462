import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Tab, Tabs } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";

import DashboardNavbar from "components/Navbars/DashboardNavbar";
import InviteCard from "components/Cards/InviteCard/DefaultInviteCard";
import TabPanel from "./components/TabPanel";
import ProfileSettingLayout from "./components/TabPanel/ProfileSetting";
import AccountSettingLayout from "./components/TabPanel/AccountSetting";
import KYCSettingLayout from "./components/TabPanel/KYCSetting";
import SecuritySettingLayout from "./components/TabPanel/SecuritySetting";

function Overview() {
    const [tabValue, setTabValue] = useState(0);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <ArgonBox>
                <ArgonBox mb={3}>
                    <InviteCard />
                </ArgonBox>
                <ArgonBox pl={3} mb={4}>
                    <ArgonTypography
                        fontWeight="medium"
                        fontSize="24px"
                        lineHeight="36px"
                        color="dark"
                    >
                        {/* Hi, User */}
                    </ArgonTypography>
                </ArgonBox>
                <ArgonBox width="400px">
                    <Tabs
                        orientation="horizontal"
                        value={tabValue}
                        onChange={handleSetTabValue}
                        style={{ background: "#DDE1FB", padding: 0 }}
                    >
                        <Tab label="Profile" />
                        <Tab label="Account" />
                        <Tab label="KYC" />
                        <Tab label="Security" />
                    </Tabs>
                </ArgonBox>
                <ArgonBox mt={2}>
                    <TabPanel value={tabValue} index={0}>
                        <ProfileSettingLayout />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <AccountSettingLayout />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <KYCSettingLayout />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <SecuritySettingLayout />
                    </TabPanel>
                </ArgonBox>
            </ArgonBox>

            <Footer />
        </DashboardLayout>
    );
}

export default Overview;
