function item(theme, ownerState) {
    const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
    const { active, darkSidenav, sidenavColor, miniSidenav } = ownerState;

    const { dark, text, transparent, white } = palette;
    const { xxl } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem, rgba } = functions;

    return {
        background: active ? "rgba(84, 107, 234, 0.2)" : transparent.main,
        color: () => {
            let result = text.main;

            if ((active && sidenavColor) || (active && darkSidenav) || darkSidenav) {
                result = white.main;
            } else if (active) {
                result = dark.main;
            }

            return result;
        },
        display: miniSidenav ? "block" : "flex",
        alignItems: "center",
        width: "100%",
        padding: `${pxToRem(12)} ${pxToRem(19.19)}`,
        marginBottom: `${pxToRem(10)}`,
        borderRadius: borderRadius.md,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        boxShadow: active && darkSidenav ? xxl : "none",

        [breakpoints.up("xl")]: {
            boxShadow: () => {
                if (active) {
                    return darkSidenav ? xxl : "none";
                }

                return "none";
            },
            transition: transitions.create("box-shadow", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
            }),
        },
    };
}

function itemIconBox(theme, ownerState) {
    const { transitions, borders, functions } = theme;
    const { darkSidenav, sidenavColor, active } = ownerState;

    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        color: "inherit",
        minWidth: pxToRem(24),
        minHeight: pxToRem(24),
        borderRadius: borderRadius.md,
        display: "grid",
        placeItems: "center",
        transition: transitions.create("margin", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),

        "& svg, svg g": {
            fill: "currentColor",
        },

        "& i": {
            color: active && (darkSidenav || sidenavColor) ? "inherit" : null,
        },
    };
}

const itemIcon = ({ palette: { white, gradients } }, { active }) => ({
    color: active ? "#546BEA" : "#666666",
});

function itemText(theme, ownerState) {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav, active } = ownerState;

    const { fontWeightMedium, fontWeightRegular } = typography;
    const { pxToRem } = functions;

    return {
        color: "inherit",
        marginLeft: pxToRem(4),

        [breakpoints.up("xl")]: {
            opacity: miniSidenav ? 0 : 1,
            maxWidth: miniSidenav ? 0 : "100%",
            marginLeft: miniSidenav ? 0 : pxToRem(15),
            transition: transitions.create(["opacity", "margin"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& span": {
            color: active ? "#546BEA" : "#666666",
            fontWeight: active ? fontWeightMedium : fontWeightRegular,
            fontSize: "16px",
            lineHeight: "24px",
        },
    };
}

export { item, itemIconBox, itemIcon, itemText };
