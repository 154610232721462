// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-label {
    font-size: 15px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
    color: black !important;
    margin-bottom: 8px;
}
.form-control {
    background: #dde1fb;
    border: 0;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #5b5e81;
    padding: 12px 20px;
    height: 48px;
    width: 100%;
    position: relative;
}
.form-control-small {
    height: 36px !important;
}

.form-control,
.form-control > input:focus {
    outline: none;
}
select.form-control {
    padding-left: 16px !important;
}

.btn-setting {
    color: #666666 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    background: #efeff4 !important;
}

.img {
    border: 1px solid #d3d3d3;
    border-radius: 12px !important;
    object-fit: cover;
}
.img:hover {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/profile/styles/style.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,2BAA2B;IAC3B,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,uBAAuB;AAC3B;;AAEA;;IAEI,aAAa;AACjB;AACA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,0BAA0B;IAC1B,4BAA4B;IAC5B,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".form-label {\n    font-size: 15px !important;\n    line-height: 22px !important;\n    font-weight: 500 !important;\n    color: black !important;\n    margin-bottom: 8px;\n}\n.form-control {\n    background: #dde1fb;\n    border: 0;\n    border-radius: 10px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n    color: #5b5e81;\n    padding: 12px 20px;\n    height: 48px;\n    width: 100%;\n    position: relative;\n}\n.form-control-small {\n    height: 36px !important;\n}\n\n.form-control,\n.form-control > input:focus {\n    outline: none;\n}\nselect.form-control {\n    padding-left: 16px !important;\n}\n\n.btn-setting {\n    color: #666666 !important;\n    font-weight: 600 !important;\n    font-size: 14px !important;\n    line-height: 22px !important;\n    background: #efeff4 !important;\n}\n\n.img {\n    border: 1px solid #d3d3d3;\n    border-radius: 12px !important;\n    object-fit: cover;\n}\n.img:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
