import * as React from "react";
import * as Spinner from "react-spinkit";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import "./Loader.css";

const Loader = ({ className, ...props }) => {
    return (
        // <DashboardLayout>
        <div {...props} className={`form-loader${className ? " " + className : ""}`}>
            <Spinner name="line-scale-pulse-out" color="rgb(3, 201, 169)" />
        </div>
        // </DashboardLayout>
    );
};
export default Loader;
