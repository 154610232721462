import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import PageLayout from "components/LayoutContainers/PageLayout";
import brand from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { hideLayout } from "layouts/authentication/styles/style";

function IllustrationLayout({ color, header, title, description, button, illustration, children }) {
    return (
        <PageLayout background="white">
            <div className="bg">
                {/* <img src={bgImage} /> */}
                <ArgonBox
                    height="100vh"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    px={15}
                >
                    <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} lg={7} sx={(theme) => hideLayout(theme)}>
                            <ArgonBox display="flex" justifyContent="flex-end" width="100%">
                                <ArgonBox
                                    position="relative"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="90%"
                                >
                                    {/* <ArgonBox
                                    component="img"
                                    src={illustration.image}
                                    alt="background"
                                    width="100%"
                                    height="768px"
                                />
                                <ArgonBox
                                    component="img"
                                    src={brand}
                                    alt="background"
                                    position="absolute"
                                    maxWidth="350px"
                                /> */}
                                </ArgonBox>
                            </ArgonBox>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <div className="login-dlg">
                                <ArgonBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="350px"
                                    height="100%"
                                >
                                    <ArgonBox maxWidth="344px">
                                        {/* <ArgonBox
                                            component="img"
                                            src={brandDark}
                                            alt="logo"
                                            width="344px"
                                            height="108px"
                                        /> */}
                                        <ArgonBox pt="18px" px={3} pl={0}>
                                            {!header ? (
                                                <>
                                                    <ArgonBox mb={1}>
                                                        <ArgonTypography
                                                            variant="h4"
                                                            fontWeight="bold"
                                                            fontSize="18px"
                                                            lineHeight="28px"
                                                            style={{ color: "#2A353A" }}
                                                        >
                                                            {title}
                                                        </ArgonTypography>
                                                    </ArgonBox>
                                                    <ArgonTypography
                                                        variant="body2"
                                                        fontWeight="regular"
                                                        fontSize="14px"
                                                        lineHeight="19px"
                                                        style={{ color: "#717579" }}
                                                    >
                                                        {description}
                                                    </ArgonTypography>
                                                </>
                                            ) : (
                                                header
                                            )}
                                        </ArgonBox>
                                        <ArgonBox pt={2}>{children}</ArgonBox>
                                    </ArgonBox>
                                </ArgonBox>
                            </div>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </div>
        </PageLayout>
    );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
    color: "info",
    header: "",
    title: "",
    description: "",
    button: { color: "info" },
    illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    header: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.object,
    children: PropTypes.node.isRequired,
    illustration: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }),
};

export default IllustrationLayout;
