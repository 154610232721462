import { createStore, combineReducers } from 'redux';
import authReducer from './reducers/auth';
import walletReducer from './reducers/wallet';
import initialState from './initialState';
import transactionReducer from './reducers/transaction';
const totalReducer = combineReducers({
    auth                : authReducer,
    wallet              : walletReducer,
    transaction         : transactionReducer
});

// const enhancer = MODE === "development" ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : undefined

const store = createStore(totalReducer, initialState);

export default store;