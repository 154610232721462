import Web3 from "web3"
import BNB from "../contract/bnb";
import { fromWei, toWei } from "../utils/convert";
import { metamaskTransaction } from "./useTransaction";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { routerAddress } from "../utils/addresses";
const web3 = new Web3(window.ethereum);
const Token = new web3.eth.Contract(BNB.abi);

export default function useToken(tokenAddress) {

    const [balance, setBalance] = useState("");
    const Token = new web3.eth.Contract(abi, tokenAddress);
    const { account } = useWeb3React();

    const approve = async (targetAddress, amount) => {
        const data = Token.methods.approve(targetAddress, toWei(amount)).encodeABI();
        return await metamaskTransaction(account, tokenAddress, data, "0");
    }
    const balanceOf = async () => {
        return await Token.methods.balanceOf(account).call();
    }
    const isRouterApproved = async () => {
        const allowance = await Token.methods.allowance(routerAddress).call();
        console.log(allowance);
    }

    useEffect(() => {
        const initBalance = async () => {
            setBalance(fromWei(await balanceOf()));
        }
        if (tokenAddress) {
            initBalance();
        }
    }, [tokenAddress])
     
    return {
        Token,
        approve,
        balanceOf,
        balance,
        isRouterApproved
    };
}

export const approve = (token, me, target, amount) => {
    const data = Token.methods.approve(target, toWei(amount)).encodeABI();
    metamaskTransaction(me, token, data, "0");
}

export const approveMax =  async(token, me, target) => {
    const data = Token.methods.approve(target, toWei("100000000")).encodeABI();
    return metamaskTransaction(me, token, data, "0");
}


export const isRouterApprovedToken = async (owner, token) => {
    Token.options.address = token;
    const allowance = await Token.methods.allowance(owner, routerAddress).call();
    if (fromWei(allowance) !== "100000000") return approveMax(token, owner, routerAddress);
    return
}