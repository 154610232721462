import { useState } from "react";
import { useMutation } from "react-query";

import { Dialog, DialogActions, DialogTitle, Divider, InputLabel } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";

import SnackbarUtils from "utils/SnackbarUtils";
import { useAuthContext } from "context/auth";
import { confirmWithdraw } from "service/apiCaller";

function WithdrawDialog({ open, onClose, asset }) {
    const { user } = useAuthContext();
    const [amount, setAmount] = useState("");
    const [address, setAddress] = useState("");
    const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);

    const handleWithdrawConfirm = () => {
        if (amount <= 0 || amount > user.balance) {
            SnackbarUtils.error("Please enther the correct amount.");
            return;
        }
        if (address == "") {
            SnackbarUtils.error("Please enther the correct address.");
            return;
        }

        onClose();
        setShowWithdrawConfirm(true);
    };

    const handleWithdrawConfirmClose = () => {
        setShowWithdrawConfirm(false);
    };

    const withdrawMutation = useMutation(confirmWithdraw, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response.data.message);
                setAmount("");
                setAddress("");
            }
        },
    });

    const handleWithdraw = () => {
        handleWithdrawConfirmClose();

        const payload = {
            method: "CRYPTO",
            asset_id: asset.id,
            crypto_address: address,
            amount: amount,
        };
        withdrawMutation.mutate(payload);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} sx={{ "& .MuiPaper-root": { width: 450 } }}>
                <ArgonBox p={3}>
                    <ArgonTypography>Withdraw</ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <ArgonBox>
                            <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>Enter amount</InputLabel>
                            <ArgonInput
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter amount"
                            ></ArgonInput>
                        </ArgonBox>
                        <ArgonBox mt={1}>
                            <InputLabel sx={{ fontSize: "14px" }}>Withdraw Address</InputLabel>
                            <ArgonInput
                                placeholder="Withdraw Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            ></ArgonInput>
                        </ArgonBox>
                        <ArgonBox mt={2} textAlign="right">
                            <ArgonButton color="primary" onClick={handleWithdrawConfirm}>
                                Withdraw
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog>

            <Dialog open={showWithdrawConfirm} onClose={handleWithdrawConfirmClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem", fontSize: "20px" }}>
                    Would you like to withdraw {amount} {asset?.symbol}?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="secondary"
                        onClick={handleWithdrawConfirmClose}
                    >
                        Disagree
                    </ArgonButton>
                    <ArgonButton variant="text" color="primary" onClick={handleWithdraw} autoFocus>
                        Agree
                    </ArgonButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default WithdrawDialog;
