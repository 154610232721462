import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { transferFund } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";

import { getAllCoins } from "service/apiCaller";
import { useAuthContext } from "context/auth";

function FundTransfer() {
    const { user, setUser } = useAuthContext();
    const [coins, setCoins] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [transferAmount, setTransferAmount] = useState("");
    const [transferSymbol, setTransferSymbol] = useState("PORTFOLIO");
    const [recipiantAddress, setRecipiantAddress] = useState("");
    const [isProceeding, setIsProceeding] = useState(false);

    const coinMutation = useMutation(getAllCoins, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setCoins(response.data.data);
            }
        },
    });

    useEffect(() => {
        coinMutation.mutate();
    }, []);

    const handleDialogOpen = () => {
        setShowDialog(true);
    };
    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const validate = () => {
        const numberRegex = /^-?\d*\.?\d+$/;
        if (!numberRegex.test(transferAmount) || transferAmount <= 0 || recipiantAddress == "") {
            SnackbarUtils.warning("Please enter the correct transfer amount and recipiant.");
            return false;
        }
        return true;
    };

    const handleCheckTransfer = () => {
        if (validate()) {
            handleDialogOpen();
        }
    };

    const handleTransfer = () => {
        handleDialogClose();
        setIsProceeding(true);
        const payload = {
            amount: transferAmount,
            symbol: transferSymbol,
            recipiant: recipiantAddress,
        };
        mutationTransfer.mutate(payload);
    };

    const mutationTransfer = useMutation(transferFund, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setUser({
                    ...user,
                    ...response.data.data,
                });
                SnackbarUtils.success("Transferred successfully!");
            }
            setTransferAmount("");
            setRecipiantAddress("");
            setIsProceeding(false);
        },
        onError: () => {
            setIsProceeding(false);
        },
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <ArgonBox></ArgonBox>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ px: 4, py: 5 }}>
                        <ArgonTypography color="dark" fontWeight="bold">
                            Balance
                        </ArgonTypography>
                        <Divider />
                        <ArgonBox
                            py={2}
                            sx={({ breakpoints }) => ({ [breakpoints.down("lg")]: { py: 0 } })}
                        >
                            <Grid container spacing={2} textAlign="center">
                                <Grid item xs={6} lg={2}>
                                    <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        Portfolio Balance
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            lineHeight: "22px",
                                            color: "#098551",
                                            mt: 1,
                                        }}
                                    >
                                        USD {Number(user.balance).toFixed(2)}
                                    </ArgonTypography>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        Philippine Peso Balance
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            lineHeight: "22px",
                                            color: "#098551",
                                            mt: 1,
                                        }}
                                    >
                                        ₱ {Number(user.pesos_balance).toFixed(2)}
                                    </ArgonTypography>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        Affiliate Rewards
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            lineHeight: "22px",
                                            color: "#098551",
                                            mt: 1,
                                        }}
                                    >
                                        USDT {Number(user.affiliate_rewards).toFixed(2)}
                                    </ArgonTypography>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        USDFX Token
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            lineHeight: "22px",
                                            color: "#098551",
                                            mt: 1,
                                        }}
                                    >
                                        USDFX {Number(user.usdfx).toFixed(2)}
                                    </ArgonTypography>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        USDT BEP20
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            lineHeight: "22px",
                                            color: "#098551",
                                            mt: 1,
                                        }}
                                    >
                                        USDT {Number(user.bep20).toFixed(2)}
                                    </ArgonTypography>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        USDT TRC20
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            lineHeight: "22px",
                                            color: "#098551",
                                            mt: 1,
                                        }}
                                    >
                                        USDT {Number(user.trc20).toFixed(2)}
                                    </ArgonTypography>
                                </Grid>
                            </Grid>
                        </ArgonBox>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={12} display="flex" justifyContent="center">
                    <Card sx={{ maxWidth: 450, width: "100%" }}>
                        <ArgonBox px={4} py={5}>
                            <ArgonTypography
                                variant="h3"
                                style={{
                                    color: "#000000",
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    lineHeight: "32px",
                                }}
                            >
                                Transfer Fund
                            </ArgonTypography>
                            <ArgonBox>
                                <ArgonBox mt={1}>
                                    <InputLabel sx={{ fontSize: 14, mb: 0.5 }}>
                                        Transfer Amount
                                    </InputLabel>
                                    <ArgonInput
                                        type="number"
                                        placeholder="Enter amount"
                                        value={transferAmount}
                                        onChange={(event) => setTransferAmount(event.target.value)}
                                    />
                                </ArgonBox>
                                <ArgonBox mt={1}>
                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                        Symbol
                                    </InputLabel>
                                    <Select
                                        value={transferSymbol}
                                        onChange={(e) => setTransferSymbol(e.target.value)}
                                        sx={{
                                            "& .MuiSelect-select": {
                                                width: "100% !important",
                                                display: "flex !important",
                                            },
                                            "& .MuiSvgIcon-root": { display: "block" },
                                            height: "40px !important",
                                            paddingLeft: "0 !important",
                                        }}
                                    >
                                        <MenuItem value="PORTFOLIO">PORTFOLIO</MenuItem>
                                        <MenuItem value="EURO">USD USDFX</MenuItem>
                                        <MenuItem value="EURO">EURO EUROFX</MenuItem>
                                        <MenuItem value="PESOS">PESO PHPFX</MenuItem>
                                        <MenuItem value="REWARDS">AFFILIATE REWARDS</MenuItem>
                                        {coins.map((coin, index) => (
                                            <MenuItem key={index} value={coin.symbol}>
                                                {coin.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArgonBox>
                                <ArgonBox mt={1}>
                                    <InputLabel sx={{ fontSize: 14, mb: 0.5 }}>
                                        Recipient Address
                                    </InputLabel>
                                    <ArgonInput
                                        type="text"
                                        placeholder="Enter Recipiant"
                                        value={recipiantAddress}
                                        onChange={(event) =>
                                            setRecipiantAddress(event.target.value)
                                        }
                                    />
                                </ArgonBox>
                                <ArgonBox mt={2} textAlign="right">
                                    <LoadingButton
                                        variant="contained"
                                        sx={{
                                            background: "#5e72e4 !important",
                                            color: "white !important",
                                        }}
                                        disabled={transferAmount <= 0 || recipiantAddress == ""}
                                        loading={isProceeding}
                                        startIcon={
                                            <ArgonTypography sx={{ display: "none" }}>
                                                Hidden Icon
                                            </ArgonTypography>
                                        }
                                        loadingPosition="start"
                                        onClick={handleCheckTransfer}
                                    >
                                        Transfer
                                    </LoadingButton>
                                </ArgonBox>
                            </ArgonBox>
                        </ArgonBox>
                    </Card>
                </Grid>
            </Grid>

            <Dialog open={showDialog} onClose={handleDialogClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem", fontSize: "20px" }}>
                    Do you want transfer funds {transferAmount} to {recipiantAddress}
                    <br />
                    Do you want to continue?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton variant="text" color="secondary" onClick={handleDialogClose}>
                        Disagree
                    </ArgonButton>
                    <ArgonButton variant="text" color="primary" onClick={handleTransfer} autoFocus>
                        Agree
                    </ArgonButton>
                </DialogActions>
            </Dialog>

            <Footer />
        </DashboardLayout>
    );
}

export default FundTransfer;
