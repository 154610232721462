// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import { useArgonController } from "context";
import ArgonButton from "components/ArgonButton";

function DetailedStaticsCard({ title, count, type, value, action, extra, icon, direction, link }) {
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const goLink = () => {
        window.location.replace(link);
    };
    return (
        <Card>
            <ArgonBox
                height="180px"
                px="16px"
                py="12px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <ArgonBox>
                    <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        fontSize="12px"
                        lineHeight="15px"
                        style={{ color: "#666666" }}
                    >
                        {title}
                    </ArgonTypography>
                    {type != 3 && (
                        <ArgonTypography
                            variant="h5"
                            fontWeight="bold"
                            fontSize="18px"
                            lineHeight="27px"
                            style={{ color: count.color }}
                        >
                            {count.text}
                        </ArgonTypography>
                    )}
                    {type === 3 && (
                        <ArgonBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <ArgonBox display="flex" flexDirection="column">
                                <ArgonTypography
                                    variant="h5"
                                    fontWeight="bold"
                                    fontSize="18px"
                                    lineHeight="27px"
                                    style={{ color: count.color }}
                                >
                                    {count.text}
                                </ArgonTypography>

                                <span
                                    style={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        lineHeight: "42px",
                                        color: "#081F32",
                                    }}
                                >
                                    {value && value.type === "money" && "$ "}
                                    {value && value.number}
                                </span>
                                {value && value.type === "day" && (
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            lineHeight: "22px",
                                            color: "#A5ADBB",
                                        }}
                                    >
                                        Days left
                                    </span>
                                )}
                            </ArgonBox>
                            <ArgonBox component="img" width="75px" src={icon} />
                        </ArgonBox>
                    )}
                </ArgonBox>
                <ArgonBox>
                    {(type === 1 || type === 3) && (
                        <ArgonButton
                            size="small"
                            onClick={goLink}
                            style={{
                                color: "#546BEA",
                                background: "rgba(84, 107, 234, 0.2)",
                                borderRadius: "6px",
                                float: "right",
                                width: "120px",
                            }}
                        >
                            {action.text}
                        </ArgonButton>
                    )}
                    {type === 2 && (
                        <ArgonBox display="flex" flexDirection="column" textAlign="right">
                            <ArgonTypography
                                variant="button"
                                textTransform="uppercase"
                                fontWeight="medium"
                                fontSize="12px"
                                lineHeight="15px"
                                style={{ color: "#666666" }}
                            >
                                {extra.title}
                            </ArgonTypography>
                            <ArgonTypography
                                variant="span"
                                fontWeight="bold"
                                fontSize="16px"
                                lineHeight="24px"
                                style={{ color: extra.color }}
                            >
                                {extra.count}
                            </ArgonTypography>
                        </ArgonBox>
                    )}
                </ArgonBox>
            </ArgonBox>
        </Card>
    );
}

// Setting default values for the props of DetailedStaticsCard
DetailedStaticsCard.defaultProps = {
    direction: "right",
};

// Typechecking props for the DetailedStaticsCard
DetailedStaticsCard.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
    }).isRequired,
    value: PropTypes.shape({
        number: PropTypes.number,
        type: PropTypes.string,
    }),
    icon: PropTypes.string,
    type: PropTypes.number,
    action: PropTypes.shape({
        text: PropTypes.string,
    }),
    extra: PropTypes.shape({
        title: PropTypes.string,
        count: PropTypes.string,
        color: PropTypes.string,
    }),
    direction: PropTypes.oneOf(["right", "left"]),
    link: PropTypes.string,
};

export default DetailedStaticsCard;
