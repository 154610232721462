import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { Checkbox, InputLabel, Dialog, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { useAuthContext } from "context/auth";
import { useMutation } from "react-query";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import { FadeLoader } from "react-spinners";

// import history from 'store/history';
import "../auth.css";
import { login, authCheck, socialRogin, addSponsor } from "service/apiCaller";

import { auth, googleProvider, facebookProvider } from "../../../firebase/firebase";
import { signInWithPopup } from "firebase/auth";

import LinkedIn from "components/LinkedIn";

function Illustration(props) {
    const { user, setUser } = useAuthContext();
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const [showModal, setShowModal] = useState(false);
    const [sponsorName, setSponsorName] = useState("");

    const getAuthCheckMutation = useMutation(authCheck, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                props.auth(response);
                setUser({
                    ...user,
                    ...response?.data?.data,
                    isAuthenticated: true,
                    token: response?.data?.tokens?.access,
                    refreshToken: response?.data?.tokens?.refresh,
                    tokenLife: Math.floor(Date.now() / 1000),
                });
                window.location.replace("/dashboard");
            } else {
                setUser({
                    isAuthenticated: false,
                    token: null,
                    tokenLife: null,
                    expire: null,
                    refreshToken: null,
                });
            }
        },
    });

    const registerMutation = useMutation(socialRogin, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                if (response.data.data.is_invited) {
                    setShowModal(true);
                    props.login(response);
                    setUser({
                        ...user,
                        ...response?.data?.data,
                        isAuthenticated: response?.data?.code == 200 ? true : false,
                        token: response?.data?.tokens?.access,
                        refreshToken: response?.data?.tokens?.refresh,
                        tokenLife: Math.floor(Date.now() / 1000),
                    });
                } else {
                    props.login(response);
                    setUser(
                        {
                            ...user,
                            ...response?.data?.data,
                            isAuthenticated: response?.data?.code == 200 ? true : false,
                            token: response?.data?.tokens?.access,
                            refreshToken: response?.data?.tokens?.refresh,
                            tokenLife: Math.floor(Date.now() / 1000),
                        },
                        () => {
                            if (response?.data?.code == 200) {
                                setLoading(false);
                                window.location.replace("/dashboard");
                            } else {
                                window.location.replace("/email-verify");
                            }
                        }
                    );
                }
            }
        },
        onError: () => {
            setLoading(false);
        },
    });

    const setSponsorNameMutation = useMutation(addSponsor, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setLoading(false);
                window.location.replace("/dashboard");
            }
        },
    });

    const setSponsorNameBySocial = () => {
        var userbody = {
            id: user.id,
            invited_by: sponsorName,
        };
        setLoading(true);
        setSponsorNameMutation.mutate(userbody);
    };

    const signInWithSocial = async (type) => {
        try {
            setLoading(true);
            var userInfo = {};
            switch (type) {
                case "google":
                    var res_google = await signInWithPopup(auth, googleProvider);

                    var data = res_google.user.reloadUserInfo;
                    userInfo.username = data.displayName;
                    userInfo.first_name = data.displayName.split(" ")[0];
                    userInfo.last_name = data.displayName.split(" ")[1];
                    userInfo.email = data.email;
                    userInfo.password = "usdfx-0000";
                    userInfo.email_verified = 1;
                    break;
                case "facebook":
                    var res_facebook = await signInWithPopup(auth, facebookProvider);

                    var data = res_facebook.user.reloadUserInfo;
                    userInfo.username = data.displayName;
                    userInfo.first_name = data.displayName.split(" ")[0];
                    userInfo.last_name = data.displayName.split(" ")[1];
                    userInfo.email = data.email;
                    userInfo.password = "usdfx-0000";
                    userInfo.email_verified = 1;
                    break;
                default:
                    break;
            }

            registerMutation.mutate(userInfo);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const postData = {
            access_token: user?.token?.token,
            refresh_token: user?.refreshToken?.token,
        };
        getAuthCheckMutation.mutate(postData);
    }, []);

    const mutation = useMutation(login, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                props.login(response);
                setUser(
                    {
                        ...user,
                        ...response?.data?.data,
                        isAuthenticated: response?.data?.code == 200 ? true : false,
                        token: response?.data?.tokens?.access,
                        refreshToken: response?.data?.tokens?.refresh,
                        tokenLife: Math.floor(Date.now() / 1000),
                    },
                    () => {
                        if (response?.data?.code == 200) {
                            window.location.replace("/dashboard");
                        } else {
                            window.location.replace("/email-verify");
                        }
                    }
                );
            }
        },
    });

    const { isSuccess, isLoading, isError, error: serverError, data: serverResponse } = mutation;
    const validate = () => {
        let errors = {};
        let isValid = true;

        if (email === "") {
            isValid = false;
            errors["email"] = "Please enter email address.";
        }
        if (email !== "") {
            let pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }
        }
        if (password === "") {
            isValid = false;
            errors["password"] = "Please enter password.";
        }
        setError(errors);
        return isValid;
    };
    const onClickLogin = () => {
        if (validate()) {
            const postData = {
                email: email,
                password: password,
            };
            mutation.mutate(postData);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onClickLogin();
        }
    };

    return (
        <>
            <div style={{ position: "absolute", left: "50%", top: "50%", zIndex: 2 }}>
                <FadeLoader
                    color={"#2dce89"}
                    loading={loading}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>

            <IllustrationLayout
                title="Log In"
                description="Welcome back , Login with your data that you entered during registration"
                illustration={{
                    image: bgImage,
                    title: '"Attention is the new currency"',
                    description:
                        "The more effortless the writing looks, the more effort the writer actually put into the process.",
                }}
            >
                <ArgonBox component="form" role="form">
                    {isSuccess && serverResponse.status === "401" ? (
                        <div className="getError_md_start">
                            <p>{serverResponse?.response?.data?.message}</p>
                        </div>
                    ) : (
                        isError && (
                            <div className="getError_md_start">
                                <p>{serverError?.response?.data?.message}</p>
                            </div>
                        )
                    )}
                    <ArgonBox mb="12px">
                        <InputLabel
                            style={{
                                fontSize: "14px",
                                lineHeight: "19px",
                                fontWeight: "700",
                                color: "#717579",
                                marginBottom: "4px",
                            }}
                        >
                            Email
                        </InputLabel>
                        <ArgonInput
                            type="email"
                            placeholder="Email"
                            size="large"
                            value={email}
                            onKeyPress={handleKeyPress}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {error.email && (
                            <div className="getError_md_start">
                                <p>{error.email}</p>
                            </div>
                        )}
                    </ArgonBox>
                    <ArgonBox mb={2}>
                        <InputLabel
                            style={{
                                fontSize: "14px",
                                lineHeight: "19px",
                                fontWeight: "700",
                                color: "#717579",
                                marginBottom: "4px",
                            }}
                        >
                            Password
                        </InputLabel>
                        <ArgonInput
                            type="password"
                            placeholder="Password"
                            size="large"
                            value={password}
                            onKeyPress={handleKeyPress}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error.password && (
                            <div className="getError_md_start">
                                <p>{error.password}</p>
                            </div>
                        )}
                    </ArgonBox>
                    <ArgonBox display="flex" alignItems="center" justifyContent="space-between">
                        <ArgonBox display="flex" alignItems="center">
                            <Checkbox
                                checked={rememberMe}
                                onChange={handleSetRememberMe}
                                color="primary"
                            />
                            <ArgonTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={handleSetRememberMe}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                            >
                                Remember my preference
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonTypography
                            component={Link}
                            to="/forgot-password"
                            variant="button"
                            color="primary"
                            fontWeight="medium"
                        >
                            Forgot Password?
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mt={2}>
                        <ArgonButton
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={onClickLogin}
                            disabled={isLoading}
                        >
                            Log In
                        </ArgonButton>
                    </ArgonBox>
                    <ArgonBox mt={2}>
                        <Grid container spacing={3} mb={3}>
                            <Grid item xs={12} md={6} lg={3}>
                                <ArgonButton
                                    color="error"
                                    size="small"
                                    fullWidth
                                    onClick={() => signInWithSocial("google")}
                                    disabled={isLoading}
                                >
                                    <GoogleIcon sx={{ fontSize: 20 }} />
                                </ArgonButton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <ArgonButton
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={() => signInWithSocial("facebook")}
                                    disabled={isLoading}
                                >
                                    <FacebookIcon />
                                </ArgonButton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <ArgonButton
                                    color="warning"
                                    size="small"
                                    fullWidth
                                    onClick={() => signInWithSocial("twiter")}
                                    disabled={isLoading}
                                >
                                    <TwitterIcon />
                                </ArgonButton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <LinkedIn
                                    registerMutation={registerMutation}
                                    setLoading={setLoading}
                                />
                            </Grid>
                        </Grid>
                    </ArgonBox>
                    <ArgonBox mt={2} textAlign="center">
                        <ArgonTypography variant="button" color="text" fontWeight="regular">
                            Don&apos;t have an account?{" "}
                            <ArgonTypography
                                component={Link}
                                to="/register"
                                variant="button"
                                color="primary"
                                fontWeight="medium"
                            >
                                Create Account
                            </ArgonTypography>
                        </ArgonTypography>
                    </ArgonBox>
                </ArgonBox>
            </IllustrationLayout>

            <Dialog
                sx={{ "& .MuiPaper-root": { width: 400 } }}
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <ArgonBox p={4}>
                    <ArgonTypography variant="h6" component="h2">
                        Sponsor Username
                    </ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <ArgonBox mt={1}>
                            <InputLabel sx={{ fontSize: 14, mb: 1 }}>
                                Input sponsor username
                            </InputLabel>
                            <ArgonInput
                                type="text"
                                placeholder="Enter Sponsor username"
                                onChange={(e) => setSponsorName(e.target.value)}
                                autoFocus
                            />
                        </ArgonBox>
                        <ArgonBox mt={2} textAlign="center">
                            <ArgonButton
                                color="primary"
                                size="small"
                                onClick={() => setSponsorNameBySocial()}
                                disabled={sponsorName.length > 0 ? false : true}
                            >
                                Enter
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog>
        </>
    );
}

// export default Illustration;
const WIndicatorPanel = connect(
    mapStoreToProps(components.AUTH),
    mapDispatchToProps(components.AUTH)
)(Illustration);
export default WIndicatorPanel;
