import actionPack from '../actions/actions';

const actions = actionPack.transaction;

const actionCreators = {
    getTransactions (payload) {
        return {
            type : actions.GET_LIST,
            payload : payload
        };
    },
    getCoins (payload) {
        return {
            type : actions.GET_COINS,
            payload : payload
        };
    }
};

export default actionCreators;