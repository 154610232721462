export const LinkedInApi = {
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUrl: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
    oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    scope: 'r_liteprofile%20r_emailaddress',
};

export const NodeServer = {
    baseURL: process.env.REACT_APP_API_URL,
    getUserCredentials: '/linkedin'
};