import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useStateWithCallbackLazy } from "use-state-with-callback";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import { AddCircle, PendingOutlined, SendRounded } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Dialog, Divider, InputLabel } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useAuthContext } from "context/auth";
import ChatMessages from "components/Message";
import SnackbarUtils from "utils/SnackbarUtils";

import { getTickets, createTicket, updateTicket, getTicket } from "service/apiCaller";

function ContactSupport() {
    const { user } = useAuthContext();
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useStateWithCallbackLazy(null);
    const [showTicketModal, setShowTicketModal] = useStateWithCallbackLazy(false);
    const [createTicketModal, setCreateTicketModal] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [ticketContent, setTicketContent] = useState("");

    const messagesEndRef = useRef(null);

    const getTicketsMutation = useMutation(getTickets, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                console.log("1111", response.data.data);
                setData(response.data.data);
            }
        },
    });

    useEffect(() => {
        getTicketsMutation.mutate();
    }, []);

    const scrollToBottom = (effect = true) => {
        if (effect) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            messagesEndRef.current?.scrollIntoView();
        }
    };

    const columns = [
        {
            name: "ticket_id",
            label: "TICKET ID",
            options: {
                sort: false,
            },
        },
        {
            name: "title",
            label: "TITLE",
            options: {
                sort: false,
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value === 0 ? <Chip label="Open" /> : <Chip label="Closed" />;
                },
            },
        },
        {
            name: "action",
            label: "ACTIONS",
            options: {
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return { align: "center" };
                },
                setCellProps: () => {
                    return { align: "center" };
                },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <IconButton
                                color="info"
                                value={dataIndex}
                                onClick={() => handleShowTicket(dataIndex)}
                                sx={{ padding: 0 }}
                            >
                                <PendingOutlined />
                            </IconButton>
                        </>
                    );
                },
            },
        },
    ];

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none !important",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            paddingBottom: "8px !important",
                            "& > span": {
                                display: "unset !important",
                            },
                            "& > span > button": {
                                padding: "6px 8px !important",
                            },
                            "&:nth-of-type(1)": {
                                width: 100,
                            },
                            "&:nth-of-type(3)": {
                                width: 100,
                            },
                            "&:nth-of-type(4)": {
                                width: 100,
                            },
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            padding: "5px 12px !important",
                        },
                    },
                },
                MUIDataTableFooter: {
                    styleOverrides: {
                        root: {
                            "& > tfoot > tr > td": {
                                borderBottom: 0,
                                padding: "0 16px !important",
                            },
                        },
                    },
                },
            },
        });

    const createTicketMutation = useMutation(createTicket, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response.data.message);
                handleCreateTicketModalClose();
                getTicketsMutation.mutate();
            }
        },
    });

    const handleCreateTicket = () => {
        const payload = {
            title: ticketTitle,
            content: ticketContent,
        };
        createTicketMutation.mutate(payload);
    };

    const getTicketMutation = useMutation(getTicket, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setSelectedData(response.data.data, () => {
                    scrollToBottom(false);
                });
            }
        },
    });

    const handleShowTicket = (dataIndex) => {
        setShowTicketModal(true, () => {
            getTicketMutation.mutate({ id: data[dataIndex].id });
        });
    };

    const handleShowTicketModalClose = () => {
        setSelectedData(null);
        setTicketContent("");
        setShowTicketModal(false);
    };

    const handleCreateTicketModalClose = () => {
        setTicketTitle("");
        setTicketContent("");
        setCreateTicketModal(false);
    };

    const updateTicketMutation = useMutation(updateTicket, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setSelectedData(
                    {
                        ...selectedData,
                        content: [
                            ...selectedData.content,
                            {
                                author: "user",
                                msg: ticketContent,
                            },
                        ],
                    },
                    () => {
                        scrollToBottom();
                        setTicketContent("");
                    }
                );
            }
        },
    });

    const handleUpdateTicket = () => {
        if (ticketContent.trim() != "") {
            const payload = {
                author: "user",
                msg: ticketContent,
                id: selectedData.id,
            };

            updateTicketMutation.mutate(payload);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            handleUpdateTicket();
            event.preventDefault();
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Card>
                <ArgonBox px={4} py={5}>
                    <ArgonTypography
                        variant="h3"
                        style={{
                            color: "#000000",
                            fontSize: "24px",
                            fontWeight: "bold",
                            lineHeight: "32px",
                        }}
                    >
                        My Tickets
                    </ArgonTypography>
                    <ArgonBox>
                        <ArgonBox textAlign="right">
                            <ArgonButton
                                color="primary"
                                size="small"
                                sx={{ px: 2 }}
                                onClick={() => setCreateTicketModal(true)}
                            >
                                <AddCircle sx={{ mr: 1 }} />
                                New ticket
                            </ArgonButton>
                        </ArgonBox>
                        <ArgonBox mt={1}>
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={data}
                                    columns={columns}
                                    options={{
                                        responsive: "simple",
                                        selectableRows: "none",
                                        filter: false,
                                        print: false,
                                        download: false,
                                        viewColumns: false,
                                        search: false,
                                        pageSize: 10,
                                        rowsPerPageOptions: [],
                                    }}
                                />
                            </ThemeProvider>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Card>

            <Dialog
                sx={{ "& .MuiPaper-root": { width: 700 } }}
                open={createTicketModal}
                onClose={handleCreateTicketModalClose}
            >
                <ArgonBox p={4}>
                    <ArgonTypography variant="h6" component="h2">
                        New Ticket
                    </ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <ArgonBox>
                            <InputLabel sx={{ fontSize: 14, mb: 1 }}>Title</InputLabel>
                            <ArgonInput
                                value={ticketTitle}
                                onChange={(event) => setTicketTitle(event.target.value)}
                            />
                        </ArgonBox>
                        <ArgonBox mt={2}>
                            <InputLabel sx={{ fontSize: 14, mb: 1 }}>Content</InputLabel>
                            <ArgonInput
                                multiline
                                rows={5}
                                value={ticketContent}
                                onChange={(event) => setTicketContent(event.target.value)}
                            />
                        </ArgonBox>
                        <ArgonBox mt={2} textAlign="right">
                            <ArgonButton
                                color="primary"
                                size="small"
                                onClick={handleCreateTicket}
                                sx={{ mr: 1 }}
                            >
                                Create
                            </ArgonButton>
                            <ArgonButton
                                color="dark"
                                size="small"
                                onClick={handleCreateTicketModalClose}
                            >
                                Close
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog>

            <Dialog
                sx={{ "& .MuiPaper-root": { width: 700 } }}
                open={showTicketModal}
                onClose={handleShowTicketModalClose}
            >
                <ArgonBox p={4}>
                    <ArgonTypography variant="h6" component="h2">
                        {selectedData ? selectedData.title : ""}
                    </ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <ArgonBox sx={{ overflowY: "auto", height: "350px" }}>
                            {selectedData &&
                                selectedData.content.map((item, index) => {
                                    return (
                                        <ChatMessages
                                            key={index}
                                            side={item.author === "user" ? "right" : "left"}
                                            messages={[item.msg]}
                                        />
                                    );
                                })}
                            <ArgonBox ref={messagesEndRef} />
                        </ArgonBox>
                        {selectedData && selectedData.status === 0 && (
                            <ArgonBox mt={2} display="flex">
                                <ArgonInput
                                    multiline
                                    placeholder="Type comment"
                                    value={ticketContent}
                                    onChange={(event) => setTicketContent(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                                <IconButton
                                    color="primary"
                                    onClick={handleUpdateTicket}
                                    sx={{ ml: 1 }}
                                >
                                    <SendRounded />
                                </IconButton>
                            </ArgonBox>
                        )}
                    </ArgonBox>
                </ArgonBox>
            </Dialog>

            <Footer />
        </DashboardLayout>
    );
}

export default ContactSupport;
