import Web3 from "web3"
import routerData from "../contract/PancakeRouter01";
import { routerAddress } from '../utils/addresses';
import { toWei } from '../utils/convert';
import { metamaskTransaction } from './useTransaction';
import { approve, isRouterApprovedToken } from './useToken';
import { useEffect, useState } from 'react';
const web3 = new Web3(window.ethereum);
export default function useRouter() {
    const [WETH, setWETH] = useState();
    const Router = new web3.eth.Contract(routerData.abi, routerAddress);

    // const initWETH = async () => {
    //     console.log(await Router.methods.WETH().call());
    //     setWETH(await Router.methods.WETH().call());
    // }

    // useEffect(() => {
    //     initWETH();
    // }, [])

    const addLiquidity = (
        token0Address,
        token1Address,
        amount0,
        amount1,
        min0,
        min1,
        to,
    ) => {
        const data = Router.methods.addLiquidity(
            token0Address,
            token1Address,
            toWei(amount0),
            toWei(amount1),
            toWei(min0),
            toWei(min1),
            to,
            Math.floor(Date.now() / 1000) + 60 * 10,
        ).encodeABI();

        const txParams = {
            from: to,
            to: routerAddress,
            data: data
        }

        window.ethereum.request({
            method: "eth_sendTransaction",
            params: [txParams]
        })
        .then((txhash) => console.log(txhash))
        .catch((err) => console.log(err))
    }

    const removeLiquidity = (
        token0Address,
        token1Address,
        liquidity,
        min0,
        min1,
        to,
    ) => {
        console.log(toWei(liquidity), toWei(min0), toWei(min1));
        const data = Router.methods.removeLiquidity(
            token0Address,
            token1Address,
            toWei(liquidity),
            toWei(min0),
            toWei(min1),
            to,
            Math.floor(Date.now() / 1000) + 60 * 10,
        ).encodeABI();
        metamaskTransaction(to, routerAddress, data, "");
    }

    const swapTokenToToken = async (
        amountIn,
        amountOutMin,
        path,
        to,
    ) => {
        const data = Router.methods.swapExactTokensForTokens(
            toWei(amountIn),
            toWei(amountOutMin),
            path,
            to,
            Math.floor(Date.now() / 1000) + 60 * 10
        ).encodeABI();
        // approve(path[0], account, routerAddress, toWei(amountIn));
        isRouterApprovedToken(to, path[0]).then(
            () => metamaskTransaction(to, routerAddress, data, "0")
        )
    }

    const swapBNBToToken = (
        amountIn,
        amountOutMin,
        path,
        to,
    ) => {
        const data = Router.methods.swapExactETHForTokens(
            toWei(amountOutMin),
            path,
            to,
            Math.floor(Date.now() / 1000) + 60 * 10
        ).encodeABI();
        // approve(path[0], account, routerAddress, toWei(amountIn));

        isRouterApprovedToken(to, path[0]).then(
            () => metamaskTransaction(to, routerAddress, data, amountIn)
        )
        
    }

    const getAmountOut = async (
        amountIn,
        path,
    ) => {
        console.log(amountIn);
        console.log(path);
        return await Router.methods.getAmountsOut(
            toWei(amountIn),
            path
        ).call().catch((err) => console.log(err));
    }

    return {
        WETH,
        Router,
        addLiquidity,
        swapTokenToToken,
        getAmountOut,
        removeLiquidity,
        swapBNBToToken
    };
}