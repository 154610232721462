import { useState } from "react";
import { useMutation } from "react-query";

import { Dialog, DialogActions, DialogTitle, Divider, InputLabel } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";

import SnackbarUtils from "utils/SnackbarUtils";
import { useAuthContext } from "context/auth";
import { transferFund } from "service/apiCaller";

function TransferDialog({ open, onClose, asset }) {
    const { user, setUser } = useAuthContext();
    const [amount, setAmount] = useState("");
    const [recipiant, setRecipiant] = useState("");
    const [showTransferConfirm, setShowTransferConfirm] = useState(false);

    const handleTransferConfirm = () => {
        if (amount <= 0) {
            SnackbarUtils.error("Please enther the correct amount.");
            return;
        }
        if (recipiant == "") {
            SnackbarUtils.error("Please enther the correct recipiant.");
            return;
        }

        onClose();
        setShowTransferConfirm(true);
    };

    const handleTransferConfirmClose = () => {
        setShowTransferConfirm(false);
    };

    const mutationTransfer = useMutation(transferFund, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setUser({
                    ...user,
                    ...response.data.data,
                });
                SnackbarUtils.success("Transferred successfully!");
                setAmount("");
                setRecipiant("");
            }
        },
    });

    const handleTransfer = () => {
        handleTransferConfirmClose();

        const payload = {
            amount: amount,
            symbol: asset.symbol,
            recipiant: recipiant,
        };
        mutationTransfer.mutate(payload);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} sx={{ "& .MuiPaper-root": { width: 450 } }}>
                <ArgonBox p={3}>
                    <ArgonTypography>Transfer Fund</ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <ArgonBox>
                            <InputLabel sx={{ fontSize: 14, mb: 0.5 }}>Transfer Amount</InputLabel>
                            <ArgonInput
                                type="number"
                                placeholder="Enter amount"
                                value={amount}
                                onChange={(event) => setAmount(event.target.value)}
                            />
                        </ArgonBox>
                        <ArgonBox mt={1}>
                            <InputLabel sx={{ fontSize: 14, mb: 0.5 }}>
                                Recipiant Address
                            </InputLabel>
                            <ArgonInput
                                type="text"
                                placeholder="Enter Recipiant"
                                value={recipiant}
                                onChange={(event) => setRecipiant(event.target.value)}
                            />
                        </ArgonBox>
                        <ArgonBox mt={2} textAlign="right">
                            <ArgonButton color="primary" onClick={handleTransferConfirm}>
                                Transfer
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog>

            <Dialog open={showTransferConfirm} onClose={handleTransferConfirmClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem", fontSize: "20px" }}>
                    Would you like to transfer {amount} {asset?.symbol} to {recipiant}?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="secondary"
                        onClick={handleTransferConfirmClose}
                    >
                        Disagree
                    </ArgonButton>
                    <ArgonButton variant="text" color="primary" onClick={handleTransfer} autoFocus>
                        Agree
                    </ArgonButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TransferDialog;
