import { bindActionCreators } from 'redux'
import authCreator from './actionCreators/auth'
import walletCreator from './actionCreators/wallet'
import transactionCreator from './actionCreators/transaction'
const components = {
    AUTH            :   "AUTH",
    WALLET          :   "WALLET",
    TRANSACTION     :   "TRANSACTION"
}
function mapStoreToProps(component) {
    switch (component) {
        case components.AUTH:
            return function (state) {
                return {
                    ...state.auth,
                }
            }
        case components.WALLET:
            return function (state) {
                return {
                    ...state.wallet,
                }
            }
        case components.TRANSACTION:
            return function (state) {
                return {
                    ...state.transaction,
                }
            }
        default:
            return undefined
    }
}
function mapDispatchToProps(component) {
    switch (component) {
        case components.AUTH:
            return function (dispatch) {
                return bindActionCreators({
                    ...authCreator
                }, dispatch)
            }
        case components.WALLET:
            return function (dispatch) {
                return bindActionCreators({
                    ...walletCreator
                }, dispatch)
            }
        case components.TRANSACTION:
            return function (dispatch) {
                return bindActionCreators({
                    ...transactionCreator
                }, dispatch)
            }
        default:
            return undefined 
    }
}
export {
    mapStoreToProps,
    mapDispatchToProps,
    components
}
