import { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { InputLabel, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";


import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import SnackbarUtils from "utils/SnackbarUtils";
import { useAuthContext } from "context/auth";

import { saveProfile } from "service/apiCaller";
import Select from "react-select";
import { Country, State } from "country-state-city";

import defaultAvatar from "assets/images/default/1.jpg";
import "./../../styles/style.css";

function ProfileSettingLayout() {
    const { user, setUser } = useAuthContext();
    const [isProcessing, setIsProcessing] = useState(false);
    const [firstName, setFirstName] = useState(user.first_name || "");
    const [lastName, setLastName] = useState(user.last_name || "");
    const [email, setEmail] = useState(user.email || "");
    const [username, setUsername] = useState(user.username || "");
    const [country, setCountry] = useState(user.country || "");
    const [state, setState] = useState(user.state || null);
    const [address, setAddress] = useState(user.address || "");
    const [phoneNumber, setPhoneNumber] = useState(user.phone_number || "");
    const [previewAvatar, setPreviewAvatar] = useState(user.avatar);
    const [avatar, setAvatar] = useState(null);
    const avatarRef = useRef(null);

    const mutation = useMutation(saveProfile, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setUser({
                    ...user,
                    ...response.data.data,
                });
                SnackbarUtils.success(response.data.message);
            }
            setIsProcessing(false);
        },
        onError: () => {
            setIsProcessing(false);
        },
    });

    const handleSaveProfile = () => {
        setIsProcessing(true);

        const profile = new FormData();
        profile.append("first_name", firstName);
        profile.append("last_name", lastName);
        profile.append("email", email);
        profile.append("username", username);
        profile.append("country", country == "null" ? "" : country);
        profile.append("state", state == "null" ? "" : state);
        profile.append("address", address);
        profile.append("phone_number", phoneNumber);
        profile.append("avatar", avatar);

        mutation.mutate(profile);
    };

    const handleAvatarChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            setAvatar(selectedImage); // Update the state with the selected file

            const imageUrl = URL.createObjectURL(selectedImage); // Create a preview URL of the selected image
            setPreviewAvatar(imageUrl);
        }
    };

    const handleAvatarUpload = () => {
        avatarRef.current.click();
    };

    return (
        <ArgonBox>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <ArgonBox
                        width="220px"
                        height="160px"
                        borderRadius="12px"
                        border="1px solid #666666"
                        p={1}
                        textAlign="center"
                        sx={{
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={handleAvatarUpload}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleAvatarChange}
                            ref={avatarRef}
                            hidden
                        />
                        <ArgonBox
                            component="img"
                            src={previewAvatar ? previewAvatar : defaultAvatar}
                            height="100%"
                            borderRadius="8px"
                        />
                    </ArgonBox>
                    <ArgonBox mt={3} mb={2}>
                        <ArgonTypography fontSize="24px" fontWeight="medium" lineHeight="36px">
                            {user.first_name} {user.last_name}
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonTypography
                        color="primary"
                        fontSize="14px"
                        lineHeight="19px"
                        fontWeight="bold"
                    >
                        QR CODE FOR AFFILIATE
                    </ArgonTypography>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <ArgonTypography fontSize="15px" fontWeight="medium" lineHeight="22px">
                        Profile Settings
                    </ArgonTypography>
                    <Grid container pt={3} rowSpacing={3} columnSpacing={4}>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">First Name</InputLabel>
                            <input
                                className="form-control"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Last Name</InputLabel>
                            <input
                                className="form-control"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Email Address</InputLabel>
                            <input
                                className="form-control"
                                placeholder="Email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Username</InputLabel>
                            <input
                                className="form-control"
                                placeholder="Username"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Country</InputLabel>
                            <Select
                                options={Country.getAllCountries()}
                                getOptionLabel={(options) => {
                                    return options["name"];
                                }}
                                getOptionValue={(options) => {
                                    return options["name"];
                                }}
                                value={Country.getCountryByCode(country)}
                                onChange={(item) => {
                                    setCountry(item.isoCode);
                                }}
                                styles={{
                                    control: (styles) => ({
                                        ...styles,
                                        background: "#dde1fb",
                                        display: "flex",
                                        borderRadius: "10px",
                                        border: "0",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "#5b5e81",
                                        padding: "0 10px",
                                        height: "48px",
                                    }),
                                    option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                    }),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">State/Province</InputLabel>
                            <Select
                                options={State?.getStatesOfCountry(country)}
                                getOptionLabel={(options) => {
                                    return options["name"];
                                }}
                                getOptionValue={(options) => {
                                    return options["name"];
                                }}
                                value={State.getStateByCodeAndCountry(state, country)}
                                onChange={(item) => {
                                    setState(item.isoCode);
                                }}
                                styles={{
                                    control: (styles) => ({
                                        ...styles,
                                        background: "#dde1fb",
                                        display: "flex",
                                        borderRadius: "10px",
                                        border: "0",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "#5b5e81",
                                        padding: "0 10px",
                                        height: "48px",
                                    }),
                                    option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                    }),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Address</InputLabel>
                            <input
                                className="form-control"
                                placeholder="Address"
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel className="form-label">Phone</InputLabel>
                            <TextField
                                className="form-control"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            sx={{
                                                backgroundColor: "rgba(91, 97, 110, 0.25)",
                                                borderRadius: "8px",
                                                padding: "20px 3px 20px 10px",
                                                marginLeft: "4px",
                                                minWidth: "35px",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            +
                                            {country
                                                ? Country.getCountryByCode(
                                                      country
                                                  ).phonecode.replace(/\D/g, "")
                                                : ""}
                                        </InputAdornment>
                                    ),
                                }}
                                value={phoneNumber}
                                onChange={(event) => setPhoneNumber(event.target.value)}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    "& > div": {
                                        background: "transparent !important",
                                        padding: "0 !important",
                                        border: "0 !important",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ArgonBox display="flex" justifyContent="flex-end" mt={3}>
                <LoadingButton
                    variant="contained"
                    sx={{ background: "#5e72e4 !important", color: "white !important" }}
                    loading={isProcessing}
                    loadingPosition="start"
                    startIcon={
                        <ArgonTypography sx={{ display: "none" }}>Hidden Icon</ArgonTypography>
                    }
                    onClick={handleSaveProfile}
                >
                    Save
                </LoadingButton>
            </ArgonBox>
        </ArgonBox>
    );
}

export default ProfileSettingLayout;
