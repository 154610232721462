// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Icons
import facebookIcon from "assets/images/icons/logos_facebook.png";
import twitterIcon from "assets/images/icons/logos_twitter.png";
import linkedinIcon from "assets/images/icons/logos_linkedin.png";
import { useAuthContext } from "context/auth";
import { CLIENT_URL } from "../../../../constant";

import SnackbarUtils from "utils/SnackbarUtils";
import * as clipboard from "clipboard-polyfill";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

const shareButtonStyle = {
    background: "#EFEFF4",
    borderRadius: "8px",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: "600",
    color: "#666666",
};

function InviteCard() {
    const { user } = useAuthContext();
    const link = CLIENT_URL + "/register/" + user.username;

    const handleCopyLink = () => {
        clipboard.writeText(link).then(
            () => {
                SnackbarUtils.success("Copied!");
            },
            () => {
                SnackbarUtils.error("Failed to copy");
            }
        );
    };

    return (
        <Card>
            <ArgonBox p={3}>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={5}>
                        <ArgonBox>
                            <ArgonTypography
                                variant="h3"
                                style={{
                                    fontSize: "24px",
                                    lineHeight: "36px",
                                    fontWeight: "500",
                                    color: "black",
                                }}
                                mb={2}
                            >
                                BE AN AFFILIATE ! SHARE YOUR LINK TODAY!
                            </ArgonTypography>
                            <ArgonBox textAlign="left">
                                <ArgonTypography
                                    style={{
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        color: "#6E798C",
                                    }}
                                    mb="28px"
                                >
                                    Kickstart your affiliate earnings and extend the benefits and
                                    rewards to your friends by recommending them. Take the step to
                                    become an affiliate today and unlock a world of rewards!
                                </ArgonTypography>
                                <ArgonTypography
                                    color="primary"
                                    fontWeight="bold"
                                    fontSize="14px"
                                    lineHeight="19px"
                                >
                                    Go to Refer & Earn Page
                                </ArgonTypography>
                            </ArgonBox>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <ArgonBox mb={2}>
                            <Grid container spacing={1} mt={3}>
                                <Grid item xs={9} lg={9}>
                                    <ArgonInput
                                        color="primary"
                                        value={link}
                                        style={{ padding: "12px 24px" }}
                                        readOnly
                                    ></ArgonInput>
                                </Grid>
                                <Grid item xs={3} lg={3}>
                                    <ArgonButton
                                        style={{ height: "48px", width: "100%" }}
                                        color="primary"
                                        onClick={handleCopyLink}
                                    >
                                        Copy link
                                    </ArgonButton>
                                </Grid>
                            </Grid>
                        </ArgonBox>
                        <ArgonBox>
                            <Grid container spacing={2}>
                                <Grid item xs={4} lg={4}>
                                    <FacebookShareButton url={link} style={shareButtonStyle}>
                                        <ArgonBox component="img" src={facebookIcon} mr={1} /> Share
                                    </FacebookShareButton>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                    <TwitterShareButton url={link} style={shareButtonStyle}>
                                        <ArgonBox component="img" src={twitterIcon} mr={1} /> Tweet
                                    </TwitterShareButton>
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                    <LinkedinShareButton url={link} style={shareButtonStyle}>
                                        <ArgonBox component="img" src={linkedinIcon} mr={1} />
                                        Post
                                    </LinkedinShareButton>
                                </Grid>
                            </Grid>
                        </ArgonBox>
                    </Grid>
                </Grid>
            </ArgonBox>
        </Card>
    );
}

export default InviteCard;
