import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";

import {
    Card,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SnackbarUtils from "utils/SnackbarUtils";
import CurrencyFlag from "react-currency-flags";

import { useAuthContext } from "context/auth";
import { getAllCoins } from "service/apiCaller";
import { getBankInfo } from "service/apiCaller";
import { confirmWithdraw } from "service/apiCaller";
import { confirmRewardsWithdraw } from "service/apiCaller";

function Withdrawal() {
    const { user, setUser } = useAuthContext();
    const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
    const [fiatCurrencies, setFiatCurrencies] = useState([]);
    // const [total, setTotal] = useState(0);
    // const [data, setData] = useState([]);
    // const [selectedData, setSelectedData] = useState(null);
    // const [showDetial, setShowDetail] = useState(false);

    const [method, setMethod] = useState("CRYPTO");
    const [amount, setAmount] = useState("");
    const [asset, setAsset] = useState(1);
    const [currency, setCurrency] = useState("USD");
    const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);
    const [cryptoAddress, setCryptoAddress] = useState("");
    const [fiatAddress, setFiatAddress] = useState({});

    const [rewards, setRewards] = useState("");
    const [showRewardsConfirm, setShowRewardsConfirm] = useState(false);

    const handleRewardsWithdrawConfirmOpen = () => {
        if (Number(rewards) <= 0 || Number(rewards) > Number(user.affiliate_rewards)) {
            SnackbarUtils.error("Please enther the correct amount.");
            return;
        }
        setShowRewardsConfirm(true);
    };

    const handleRewardsWithdrawConfirmClose = () => {
        setShowRewardsConfirm(false);
    };

    const rewardsWithdrawMutation = useMutation(confirmRewardsWithdraw, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setUser({
                    ...user,
                    ...response.data.data,
                });
                SnackbarUtils.success(response.data.message);
                setRewards("");
                handleRewardsWithdrawConfirmClose();
            }
        },
    });

    const handleRewardsWithdraw = () => {
        const payload = {
            amount: rewards,
        };
        rewardsWithdrawMutation.mutate(payload);
    };

    const coinMutation = useMutation(getAllCoins, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setCryptoCurrencies(response.data.data);
            }
        },
    });

    const getBankInfoMutation = useMutation(getBankInfo, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                const bankInfo = response.data.data;
                setFiatAddress({
                    account_name: bankInfo.account_name,
                    account_number: bankInfo.account_number,
                    bank_name: bankInfo.bank_name,
                    bank_address: bankInfo.bank_address,
                    iban_number: bankInfo.iban_number,
                    swift_code: bankInfo.swift_code,
                });
            }
        },
    });

    const getFiatCurrencies = async () => {
        const ret = await axios(`https://api.exchangerate.host/symbols`);
        setFiatCurrencies(ret.data.symbols);
    };

    useEffect(() => {
        coinMutation.mutate();
        getBankInfoMutation.mutate();
        getFiatCurrencies();
    }, []);

    const handleFiatInfoChange = (e) => {
        const addressInfo = { ...fiatAddress };
        addressInfo[e.target.name] = e.target.value;
        setFiatAddress(addressInfo);
    };

    const handleWithdrawConfirmOpen = () => {
        if (amount <= 0 || amount > user.balance) {
            SnackbarUtils.error("Please enther the correct amount.");
            return;
        }
        if (method == "CRYPTO" && cryptoAddress == "") {
            SnackbarUtils.error("Please enther the correct address.");
            return;
        }
        if (method == "FIAT") {
            if (
                fiatAddress.account_name == "" ||
                fiatAddress.account_number == "" ||
                fiatAddress.bank_name == "" ||
                fiatAddress.bank_address == "" ||
                fiatAddress.iban_number == "" ||
                fiatAddress.swift_code == ""
            ) {
                SnackbarUtils.error("Please enther the correct account information.");
                return;
            }
        }
        setShowWithdrawConfirm(true);
    };

    const handleWithdrawConfirmClose = () => {
        setShowWithdrawConfirm(false);
    };

    const withdrawMutation = useMutation(confirmWithdraw, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setShowWithdrawConfirm(false);
                setAmount("");
                setCryptoAddress("");
                SnackbarUtils.success(response.data.message);
            }
        },
    });

    const handleWithdraw = () => {
        let payload = {
            method: method,
            amount: amount,
        };
        if (method == "CRYPTO") {
            payload = { ...payload, crypto_address: cryptoAddress, asset_id: asset };
        } else {
            payload = {
                ...payload,
                fiat_address: JSON.stringify(fiatAddress),
                fiat_currency: currency,
            };
        }

        withdrawMutation.mutate(payload);
    };

    // const mutation = useMutation(getWithdraws, {
    //     onSuccess: (response) => {
    //         if (response?.data?.status) {
    //             setTotal(response?.data?.data?.count);
    //             setData(response?.data?.data?.rows);
    //         }
    //     },
    // });

    // const updateTable = (tableState) => {
    //     let filterOptions = { ...tableState };
    //     mutation.mutate(filterOptions);
    // };

    // const handleShowDetailOpen = (dataIndex) => {
    //     const info = data[dataIndex];
    //     if (info.method == "CRYPTO") {
    //         setSelectedData(info);
    //     } else {
    //         setSelectedData({ ...info, fiat_address: JSON.parse(info.fiat_address) });
    //     }
    //     setShowDetail(true);
    // };

    // const handleShowDetailClose = () => {
    //     setSelectedData(null);
    //     setShowDetail(false);
    // };

    // const columns = [
    //     {
    //         name: "updatedAt",
    //         label: "TIME",
    //         options: {
    //             customBodyRender: (value) => {
    //                 return moment(value).format("HH:MM, DD-MM-YYYY");
    //             },
    //         },
    //     },
    //     {
    //         name: "method",
    //         label: "WITHDRAW METHOD",
    //         options: {
    //             setCellHeaderProps: () => {
    //                 return { align: "center" };
    //             },
    //             setCellProps: () => {
    //                 return { align: "center" };
    //             },
    //         },
    //     },
    //     {
    //         name: "currency",
    //         label: "CURRENCY",
    //         options: {
    //             customBodyRenderLite: (dataIndex) => {
    //                 return data[dataIndex].method == "CRYPTO"
    //                     ? data[dataIndex].coin_symbol
    //                     : data[dataIndex].fiat_currency;
    //             },
    //         },
    //     },
    //     {
    //         name: "amount",
    //         label: "AMOUNT",
    //         options: {
    //             setCellHeaderProps: () => {
    //                 return { align: "right" };
    //             },
    //             setCellProps: () => {
    //                 return { align: "right" };
    //             },
    //         },
    //     },
    //     {
    //         name: "status",
    //         label: "STATUS",
    //         options: {
    //             setCellHeaderProps: () => {
    //                 return { align: "center" };
    //             },
    //             setCellProps: () => {
    //                 return { align: "center" };
    //             },
    //         },
    //     },
    //     {
    //         name: "action",
    //         label: "ACTIONS",
    //         options: {
    //             sort: false,
    //             empty: true,
    //             setCellHeaderProps: () => {
    //                 return { align: "center" };
    //             },
    //             setCellProps: () => {
    //                 return { align: "center" };
    //             },
    //             customBodyRenderLite: (dataIndex) => {
    //                 return (
    //                     <>
    //                         <IconButton
    //                             color="info"
    //                             onClick={() => handleShowDetailOpen(dataIndex)}
    //                             sx={{ padding: 0 }}
    //                         >
    //                             <PendingOutlined />
    //                         </IconButton>
    //                     </>
    //                 );
    //             },
    //         },
    //     },
    // ];

    // const option = {
    //     responsive: "simple",
    //     selectableRows: "none",
    //     filter: false,
    //     print: false,
    //     download: false,
    //     viewColumns: false,
    //     search: false,
    //     pageSize: 10,
    //     rowsPerPageOptions: [],
    //     serverSide: true,
    //     count: total,
    //     onTableInit: () => {
    //         updateTable(null);
    //     },
    //     onTableChange: (action, tableState) => {
    //         switch (action) {
    //             case "sort":
    //             case "changePage": {
    //                 updateTable(tableState);
    //                 break;
    //             }
    //             default:
    //                 break;
    //         }
    //     },
    // };

    // const getMuiTheme = () =>
    //     createTheme({
    //         components: {
    //             MUIDataTable: {
    //                 styleOverrides: {
    //                     root: {
    //                         boxShadow: "none !important",
    //                     },
    //                 },
    //             },
    //             MUIDataTableHeadCell: {
    //                 styleOverrides: {
    //                     root: {
    //                         paddingBottom: "8px !important",
    //                         "& > span": {
    //                             display: "unset !important",
    //                         },
    //                         "& > span > button": {
    //                             padding: "6px 8px !important",
    //                         },
    //                         "&:nth-of-type(7)": {
    //                             width: 100,
    //                         },
    //                     },
    //                 },
    //             },
    //             MUIDataTableBodyCell: {
    //                 styleOverrides: {
    //                     root: {
    //                         padding: "5px 12px !important",
    //                     },
    //                 },
    //             },
    //             MUIDataTableFooter: {
    //                 styleOverrides: {
    //                     root: {
    //                         "& > tfoot > tr > td": {
    //                             borderBottom: 0,
    //                             padding: "0 16px !important",
    //                         },
    //                     },
    //                 },
    //             },
    //         },
    //     });

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <ArgonBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <Card sx={{ px: 4, py: 5 }}>
                            <ArgonTypography color="dark" fontWeight="bold">
                                Balance
                            </ArgonTypography>
                            <Divider />
                            <ArgonBox
                                py={2}
                                sx={({ breakpoints }) => ({ [breakpoints.down("lg")]: { py: 0 } })}
                            >
                                <Grid container spacing={2} textAlign="center">
                                    <Grid item xs={6} lg={2}>
                                        <ArgonTypography
                                            sx={{ fontSize: "14px", fontWeight: "600" }}
                                        >
                                            Portfolio Balance
                                        </ArgonTypography>
                                        <ArgonTypography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                lineHeight: "22px",
                                                color: "#098551",
                                                mt: 1,
                                            }}
                                        >
                                            USD {Number(user.balance).toFixed(2)}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <ArgonTypography
                                            sx={{ fontSize: "14px", fontWeight: "600" }}
                                        >
                                            Pesos Balance
                                        </ArgonTypography>
                                        <ArgonTypography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                lineHeight: "22px",
                                                color: "#098551",
                                                mt: 1,
                                            }}
                                        >
                                            ₱ {Number(user.pesos_balance).toFixed(2)}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <ArgonTypography
                                            sx={{ fontSize: "14px", fontWeight: "600" }}
                                        >
                                            Affiliate Rewards
                                        </ArgonTypography>
                                        <ArgonTypography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                lineHeight: "22px",
                                                color: "#098551",
                                                mt: 1,
                                            }}
                                        >
                                            USDT {Number(user.affiliate_rewards).toFixed(2)}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <ArgonTypography
                                            sx={{ fontSize: "14px", fontWeight: "600" }}
                                        >
                                            USDFX
                                        </ArgonTypography>
                                        <ArgonTypography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                lineHeight: "22px",
                                                color: "#098551",
                                                mt: 1,
                                            }}
                                        >
                                            USDFX {Number(user.usdfx).toFixed(2)}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <ArgonTypography
                                            sx={{ fontSize: "14px", fontWeight: "600" }}
                                        >
                                            BEP20
                                        </ArgonTypography>
                                        <ArgonTypography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                lineHeight: "22px",
                                                color: "#098551",
                                                mt: 1,
                                            }}
                                        >
                                            USDT {Number(user.bep20).toFixed(2)}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={6} lg={2}>
                                        <ArgonTypography
                                            sx={{ fontSize: "14px", fontWeight: "600" }}
                                        >
                                            TRC20
                                        </ArgonTypography>
                                        <ArgonTypography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                lineHeight: "22px",
                                                color: "#098551",
                                                mt: 1,
                                            }}
                                        >
                                            USDT {Number(user.trc20).toFixed(2)}
                                        </ArgonTypography>
                                    </Grid>
                                </Grid>
                            </ArgonBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card sx={{ px: 4, py: 5 }}>
                            <ArgonTypography color="dark" fontWeight="bold">
                                Withdraw
                            </ArgonTypography>
                            <Divider />
                            <ArgonBox>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} lg={6}>
                                        <ArgonBox>
                                            <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                Enter amount
                                            </InputLabel>
                                            <ArgonInput
                                                type="number"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                placeholder="Enter amount"
                                            ></ArgonInput>
                                        </ArgonBox>
                                    </Grid>
                                    {method == "CRYPTO" ? (
                                        <>
                                            <Grid item xs={6} lg={6}>
                                                <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                    Crypto Currency
                                                </InputLabel>
                                                <Select
                                                    value={asset}
                                                    onChange={(e) => setAsset(e.target.value)}
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            width: "100% !important",
                                                            display: "flex !important",
                                                        },
                                                        "& .MuiSvgIcon-root": { display: "block" },
                                                        height: "40px !important",
                                                        paddingLeft: "0 !important",
                                                    }}
                                                >
                                                    {cryptoCurrencies.map((coin, index) => (
                                                        <MenuItem key={index} value={coin.id}>
                                                            <ArgonBox
                                                                component="img"
                                                                src={coin?.image_url}
                                                                mr={2}
                                                                sx={{ width: 20, height: 20 }}
                                                            />
                                                            {coin.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <InputLabel sx={{ fontSize: "14px" }}>
                                                    Withdraw Address
                                                </InputLabel>
                                                <ArgonInput
                                                    placeholder="Withdraw Address"
                                                    value={cryptoAddress}
                                                    onChange={(e) =>
                                                        setCryptoAddress(e.target.value)
                                                    }
                                                ></ArgonInput>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={6} lg={6}>
                                                <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                    Fiat Currency
                                                </InputLabel>
                                                <Select
                                                    value={currency}
                                                    onChange={(e) => setCurrency(e.target.value)}
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            width: "100% !important",
                                                            display: "flex !important",
                                                            gap: "8px",
                                                        },
                                                        "& .MuiSvgIcon-root": { display: "block" },
                                                        height: "40px !important",
                                                        paddingLeft: "0 !important",
                                                    }}
                                                >
                                                    {Object.keys(fiatCurrencies).map(
                                                        (fiatCurrency, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={fiatCurrency}
                                                                sx={{
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <CurrencyFlag
                                                                    currency={fiatCurrency}
                                                                    width={20}
                                                                />
                                                                {fiatCurrency}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <ArgonBox>
                                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                        Account Name
                                                    </InputLabel>
                                                    <ArgonInput
                                                        placeholder="Account Name"
                                                        name="account_name"
                                                        value={fiatAddress?.account_name || ""}
                                                        onChange={handleFiatInfoChange}
                                                    ></ArgonInput>
                                                </ArgonBox>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <ArgonBox>
                                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                        Account Number
                                                    </InputLabel>
                                                    <ArgonInput
                                                        placeholder="Account Number"
                                                        name="account_number"
                                                        value={fiatAddress?.account_number || ""}
                                                        onChange={handleFiatInfoChange}
                                                    ></ArgonInput>
                                                </ArgonBox>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <ArgonBox>
                                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                        Bank Name
                                                    </InputLabel>
                                                    <ArgonInput
                                                        placeholder="Bank Name"
                                                        name="bank_name"
                                                        value={fiatAddress?.bank_name || ""}
                                                        onChange={handleFiatInfoChange}
                                                    ></ArgonInput>
                                                </ArgonBox>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <ArgonBox>
                                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                        Bank Address
                                                    </InputLabel>
                                                    <ArgonInput
                                                        placeholder="Bank Address"
                                                        name="bank_address"
                                                        value={fiatAddress?.bank_address || ""}
                                                        onChange={handleFiatInfoChange}
                                                    ></ArgonInput>
                                                </ArgonBox>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <ArgonBox>
                                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                        IBAN Number
                                                    </InputLabel>
                                                    <ArgonInput
                                                        placeholder="IBAN Number"
                                                        name="iban_number"
                                                        value={fiatAddress?.iban_number || ""}
                                                        onChange={handleFiatInfoChange}
                                                    ></ArgonInput>
                                                </ArgonBox>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <ArgonBox>
                                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                                        SWIFT Code
                                                    </InputLabel>
                                                    <ArgonInput
                                                        placeholder="SWIFT Code"
                                                        name="swift_code"
                                                        value={fiatAddress?.swift_code || ""}
                                                        onChange={handleFiatInfoChange}
                                                    ></ArgonInput>
                                                </ArgonBox>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <RadioGroup
                                    row
                                    sx={{ mt: 1, "& > label": { marginLeft: 0 } }}
                                    value={method}
                                    onChange={(e) => setMethod(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="CRYPTO"
                                        control={<Radio />}
                                        label="CRYPTO"
                                    />
                                    <FormControlLabel
                                        value="FIAT"
                                        control={<Radio />}
                                        label="FIAT"
                                    />
                                </RadioGroup>
                                <ArgonButton
                                    color="primary"
                                    sx={{ mt: 2, float: "right" }}
                                    onClick={handleWithdrawConfirmOpen}
                                >
                                    Withdraw
                                </ArgonButton>
                            </ArgonBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card sx={{ px: 4, py: 5 }}>
                            <ArgonTypography color="dark" fontWeight="bold">
                                Affiliate Rewards to Portfolio Balance
                            </ArgonTypography>
                            <Divider />
                            <ArgonBox>
                                <ArgonBox>
                                    <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>
                                        Enter amount
                                    </InputLabel>
                                    <ArgonInput
                                        type="number"
                                        value={rewards}
                                        onChange={(e) => setRewards(e.target.value)}
                                        placeholder="Enter amount"
                                    ></ArgonInput>
                                </ArgonBox>
                                <ArgonButton
                                    color="primary"
                                    sx={{ mt: 2, float: "right" }}
                                    onClick={handleRewardsWithdrawConfirmOpen}
                                >
                                    Convert
                                </ArgonButton>
                            </ArgonBox>
                        </Card>
                    </Grid>
                </Grid>
                {/* <Card sx={{ mt: 3, px: 4, py: 5 }}>
                    <ArgonTypography
                        sx={{
                            fontSize: "24px",
                            lineHeight: "32px",
                            fontWeight: "bold",
                            color: "#000",
                        }}
                    >
                        Withdraw History
                    </ArgonTypography>
                    <ArgonBox mt={3}>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable data={data} columns={columns} options={option} />
                        </ThemeProvider>
                    </ArgonBox>
                </Card> */}
            </ArgonBox>

            <Dialog open={showWithdrawConfirm} onClose={handleWithdrawConfirmClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    Are you sure you want to withdraw?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="secondary"
                        onClick={handleWithdrawConfirmClose}
                    >
                        No
                    </ArgonButton>
                    <ArgonButton variant="text" color="primary" onClick={handleWithdraw} autoFocus>
                        Yes
                    </ArgonButton>
                </DialogActions>
            </Dialog>

            <Dialog open={showRewardsConfirm} onClose={handleRewardsWithdrawConfirmClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    Are you sure you want to withdraw?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton
                        variant="text"
                        color="secondary"
                        onClick={handleRewardsWithdrawConfirmClose}
                    >
                        No
                    </ArgonButton>
                    <ArgonButton
                        variant="text"
                        color="primary"
                        onClick={handleRewardsWithdraw}
                        autoFocus
                    >
                        Yes
                    </ArgonButton>
                </DialogActions>
            </Dialog>

            {/* <Dialog
                open={showDetial}
                onClose={handleShowDetailClose}
                sx={{ "& .MuiPaper-root": { width: "450px" } }}
            >
                <ArgonBox p={3}>
                    <ArgonTypography fontWeight="bold" lineHeight="30px">
                        Withdraw Information
                    </ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={12}>
                                <ArgonTypography fontSize="16px" fontWeight="medium">
                                    {(selectedData &&
                                        moment(selectedData.updatedAt).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )) ||
                                        ""}
                                </ArgonTypography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <ArgonBox>
                                    <InputLabel sx={{ fontSize: "14px" }}>Amount</InputLabel>
                                    <ArgonTypography fontSize="16px" fontWeight="medium">
                                        {(selectedData && selectedData.amount) || ""}
                                    </ArgonTypography>
                                </ArgonBox>
                            </Grid>
                            {selectedData && selectedData.method == "CRYPTO" && (
                                <>
                                    <Grid item xs={6} lg={6}>
                                        <ArgonBox>
                                            <InputLabel sx={{ fontSize: "14px" }}>
                                                Crypto Currency
                                            </InputLabel>
                                            <ArgonTypography fontSize="16px" fontWeight="medium">
                                                {(selectedData && selectedData.coin_symbol) || ""}
                                            </ArgonTypography>
                                        </ArgonBox>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <InputLabel sx={{ fontSize: "14px" }}>
                                            Withdraw Address
                                        </InputLabel>
                                        <ArgonTypography
                                            fontSize="16px"
                                            fontWeight="medium"
                                            sx={{ overflowWrap: "break-word" }}
                                        >
                                            {(selectedData && selectedData.crypto_address) || ""}
                                        </ArgonTypography>
                                    </Grid>
                                </>
                            )}
                            {selectedData && selectedData.method == "FIAT" && (
                                <>
                                    <Grid item xs={6} lg={6}>
                                        <ArgonBox>
                                            <InputLabel sx={{ fontSize: "14px" }}>
                                                Fiat Currency
                                            </InputLabel>
                                            <ArgonTypography fontSize="16px" fontWeight="medium">
                                                {(selectedData && selectedData.fiat_currency) || ""}
                                            </ArgonTypography>
                                        </ArgonBox>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel sx={{ fontSize: "14px" }}>
                                            Account Name
                                        </InputLabel>
                                        <ArgonTypography fontSize="16px" fontWeight="medium">
                                            {(selectedData &&
                                                selectedData.fiat_address.account_name) ||
                                                ""}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel sx={{ fontSize: "14px" }}>
                                            Account Number
                                        </InputLabel>
                                        <ArgonTypography fontSize="16px" fontWeight="medium">
                                            {(selectedData &&
                                                selectedData.fiat_address.account_number) ||
                                                ""}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel sx={{ fontSize: "14px" }}>Bank Name</InputLabel>
                                        <ArgonTypography fontSize="16px" fontWeight="medium">
                                            {(selectedData &&
                                                selectedData.fiat_address.bank_name) ||
                                                ""}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel sx={{ fontSize: "14px" }}>
                                            Bank Address
                                        </InputLabel>
                                        <ArgonTypography fontSize="16px" fontWeight="medium">
                                            {(selectedData &&
                                                selectedData.fiat_address.bank_address) ||
                                                ""}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel sx={{ fontSize: "14px" }}>
                                            IBAN Number
                                        </InputLabel>
                                        <ArgonTypography fontSize="16px" fontWeight="medium">
                                            {(selectedData &&
                                                selectedData.fiat_address.iban_number) ||
                                                ""}
                                        </ArgonTypography>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <InputLabel sx={{ fontSize: "14px" }}>
                                            SWIFT Code
                                        </InputLabel>
                                        <ArgonTypography fontSize="16px" fontWeight="medium">
                                            {(selectedData &&
                                                selectedData.fiat_address.swift_code) ||
                                                ""}
                                        </ArgonTypography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <ArgonBox mt={1} textAlign="right">
                            <ArgonButton color="dark" onClick={handleShowDetailClose}>
                                Close
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog> */}

            <Footer />
        </DashboardLayout>
    );
}

export default Withdrawal;
