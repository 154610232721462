import React, { createContext, useEffect, useState } from "react";
import { useAuthContext } from "context/auth";
import io from "socket.io-client";

export const SocketContext = createContext();

// Create the provider component
export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    // const { user } = useAuthContext();
    useEffect(() => {
        // Connect to the server
        setTimeout(() => {
            const user = JSON.parse(localStorage.getItem("user"));
            const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
                query: { token: `${user?.token?.token ? user.token.token : ""}` },
            });
            setSocket(newSocket);
        }, 2000);

        // Clean up on unmount
        // return () => {
        //   newSocket.disconnect();
        // };
    }, []);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
