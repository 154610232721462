import { toHex, toWei } from "utils/convert";
import SnackbarUtils from "utils/SnackbarUtils";
export const metamaskTransaction = async (from, to, data, value) => {
    
    if (value) console.log(toWei(value));
    const txParams = {
        from: from,
        to: to,
        data: data,
        value: value ? toHex(toWei(value)) : "0",
    }
    return await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [txParams]
    })
    .catch((err) => {
        // throw new Error(err);
        SnackbarUtils.error(err)
    })
}
