import { useState } from "react";
import { useMutation } from "react-query";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";


import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

import SnackbarUtils from "utils/SnackbarUtils";

import { useAuthContext } from "context/auth";
import { investPackage } from "service/apiCaller";

function DefaultPricingCard({ type, logo, percentage, days }) {
    const { user, setUser } = useAuthContext();
    const [balanceType, setBalanceType] = useState("wallet");
    const [capital, setCapital] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const investMutation = useMutation(investPackage, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setUser({
                    ...user,
                    ...response?.data?.data,
                });
                SnackbarUtils.success(response?.data?.message);
                setShowModal(false);
            }
        },
    });

    const validate = () => {
        const numberRegex = /^-?\d*\.?\d+$/;
        if (!numberRegex.test(capital) || capital <= 0) {
            SnackbarUtils.warning("Please enter the correct investment amount.");
            return false;
        }
        if (balanceType == "wallet") {
            if (capital > user.balance) {
                handleDialogOpen();
                return false;
            }
        } else {
            if (capital > user.affiliate_rewards) {
                SnackbarUtils.warning("The investment amount exceeds your affiliate rewards.");
                return false;
            }
        }
        return true;
    };

    const handleProceedInvest = () => {
        if (validate()) {
            const payload = {
                type: type,
                balanceType: balanceType,
                capital: capital,
            };
            investMutation.mutate(payload);
        }
    };

    const handleModalOpen = () => {
        setCapital("");
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleDialogOpen = () => {
        setShowDialog(true);
    };
    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleWalletDeposit = () => {
        window.location.replace("/wallet");
    };
    return (
        <Card>
            <ArgonBox px="30px" py="22px">
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
                    <ArgonBox display="flex" flexDirection="column" alignItems="center">
                        <ArgonBox component="img" src={logo} width="80px" height="80px" />
                        <ArgonBox>
                            <ArgonTypography
                                variant="span"
                                fontSize="20px"
                                fontWeight="bold"
                                lineHeight="22px"
                                style={{ color: color[type] }}
                            >
                                {percentage}%
                            </ArgonTypography>
                            <ArgonTypography
                                variant="span"
                                style={{ color: "#A5ADBB" }}
                                fontSize="14px"
                                lineHeight="22px"
                            >
                                /APY
                            </ArgonTypography>
                        </ArgonBox>
                    </ArgonBox>
                    <ArgonBox textAlign="center">
                        <ArgonTypography
                            fontSize="16px"
                            lineHeight="22px"
                            style={{ color: color[type] }}
                        >
                            {type}
                        </ArgonTypography>
                        <ArgonTypography
                            fontSize="30px"
                            lineHeight="34px"
                            style={{ color: "#081F32", fontWeight: "700" }}
                        >
                            {days}
                        </ArgonTypography>
                        <ArgonTypography fontSize="14px" style={{ color: "#A5ADBB" }}>
                            Days
                        </ArgonTypography>
                    </ArgonBox>
                </ArgonBox>
                <ArgonBox mt="12px">
                    <ArgonTypography display="flex" alignItems="center">
                        <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
                        <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
                            Earn {percentage}% interest for {days} Days
                        </span>
                    </ArgonTypography>
                    <ArgonTypography display="flex" alignItems="center">
                        <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
                        <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
                            Compounded Interest
                        </span>
                    </ArgonTypography>
                    <ArgonTypography display="flex" alignItems="center">
                        <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
                        <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
                            Unlock your USDFX Monthly
                        </span>
                    </ArgonTypography>
                    <ArgonTypography display="flex" alignItems="center">
                        <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
                        <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
                            Interest are paid in USDFX
                        </span>
                    </ArgonTypography>
                </ArgonBox>
                <ArgonBox textAlign="center" mt={1}>
                    {type == "Starter" && (
                        <ArgonButton
                            size="small"
                            fullWidth
                            style={{
                                color: "#888F96",
                                background: "#A5FFAE",
                                borderRadius: "8px",
                                fontSize: "12px",
                                fontWeight: "400",
                                width: "167px",
                                padding: 0,
                            }}
                            onClick={handleModalOpen}
                        >
                            Subscribe
                        </ArgonButton>
                    )}
                    {type == "Silver" && (
                        <ArgonButton
                            size="small"
                            fullWidth
                            style={{
                                color: "#626ABB",
                                background: "#B5CAF1",
                                borderRadius: "8px",
                                fontSize: "12px",
                                fontWeight: "400",
                                width: "167px",
                                padding: 0,
                            }}
                            onClick={handleModalOpen}
                        >
                            Subscribe
                        </ArgonButton>
                    )}
                    {type == "Gold" && (
                        <ArgonButton
                            size="small"
                            fullWidth
                            style={{
                                color: "#C79107",
                                background: "#FFF4BD",
                                borderRadius: "8px",
                                fontSize: "12px",
                                fontWeight: "400",
                                width: "167px",
                                padding: 0,
                            }}
                            onClick={handleModalOpen}
                        >
                            Subscribe
                        </ArgonButton>
                    )}
                    {type == "Premium" && (
                        <ArgonButton
                            size="small"
                            fullWidth
                            style={{
                                color: "#7446EA",
                                background: "#DDD1FA",
                                borderRadius: "8px",
                                fontSize: "12px",
                                fontWeight: "400",
                                width: "167px",
                                padding: 0,
                            }}
                            onClick={handleModalOpen}
                        >
                            Subscribe
                        </ArgonButton>
                    )}
                </ArgonBox>
            </ArgonBox>

            <Dialog
                sx={{ "& .MuiPaper-root": { width: 400 } }}
                open={showModal}
                onClose={handleModalClose}
            >
                <ArgonBox p={4}>
                    <ArgonTypography variant="h6" component="h2">
                        Investment
                    </ArgonTypography>
                    <Divider />
                    <ArgonBox>
                        <Grid container spacing={1}>
                            <Grid item xs={6} lg={6} mb={2}>
                                <ArgonBox textAlign="center">
                                    <InputLabel sx={{ fontSize: 14 }}>Wallet Balance</InputLabel>
                                    <ArgonBox>
                                        <span style={{ fontSize: "14px" }}>USD</span>{" "}
                                        <span style={{ color: "blue" }}>{user.balance}</span>
                                    </ArgonBox>
                                </ArgonBox>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <ArgonBox textAlign="center">
                                    <InputLabel sx={{ fontSize: 14 }}>Affiliate Rewards</InputLabel>
                                    <ArgonBox>
                                        <span style={{ fontSize: "14px" }}>USD</span>{" "}
                                        <span style={{ color: "blue" }}>
                                            {user.affiliate_rewards}
                                        </span>
                                    </ArgonBox>
                                </ArgonBox>
                            </Grid>
                        </Grid>
                        <p style={{ fontSize: "14px", lineHeight: "18px", color: "#039" }}>
                            You are about to invest in {type} package which comes with {percentage}%
                            total interest
                        </p>
                        <ArgonBox mt={2}>
                            <InputLabel sx={{ fontSize: 14, mb: 1 }}>
                                Select Method To Purchase Package
                            </InputLabel>
                            <Select
                                value={balanceType}
                                onChange={(event) => setBalanceType(event.target.value)}
                                sx={{
                                    "& .MuiSelect-select": {
                                        width: "100% !important",
                                    },
                                    "& .MuiSvgIcon-root": { display: "block" },
                                    height: "40px !important",
                                    paddingLeft: "0 !important",
                                }}
                            >
                                <MenuItem value={"wallet"}>Wallet</MenuItem>
                                <MenuItem value={"affiliate_rewards"}>Affiliate Rewards</MenuItem>
                            </Select>
                        </ArgonBox>
                        <ArgonBox mt={1}>
                            <InputLabel sx={{ fontSize: 14, mb: 1 }}>
                                Enter Amount to Invest
                            </InputLabel>
                            <ArgonInput
                                type="number"
                                placeholder="Enter Capital to invest"
                                value={capital}
                                onChange={(event) => setCapital(event.target.value)}
                            />
                        </ArgonBox>
                        <ArgonBox mt={2} textAlign="right">
                            <ArgonButton
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                                onClick={handleProceedInvest}
                            >
                                Proceed
                            </ArgonButton>
                            <ArgonButton color="dark" size="small" onClick={handleModalClose}>
                                Close
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </Dialog>

            <Dialog open={showDialog} onClose={handleDialogClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    The investment amount exceeds your balance. Would you like to go to the deposit
                    page?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton variant="text" color="secondary" onClick={handleDialogClose}>
                        Disagree
                    </ArgonButton>
                    <ArgonButton
                        variant="text"
                        color="primary"
                        onClick={handleWalletDeposit}
                        autoFocus
                    >
                        Agree
                    </ArgonButton>
                </DialogActions>
            </Dialog>
        </Card>
    );
}

const color = {
    Starter: "#F79264",
    Silver: "#9297D0",
    Gold: "#FFDD3A",
    Premium: "#7446EA",
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
    type: PropTypes.oneOf(["Starter", "Silver", "Gold", "Premium"]).isRequired,
    percentage: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
};

export default DefaultPricingCard;
