import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
// Store
import { useAuthContext } from "context/auth";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import Loader from "components/Loader/Loader";
import { connect } from "react-redux";
import { authCheck } from "service/apiCaller";
import { useMutation } from "react-query";
import routes from "routes";

function RouterContainer(props) {
    const { pathname } = useLocation();
    const { user, setUser } = useAuthContext();

    const getAuthCheckMutation = useMutation(authCheck, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                props.auth(response);
                setUser({
                    ...user,
                    ...response?.data?.data,
                    isAuthenticated: true,
                    token: response?.data?.tokens?.access,
                    refreshToken: response?.data?.tokens?.refresh,
                    tokenLife: Math.floor(Date.now() / 1000),
                });
            } else {
                setUser({
                    isAuthenticated: false,
                    token: null,
                    tokenLife: null,
                    expire: null,
                    refreshToken: null,
                });
            }
        },
    });

    useEffect(() => {
        if (
            !pathname.includes("/login") &&
            !pathname.includes("/register") &&
            !pathname.includes("/email-verify") &&
            !pathname.includes("/forgot-password") &&
            !pathname.includes("/reset-password")
        ) {
            const postData = {
                access_token: user?.token?.token,
                refresh_token: user?.refreshToken?.token,
            };
            getAuthCheckMutation.mutate(postData);
        }
    }, [pathname]);

    const { isLoading } = getAuthCheckMutation;
    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                if (route.private) {
                    if (user.isAuthenticated) {
                        return (
                            <Route
                                exact
                                path={route.route}
                                element={route.component}
                                key={route.key}
                            />
                        );
                    } else {
                        <Route path="*" element={<Navigate to="/login" />} />;
                    }
                } else {
                    return (
                        <Route exact path={route.route} element={route.component} key={route.key} />
                    );
                }
            }
            return null;
        });

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            )}
        </>
    );
}
const WRouter = connect(
    mapStoreToProps(components.AUTH),
    mapDispatchToProps(components.AUTH)
)(RouterContainer);
export default WRouter;
