import React from "react";
import PropTypes from "prop-types";

import { Avatar, Grid, Typography } from "@mui/material";
import ArgonBox from "components/ArgonBox";

const ChatMessages = ({ avatar, messages, side }) => {
    const radius = 1;
    const size = "32px";

    const style = {
        avatar: {
            width: size,
            height: size,
        },
        msg: {
            padding: "8px 16px",
            borderRadius: 3,
            marginBottom: 0.5,
            display: "inline-block",
            wordBreak: "break-all",
            fontSize: "14px",
        },
        leftRow: {
            textAlign: "left",
        },
        left: {
            backgroundColor: "#f5f5f5",
            borderTopLeftRadius: radius,
        },
        rightRow: {
            textAlign: "right",
        },
        right: {
            backgroundColor: "#3f51b5",
            color: "#fff",
            borderBottomRightRadius: radius,
        },
    };

    return (
        <Grid container spacing={2} justifyContent={side === "right" ? "flex-end" : "flex-start"}>
            {side === "left" && avatar && (
                <Grid item>
                    <Avatar sx={style.avatar} src={avatar} />
                </Grid>
            )}
            <Grid item xs={8}>
                {messages.map((msg, i) => (
                    <ArgonBox sx={style[`${side}Row`]} key={i}>
                        <Typography align={"left"} sx={{ ...style.msg, ...style[`${side}`] }}>
                            {msg.split("\n").map((paragraph, index) => (
                                <Typography variant="span" key={index}>
                                    {paragraph}
                                    <br />
                                </Typography>
                            ))}
                        </Typography>
                    </ArgonBox>
                ))}
            </Grid>
        </Grid>
    );
};

ChatMessages.propTypes = {
    avatar: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
    side: PropTypes.oneOf(["left", "right"]),
};
ChatMessages.defaultProps = {
    avatar: "",
    messages: [],
    side: "left",
};
ChatMessages.metadata = {
    name: "Chat Messages",
};
ChatMessages.codeSandbox = "";

export default ChatMessages;
