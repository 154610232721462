import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
/* eslint-disable no-unused-vars */
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card, Grid, MenuItem, Select, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "components/Footer";
import { connect } from "react-redux";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { getTransactions, getAllCoins, confirmTransaction } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";
import "./transaction.css";

import moment from "moment";
import CsvDownloader from "react-csv-downloader";

function Transaction(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [transactionType, setTransactionType] = useState(-1);
    const [assetType, setAssetType] = useState(-1);
    const [dateRange, setDateRange] = useState(-1);
    const [confirmingTx, setConfirmingTx] = useState(0);
    const { data: coins } = useQuery("get-coins", getAllCoins, { refetchOnWindowFocus: false });
    if (coins && coins.data && coins.data.data) {
        props.getCoins(coins.data.data);
    }

    const getDates = (period) => {
        const today = moment();
        let startDate, endDate;

        switch (period) {
            case -1:
                startDate = null;
                endDate = null;
                break;
            case "Last 7 Days":
                startDate = today.clone().subtract(6, "days");
                endDate = today;
                break;
            case "Last 15 Days":
                startDate = today.clone().subtract(14, "days");
                endDate = today;
                break;
            case "Last 30 Days":
                startDate = today.clone().subtract(29, "days");
                endDate = today;
                break;
            case "This Month":
                startDate = today.clone().startOf("month");
                endDate = today;
                break;
            case "Last Month":
                startDate = today.clone().subtract(1, "month").startOf("month");
                endDate = today.clone().subtract(1, "month").endOf("month");
                break;
        }

        if (startDate) startDate = startDate.format("YYYY-MM-DD 00:00:00");
        if (endDate) endDate = endDate.format("YYYY-MM-DD 23:59:59");

        return { startDate, endDate };
    };

    const getTransactionsMutation = useMutation(getTransactions, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setTransactions(response.data.data);
            }
            setIsLoading(false);
        },
        onError: (error) => {
            setIsLoading(false);
        },
    });

    useEffect(() => {
        updateTable();
    }, [transactionType, assetType, dateRange]);

    const updateTable = () => {
        const { startDate, endDate } = getDates(dateRange);
        const payload = {
            type: transactionType,
            asset: assetType,
            startDate: startDate,
            endDate: endDate,
        };
        setIsLoading(true);
        getTransactionsMutation.mutate(payload);
    };

    const mutationTransactionConfirm = useMutation(confirmTransaction, {
        onSuccess: async (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success("Transaction Confirmed!");
                updateTable();
            }
            setConfirmingTx(0);
        },
        onError: () => {
            setConfirmingTx(0);
            setIsLoading(false);
        },
    });

    const onConfirmTransaction = (id) => {
        console.log(id);
        setConfirmingTx(id);
        const postData = {
            transactionId: id,
        };
        mutationTransactionConfirm.mutate(postData);
    };

    const columns = [
        {
            name: "updatedAt",
            label: "Time",
            options: {
                customBodyRender: (value) => moment(value).format("HH:MM, DD-MM-YYYY"),
            },
        },
        {
            name: "type",
            label: "Transaction Type",
            options: {
                setCellHeaderProps: () => ({ align: "center" }),
                setCellProps: () => ({ align: "center" }),
            },
        },
        // {
        //     name: "method",
        //     label: "Payment Method",
        //     options: {
        //         setCellHeaderProps: () => {
        //             return { align: "center" };
        //         },
        //         setCellProps: () => {
        //             return { align: "center" };
        //         },
        //     },
        // },
        {
            name: "asset",
            label: "Asset",
            options: {
                setCellHeaderProps: () => ({ align: "center" }),
                setCellProps: () => ({ align: "center" }),
                customBodyRenderLite: (idx) => {
                    if (transactions[idx].type == "TRANSFER" && transactions[idx]?.symbol) {
                        return transactions[idx].symbol;
                    }
                    if (transactions[idx].type != "TRANSFER" && transactions[idx]?.asset?.symbol) {
                        return transactions[idx].asset.symbol;
                    }
                    return "";
                },
            },
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                setCellHeaderProps: () => ({ align: "right" }),
                setCellProps: () => ({ align: "right" }),
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                setCellHeaderProps: () => ({ align: "center" }),
                setCellProps: () => ({ align: "center" }),
                customBodyRender: (value) => {
                    console.log(value);
                    if (value == "COMPLETED") {
                        return <span style={{ color: "#58bd7d" }}>{value}</span>;
                    } else if (value == "PENDING") {
                        return <span style={{ color: "red" }}>{value}</span>;
                    }
                },
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                setCellHeaderProps: () => ({ align: "center" }),
                setCellProps: () => ({ align: "center" }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {transactions[dataIndex].status == "PENDING" &&
                                transactions[dataIndex].asset_id &&
                                transactions[dataIndex].type == "DEPOSIT" && (
                                    <LoadingButton
                                        className="action-btn"
                                        loading={confirmingTx == transactions[dataIndex].id}
                                        fullWidth
                                        loadingPosition="start"
                                        startIcon={
                                            <ArgonTypography sx={{ display: "none" }}>
                                                Hidden Icon
                                            </ArgonTypography>
                                        }
                                        onClick={() =>
                                            onConfirmTransaction(transactions[dataIndex].id)
                                        }
                                    >
                                        Click to Complete transaction
                                    </LoadingButton>
                                )}

                            {transactions[dataIndex].verify_url &&
                                transactions[dataIndex].status == "COMPLETED" && (
                                    <Link
                                        target="_blank"
                                        href={`${transactions[dataIndex].verify_url}`}
                                    >
                                        Verify Blockchain
                                    </Link>
                                )}
                        </>
                    );
                },
            },
        },
    ];

    const option = {
        responsive: "simple",
        selectableRows: "none",
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        search: false,
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none !important",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            paddingBottom: "8px !important",
                            "& > span": {
                                display: "unset !important",
                            },
                            "& > span > button": {
                                padding: "6px 8px !important",
                            },
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            padding: "6px 12px !important",
                        },
                    },
                },
                MUIDataTableFooter: {
                    styleOverrides: {
                        root: {
                            "& > tfoot > tr > td": {
                                borderBottom: 0,
                                padding: "0 16px !important",
                            },
                        },
                    },
                },
            },
        });

    return (
        <DashboardLayout>
            <DashboardNavbar title="Transaction History" />
            {isLoading && <Loader />}

            <ArgonBox>
                <Grid container spacing={2} sx={{ "& label": { fontSize: "14px" } }}>
                    <Grid item xs={12} lg={3}>
                        <ArgonBox>
                            <ArgonBox component="label">Transaction Type</ArgonBox>
                            <Select
                                value={transactionType}
                                onChange={(e) => setTransactionType(e.target.value)}
                                sx={{
                                    "& .MuiSelect-select": {
                                        width: "100% !important",
                                    },
                                    "& .MuiSvgIcon-root": { display: "block" },
                                    height: "40px !important",
                                    paddingLeft: "0 !important",
                                    border: 0,
                                }}
                            >
                                <MenuItem value="-1">All</MenuItem>
                                <MenuItem value="DEPOSIT">Deposit</MenuItem>
                                <MenuItem value="WITHDRAW">Withdraw</MenuItem>
                                <MenuItem value="TRANSFER">Transfer</MenuItem>
                            </Select>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ArgonBox>
                            <ArgonBox component="label">Assets Type</ArgonBox>
                            <Select
                                value={assetType}
                                onChange={(e) => setAssetType(e.target.value)}
                                sx={{
                                    "& .MuiSelect-select": {
                                        width: "100% !important",
                                    },
                                    "& .MuiSvgIcon-root": { display: "block" },
                                    height: "40px !important",
                                    paddingLeft: "0 !important",
                                    border: 0,
                                }}
                            >
                                <MenuItem value="-1">All</MenuItem>
                                {props.coins.map((coin) => (
                                    <MenuItem key={`asset_${coin.id}`} value={coin.id}>
                                        {coin.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ArgonBox>
                            <ArgonBox component="label">Date Rage</ArgonBox>
                            <Select
                                value={dateRange}
                                onChange={(e) => setDateRange(e.target.value)}
                                sx={{
                                    "& .MuiSelect-select": {
                                        width: "100% !important",
                                    },
                                    "& .MuiSvgIcon-root": { display: "block" },
                                    height: "40px !important",
                                    paddingLeft: "0 !important",
                                    border: 0,
                                }}
                            >
                                <MenuItem value="-1">All</MenuItem>
                                <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                                <MenuItem value="Last 15 Days">Last 15 Days</MenuItem>
                                <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                                <MenuItem value="This Month">This Month</MenuItem>
                                <MenuItem value="Last Month">Last Month</MenuItem>
                            </Select>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ArgonBox
                            display="flex"
                            alignItems="flex-end"
                            height="100%"
                            sx={{ "& > div": { width: "100%" } }}
                        >
                            <CsvDownloader
                                filename={
                                    "TransactionsHistory-" + moment().format("YYYYMMDDHHMMss")
                                }
                                datas={transactions}
                                // columns={columns}
                            >
                                <ArgonButton color="primary" fullWidth>
                                    Export History
                                </ArgonButton>
                            </CsvDownloader>
                        </ArgonBox>
                    </Grid>
                </Grid>
                <Card sx={{ mt: 3 }}>
                    <ArgonBox p={3}>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                className="transaction-table"
                                data={transactions}
                                columns={columns}
                                options={option}
                            />
                        </ThemeProvider>
                    </ArgonBox>
                </Card>
            </ArgonBox>

            <Footer />
        </DashboardLayout>
    );
}

// export default Default;
const Wrapper = connect(
    mapStoreToProps(components.TRANSACTION),
    mapDispatchToProps(components.TRANSACTION)
)(Transaction);
export default Wrapper;
