import { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";

import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import { getKYC, saveKYC } from "service/apiCaller";

import SnackbarUtils from "utils/SnackbarUtils";
import defaultImage from "assets/images/default/3.png";
import "./../../styles/style.css";

function KYCSettingLayout() {
    const selfieRef = useRef(null);
    const cardFrontRef = useRef(null);
    const cardBackRef = useRef(null);
    const addressProofRef = useRef(null);
    const [selfie, setSelfie] = useState(null);
    const [cardType, setCardType] = useState("");
    const [cardFront, setCardFront] = useState(null);
    const [cardBack, setCardBack] = useState(null);
    const [addressProof, setAddressProof] = useState(null);
    const [previewSelfie, setPreviewSelfie] = useState(null);
    const [previewCardFront, setPreviewCardFront] = useState(null);
    const [previewCardBack, setPreviewCardBack] = useState(null);
    const [previewAddressProof, setPreviewAddressProof] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const getKycMutation = useMutation(getKYC, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                const { selfie, card_type, card_front, card_back, address_proof } =
                    response.data.data;
                setSelfie(selfie);
                setPreviewSelfie(selfie);
                setCardType(card_type ? card_type : "");
                setCardFront(card_front);
                setPreviewCardFront(card_front);
                setPreviewCardBack(card_back);
                setPreviewAddressProof(address_proof);
            }
        },
    });

    useEffect(() => {
        getKycMutation.mutate();
    }, []);

    const handleImageChange = (event, type) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            switch (type) {
                case "selfie":
                    setSelfie(selectedImage);
                    setPreviewSelfie(URL.createObjectURL(selectedImage));
                    break;
                case "card_front":
                    setCardFront(selectedImage);
                    setPreviewCardFront(URL.createObjectURL(selectedImage));
                    break;
                case "card_back":
                    setCardBack(selectedImage);
                    setPreviewCardBack(URL.createObjectURL(selectedImage));
                    break;
                case "address_proof":
                    setAddressProof(selectedImage);
                    setPreviewAddressProof(URL.createObjectURL(selectedImage));
                    break;
                default:
                    break;
            }
        }
    };

    const handleImageUpload = (type) => {
        switch (type) {
            case "selfie":
                selfieRef.current.click();
                break;
            case "card_front":
                cardFrontRef.current.click();
                break;
            case "card_back":
                cardBackRef.current.click();
                break;
            case "address_proof":
                addressProofRef.current.click();
                break;
            default:
                break;
        }
    };

    const saveKycMutation = useMutation(saveKYC, {
        onSuccess: (response) => {
            if (response?.data?.message) {
                SnackbarUtils.success(response.data.message);
            }
            setIsProcessing(false);
        },
        onError: () => {
            setIsProcessing(false);
        },
    });

    const saveKycInfo = () => {
        if (!cardType && (cardFront || cardBack)) {
            SnackbarUtils.error("Please select the card type");
            return;
        }

        setIsProcessing(true);

        const payload = new FormData();
        if (selfie) {
            payload.append("selfie", selfie);
        }
        if (cardType) {
            payload.append("card_type", cardType);
        }
        if (cardFront) {
            payload.append("card_front", cardFront);
        }
        if (cardBack) {
            payload.append("card_back", cardBack);
        }
        if (addressProof) {
            payload.append("address_proof", addressProof);
        }

        saveKycMutation.mutate(payload);
    };

    return (
        <ArgonBox>
            <ArgonTypography fontSize="15px" fontWeight="medium" lineHeight="22px">
                KYC
            </ArgonTypography>
            <ArgonBox mt={3}>
                <Grid container spacing={3} mb={2}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <ArgonBox>
                            <InputLabel className="form-label">Selfie</InputLabel>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageChange(event, "selfie")}
                                ref={selfieRef}
                                hidden
                            />
                            <ArgonBox
                                component="img"
                                width="100%"
                                // width="300px"
                                // height="300px"
                                className="img"
                                src={previewSelfie ? previewSelfie : defaultImage}
                                onClick={() => handleImageUpload("selfie")}
                            />
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <ArgonBox>
                            <InputLabel className="form-label">Address Proof</InputLabel>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageChange(event, "address_proof")}
                                ref={addressProofRef}
                                hidden
                            />
                            <ArgonBox
                                component="img"
                                width="100%"
                                // width="300px"
                                // height="200px"
                                className="img"
                                src={previewAddressProof ? previewAddressProof : defaultImage}
                                onClick={() => handleImageUpload("address_proof")}
                            />
                        </ArgonBox>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ArgonBox>
                            <InputLabel className="form-label">Select Card Type</InputLabel>
                            <Select
                                value={cardType}
                                onChange={(event) => setCardType(event.target.value)}
                                sx={{
                                    width: "300px !important",
                                    height: "40px !important",
                                    paddingLeft: "0 !important",
                                    "& .MuiSelect-select": {
                                        width: "100% !important",
                                    },
                                    "& .MuiSvgIcon-root": { display: "block" },
                                }}
                            >
                                <MenuItem value={"idcard_op"}>ID Card</MenuItem>
                                <MenuItem value={"passport_op"}>Passport</MenuItem>
                                <MenuItem value={"driver_op"}>Driver License</MenuItem>
                            </Select>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <ArgonBox>
                            <InputLabel className="form-label">Card Front</InputLabel>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageChange(event, "card_front")}
                                ref={cardFrontRef}
                                hidden
                            />
                            <ArgonBox
                                component="img"
                                width="100%"
                                // width="300px"
                                // height="200px"
                                className="img"
                                src={previewCardFront ? previewCardFront : defaultImage}
                                onClick={() => handleImageUpload("card_front")}
                            />
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <ArgonBox>
                            <InputLabel className="form-label">Card Back</InputLabel>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageChange(event, "card_back")}
                                ref={cardBackRef}
                                hidden
                            />
                            <ArgonBox
                                component="img"
                                width="100%"
                                // width="300px"
                                // height="200px"
                                className="img"
                                src={previewCardBack ? previewCardBack : defaultImage}
                                onClick={() => handleImageUpload("card_back")}
                            />
                        </ArgonBox>
                    </Grid>
                </Grid>
            </ArgonBox>
            <ArgonBox mt={2}>
                <LoadingButton
                    variant="contained"
                    sx={{ background: "#5e72e4 !important", color: "white !important" }}
                    loading={isProcessing}
                    loadingPosition="start"
                    startIcon={
                        <ArgonTypography sx={{ display: "none" }}>Hidden Icon</ArgonTypography>
                    }
                    onClick={saveKycInfo}
                >
                    Save
                </LoadingButton>
            </ArgonBox>
        </ArgonBox>
    );
}

export default KYCSettingLayout;
