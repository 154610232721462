function hideLayout(theme) {
  const { breakpoints } = theme;
  return {
    [breakpoints.down("lg")]: {
      display: "none",
    },
  };
}

function errorText(theme) {
  const { functions, colors } = theme;
  const { pxToRem } = functions;
  const { red } = colors;

  return {
    fontSize: pxToRem(14),
    color: red,
  };
}

export { hideLayout, errorText };
