import http from "./http";
const apiEndpoints = {
    auth: {
        check: "/auth/check",
        register: "/auth/register",
        login: "/auth/login",
        refreshToken: "/auth/refresh-token",
        changePassword: "/auth/change-password",
        forgotPassword: "/auth/forgot-password",
        resetPassword: "/auth/reset-password",
        logout: "/auth/logout",
        socialRogin: "/auth/social-login",
        addSponsor: "/auth/add-sponsor"
    },
    verify: {
        resend: "/verify/resend",
        changeEmail: "/verify/change-email",
        check: "/verify/check",
    },
    wallet: {
        getCoinsByNetwork: "/coin/list",
        getNetworks: "/network/list",
        getPaymentOptions: "/paymentoptions/list",
        confirmPayment: "/payment/confirm",
        watchDeposit: "/payment/watch",
        payByCard: "/payment/card/confirm",
        payByBank: "/payment/bank/confirm",
    },
    transaction: {
        getTransactions: "/transactions/list",
        getAllCoins: "/coin/list",
        confirm: "/transactions/confirm",
    },
    withdraw: {
        getWithdraws: "/withdraw/list",
        confirmWithdraw: "/withdraw/confirm",
        confirmRewardsWithdraw: "/withdraw/rewards/confirm",
    },
    user: {
        saveProfile: "/user/profile/save",
        getKYC: "/user/kyc/info",
        saveKYC: "/user/kyc/save",
        getBankInfo: "/user/bank/info",
        saveBankInfo: "/user/bank/save",
        getStatisticsInfo: "user/statistics",
    },
    contact: {
        getTickets: "/contact/list",
        getTicket: "/contact/get",
        createTicket: "/contact/create",
        deleteTicket: "/contact/delete",
        updateTicket: "/contact/update",
    },
    affiliate: {
        getAffiliates: "/affiliate/list",
    },
    subscription: {
        getInvestments: "/subscription/list",
        getPackages: "/subscription/package/list",
        investPackage: "/subscription/package/invest",
        investNode: "/subscription/node/invest",
    },
    setting: {
        getSettings: "/setting/list",
    },
    fundTransfer: {
        list: "/fundtransfer/list",
        transfer: "/fundtransfer/transfer",
    },
};
const {
    auth,
    verify,
    wallet,
    user,
    contact,
    transaction,
    withdraw,
    affiliate,
    subscription,
    setting,
    fundTransfer,
} = apiEndpoints;

export const login = (payload) => http.post(auth.login, payload);
export const logout = (payload) => http.post(auth.logout, payload);
export const refreshToken = (payload) => http.post(auth.refreshToken, payload);
export const registration = (payload) => http.post(auth.register, payload);
export const changePassword = (payload) => http.put(auth.changePassword, payload);
export const forgotPassword = (payload) => http.post(auth.forgotPassword, payload);
export const resetPassword = (payload) => http.put(auth.resetPassword, payload);
export const authCheck = (payload) => http.post(auth.check, payload);
export const socialRogin = (payload) => http.post(auth.socialRogin, payload);
export const addSponsor = (payload) => http.post(auth.addSponsor, payload);


export const resendCode = () => http.post(verify.resend);
export const verifyCode = (payload) => http.post(verify.check, payload);
export const changeEmail = (payload) => http.post(verify.changeEmail, payload);

export const getCoinsByNetwork = ({ queryKey }) => {
    const [_, networkId] = queryKey;
    return http.get(wallet.getCoinsByNetwork + "/" + networkId);
};
export const getNetworks = () => http.get(wallet.getNetworks);
export const getPaymentOptions = () => http.get(wallet.getPaymentOptions);
export const confirmPayment = (payload) => http.post(wallet.confirmPayment, payload);
export const watchDeposit = (payload) => http.post(wallet.watchDeposit, payload);
export const payByCard = (payload) => http.post(wallet.payByCard, payload);
export const payByBank = (payload) => http.post(wallet.payByBank, payload);

export const getAllCoins = (payload) => http.get(transaction.getAllCoins, payload);
export const getTransactions = (payload) => http.post(transaction.getTransactions, payload);
export const confirmTransaction = (payload) => http.post(transaction.confirm, payload);

export const getWithdraws = (payload) => http.post(withdraw.getWithdraws, payload);
export const confirmWithdraw = (payload) => http.post(withdraw.confirmWithdraw, payload);
export const confirmRewardsWithdraw = (payload) =>
    http.post(withdraw.confirmRewardsWithdraw, payload);

export const saveProfile = (payload) => http.put(user.saveProfile, payload);
export const getKYC = () => http.get(user.getKYC);
export const saveKYC = (payload) => http.put(user.saveKYC, payload);
export const getBankInfo = () => http.get(user.getBankInfo);
export const saveBankInfo = (payload) => http.put(user.saveBankInfo, payload);
export const getStatisticsInfo = () => http.get(user.getStatisticsInfo);

export const getTickets = () => http.get(contact.getTickets);
export const getTicket = (payload) => http.post(contact.getTicket, payload);
export const createTicket = (payload) => http.post(contact.createTicket, payload);
export const updateTicket = (payload) => http.put(contact.updateTicket, payload);
export const deleteTicket = (payload) => http.post(contact.deleteTicket, payload);

export const getAffiliates = (payload) => http.post(affiliate.getAffiliates, payload);

export const getInvestments = (payload) => http.post(subscription.getInvestments, payload);
export const getPackages = () => http.get(subscription.getPackages);
export const investPackage = (payload) => http.post(subscription.investPackage, payload);

export const getSettings = () => http.get(setting.getSettings);
export const getFundTransferList = (payload) => http.post(fundTransfer.list, payload);
export const transferFund = (payload) => http.post(fundTransfer.transfer, payload);
