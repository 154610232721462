import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import { fromWei } from "../../utils/convert";
import useRouter from "../../hooks/useRouter";
import ExchangeIcon from "assets/images/icons/exchange.png";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { getAllCoins } from "service/apiCaller";
import { useConfirm } from "material-ui-confirm";
import SnackbarUtils from "utils/SnackbarUtils";
import { getNetworks } from "service/apiCaller";
import { ENVIRONMENT } from "../../constant";
import ArgonAlert from "components/ArgonAlert";
const Web3 = require("web3");
function CoinSelect({ value, onChange, values }) {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChangeValue = (event) => {
        const value = event.target.value;
        // const coin = values.find(c=>c.symbol == value)
        setSelectedValue(value);
        onChange(value);
    };
    useEffect(() => {
        setSelectedValue(value);
    }, [value]);
    return (
        <Select
            value={selectedValue}
            onChange={handleChangeValue}
            sx={{
                "& > .MuiSelect-select": {
                    display: "flex !important",
                    "& > img": { width: 32, height: 32 },
                    "& > span": { fontSize: "25px", lineHeight: "38px" },
                },
                border: 0,
                padding: 0,
            }}
        >
            {values.map((coin, index) => (
                <MenuItem key={index} value={coin.symbol} sx={{ py: 1.2 }}>
                    <ArgonBox
                        component="img"
                        src={coin?.image_url}
                        mr={2}
                        sx={{ width: 20, height: 20 }}
                    />
                    <ArgonTypography as="span" sx={{ fontSize: "18px", lineHeight: "22px" }}>
                        {coin?.symbol}
                    </ArgonTypography>
                </MenuItem>
            ))}
        </Select>
    );
}

function Exchange() {
    const [networks, setNetworks] = useState([]);
    const [networkId, setNetworkId] = useState(1);
    const [coins, setCoins] = useState([]);
    const [fromCoin, setFromCoin] = useState(null);
    const [toCoin, setToCoin] = useState(null);
    const [fromCoinValue, setFromCoinValue] = useState(0);
    const [toCoinValue, setToCoinValue] = useState(0);
    const [calculated, setCalculated] = useState(false);
    const [coinsByNetwork, setCoinsByNetwork] = useState([]);
    const { swapTokenToToken, getAmountOut, swapBNBToToken } = useRouter();
    const [account, setAccount] = useState(null);
    const confirm = useConfirm();
    const coinMutation = useMutation(getAllCoins, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                if (response.data.data.length > 0) {
                    const coinList = response.data.data;
                    setCoins(coinList);
                    setCoinsByNetwork(coinList.filter((coin) => coin.network_id === networkId));
                    setFromCoin(response.data.data[0].symbol);
                    setToCoin(response.data.data[0].symbol);
                }
            }
        },
    });
    const networkMutation = useMutation(getNetworks, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setNetworks(response.data.data);
            }
        },
    });

    useEffect(() => {
        networkMutation.mutate();
        coinMutation.mutate();
    }, []);

    useEffect(() => {
        if (fromCoin && toCoin && fromCoinValue) {
            handleCalculateOutput();
            setCalculated(true);
        } else {
            setToCoinValue(null);
            setCalculated(false);
        }
    }, [fromCoin, fromCoinValue, toCoin]);

    const handleChangeNetwork = (e) => {
        const newNetworkId = e.target.value;
        setNetworkId(newNetworkId);
        const coinsFilter = coins.filter((coin) => coin.network_id === newNetworkId);
        handleChangeFromCoin(coinsFilter[0].symbol);
        handleChangeToCoin(coinsFilter[0].symbol);
        setCoinsByNetwork(coinsFilter);
    };
    const handleChangeFromCoin = (coin) => {
        setFromCoin(coin);
    };
    const handleChangeToCoin = (coin) => {
        setToCoin(coin);
    };
    const handleSwapCoin = async () => {
        const fromCoinItem = toCoin;
        const toCoinItem = fromCoin;
        setFromCoin(fromCoinItem);
        setToCoin(toCoinItem);
    };
    const handleCalculateOutput = async () => {
        if (fromCoinValue <= 0) {
            return;
        }
        const fromAddress = coins.find((c) => c.symbol == fromCoin).address;
        const toAddress = coins.find((c) => c.symbol == toCoin).address;
        const a = await getAmountOut(fromCoinValue.toString(), [
            fromAddress,
            "0x829c09fCc46D9fd31967272ABA245BEF9f727F93",
        ]);
        console.log(a);
        // setOutput(fromWei(a[1]));
        if (a && a.length > 0) setToCoinValue(parseFloat(fromWei(a[1])));
    };
    const handleSwapEvent = () => {
        const fromAddress = coins.find((c) => c.symbol == fromCoin).address;
        const toAddress = coins.find((c) => c.symbol == toCoin).address;
        const swap = async () => {};
        confirm({
            description: `You will swap your ${fromCoinValue} ${fromCoin} with ${toCoinValue} ${toCoin},confirm transaction?`,
        })
            .then(async () => {
                if (!account || account == "") {
                    SnackbarUtils.warning("Your wallet is locked. Please open your wallet first.");
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    const web3 = new Web3(window.ethereum);
                    const account = await web3.eth.getAccounts();
                    console.log("cccc", account);
                    setAccount(account[0]);
                } else {
                    if (fromCoin === "BNB") {
                        return swapBNBToToken(
                            fromCoinValue.toString(),
                            (toCoinValue * 0.98).toString(),
                            [fromAddress, toAddress],
                            account
                        );
                    } else
                        return swapTokenToToken(
                            fromCoinValue.toString(),
                            (toCoinValue * 0.98).toString(),
                            [fromAddress, toAddress],
                            account
                        );
                }
            })
            .catch((e) => {
                SnackbarUtils.error(e);
            });
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />

            {ENVIRONMENT == "development" && (
                <ArgonAlert color="error" sx={{ justifyContent: "center" }}>
                    Swap Tokens using our FXTOKENSWAP
                </ArgonAlert>
            )}
            <ArgonBox display="flex" flexDirection="column" alignItems="center" mt={5}>
                <ArgonBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mb={5}
                    width={300}
                >
                    <InputLabel>Select Network</InputLabel>
                    <Select
                        value={networkId}
                        onChange={handleChangeNetwork}
                        sx={{
                            "& .MuiSelect-select": {
                                width: "100% !important",
                            },
                            "& .MuiSvgIcon-root": { display: "block" },
                            height: "48px !important",
                            paddingLeft: "0 !important",
                            backgroundColor: "#CFD4F1 !important",
                            color: "#344767",
                        }}
                    >
                        {networks.map((network, index) => (
                            <MenuItem key={index} value={network.id}>
                                {network.name}
                            </MenuItem>
                        ))}
                    </Select>
                </ArgonBox>
                <ArgonBox
                    p={3}
                    boxShadow="0px 4px 52px rgba(0, 0, 0, 0.25)"
                    bgColor="#CFD4F1"
                    borderRadius="8px"
                    width="650px"
                    maxWidth="100%"
                >
                    <ArgonBox
                        textAlign="center"
                        sx={({ breakpoints }) => ({
                            "& > button": {
                                width: "180px",
                                [breakpoints.down("sm")]: {
                                    maxWidth: "100px",
                                },
                            },
                        })}
                    >
                        <ArgonButton size="large" color="primary" variant="contained">
                            Convert
                        </ArgonButton>
                        <ArgonButton
                            size="large"
                            color="primary"
                            variant="outlined"
                            sx={{ ml: 3, background: "white" }}
                        >
                            Trade
                        </ArgonButton>
                    </ArgonBox>
                    <ArgonBox mt={5} display="flex" flexDirection="column" alignItems="center">
                        <ArgonBox p={3} bgColor="white" borderRadius="8px" width="100%">
                            <ArgonTypography
                                sx={{ fontSize: "20px", lineHeight: "22px", color: "#707A8A" }}
                            >
                                From
                            </ArgonTypography>
                            <ArgonBox mt={3} display="flex" alignItems="center">
                                <ArgonInput
                                    sx={{
                                        background: "#F6F8FE !important",
                                        borderRadius: "8px",
                                        border: 0,
                                        "& > input": {
                                            color: "#B7BDC6 !important",
                                        },
                                        flex: 1,
                                    }}
                                    value={fromCoinValue}
                                    onChange={(e) => setFromCoinValue(e.target.value)}
                                />
                                <ArgonBox
                                    sx={({ breakpoints }) => ({
                                        ml: 8,
                                        [breakpoints.down("sm")]: { ml: 3 },
                                    })}
                                >
                                    <CoinSelect
                                        value={fromCoin}
                                        onChange={handleChangeFromCoin}
                                        values={coinsByNetwork}
                                    />
                                </ArgonBox>
                            </ArgonBox>
                        </ArgonBox>
                        <ArgonButton
                            onClick={handleSwapCoin}
                            sx={{
                                borderRadius: "50%",
                                background: "#F5F5F5",
                                height: "64px",
                                width: "64px",
                                mt: 5,
                                mb: 5,
                            }}
                        >
                            <ArgonBox component="img" src={ExchangeIcon} />
                        </ArgonButton>
                        <ArgonBox p={3} bgColor="white" borderRadius="8px" width="100%">
                            <ArgonTypography
                                sx={{ fontSize: "20px", lineHeight: "22px", color: "#707A8A" }}
                            >
                                To
                            </ArgonTypography>
                            <ArgonBox mt={3} display="flex" alignItems="center">
                                <ArgonInput
                                    sx={{
                                        background: "#F6F8FE !important",
                                        borderRadius: "8px",
                                        border: 0,
                                        "& > input": {
                                            color: "#B7BDC6 !important",
                                        },
                                        flex: 1,
                                    }}
                                    value={toCoinValue}
                                    onChange={(e) => setToCoinValue(e.target.value)}
                                />
                                <ArgonBox
                                    sx={({ breakpoints }) => ({
                                        ml: 8,
                                        [breakpoints.down("sm")]: { ml: 3 },
                                    })}
                                >
                                    <CoinSelect
                                        value={toCoin}
                                        onChange={handleChangeToCoin}
                                        values={coinsByNetwork}
                                    />
                                </ArgonBox>
                            </ArgonBox>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
                <ArgonButton
                    sx={{
                        background: "rgba(84, 107, 234, 0.2)",
                        color: "#3C56E7",
                        fontSize: "24px",
                        fontWeight: "600",
                        lineHeight: "40px",
                        mt: 5,
                        px: 5,
                        py: 1,
                    }}
                    onClick={handleSwapEvent}
                    disabled={fromCoin && toCoin && fromCoinValue && toCoinValue ? false : true}
                >
                    Swap
                </ArgonButton>
            </ArgonBox>

            <Footer />
        </DashboardLayout>
    );
}

export default Exchange;
