/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import EmailVerification from "layouts/authentication/email-verify";
import Dashboard from "layouts/dashboard";
import Wallet from "layouts/wallet";
import Transaction from "layouts/transaction";
import MySubscription from "layouts/subscription/SubscriptionPlan";
// import NodeSubscription from "layouts/subscription/NodeSubscription";
// import BitJobberSubscription from "layouts/subscription/BitJobberSubscription";
import Profile from "layouts/profile";

import ArgonBox from "components/ArgonBox";

// Icons
import dashboardIcon from "assets/images/icons/menu/monitoring.svg";
import profileIcon from "assets/images/icons/menu/profile.svg";
import walletIcon from "assets/images/icons/menu/wallet.svg";
import exchangeIcon from "assets/images/icons/menu/money-exchange.svg";
import historyIcon from "assets/images/icons/menu/transaction-history.svg";
import transferIcon from "assets/images/icons/menu/transfer.svg";
import subscriptionPlanIcon from "assets/images/icons/menu/subscription.svg";
import subscriptionIcon from "assets/images/icons/menu/my-subscription.svg";
import withdrawalIcon from "assets/images/icons/menu/withdrawal.svg";
import affiliateIcon from "assets/images/icons/menu/affiliate.svg";
import contactIcon from "assets/images/icons/menu/support.svg";
import logoutIcon from "assets/images/icons/menu/logout.svg";
import ContactSupport from "layouts/contact-support";
// import MySubscription from "layouts/subscription";
import FundTansfer from "layouts/fundtransfer";
import Affiliates from "layouts/affiliates";
import Exchange from "layouts/exchange";
import { ConfirmProvider } from "material-ui-confirm";
import Withdrawal from "layouts/withrawal";
import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password";

const routes = [
    {
        type: "route",
        name: "Sign In",
        key: "sign-in",
        route: "/login",
        component: <SignIn />,
    },
    {
        type: "route",
        name: "Sign Up",
        key: "sign-up",
        route: "/register",
        component: <SignUp />,
    },
    {
        type: "route",
        name: "Sign Up",
        key: "sign-up",
        route: "/register/:referral",
        component: <SignUp />,
    },
    {
        type: "route",
        name: "Verify Email",
        key: "email-verification",
        route: "/email-verify",
        component: <EmailVerification />,
    },
    {
        type: "route",
        name: "Forgot Password",
        key: "forgot-password",
        route: "/forgot-password",
        component: <ForgotPassword />,
    },
    {
        type: "route",
        name: "Reset Password",
        key: "reset-password",
        route: "/reset-password",
        component: <ResetPassword />,
    },
    {
        isMenu: true,
        type: "route",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        private: true,
        icon: <ArgonBox component="img" width="24px" src={dashboardIcon} />,
        component: <Dashboard />,
    },
    {
        isMenu: true,
        type: "route",
        name: "My Profile",
        key: "profile",
        route: "/profile",
        private: true,
        icon: <ArgonBox component="img" width="24px" src={profileIcon} />,
        component: <Profile />,
    },
    {
        isMenu: true,
        type: "route",
        name: "Wallet Deposit",
        key: "wallet",
        route: "/wallet",
        private: true,
        icon: <ArgonBox component="img" width="24px" src={walletIcon} />,
        component: <Wallet />,
    },
    // {
    //     isMenu: true,
    //     type: "route",
    //     name: "Exchange",
    //     key: "exchange",
    //     route: "/exchange",
    //     private: true,
    //     icon: <ArgonBox component="img" width="24px" src={exchangeIcon} />,
    //     component: (
    //         <ConfirmProvider>
    //             <Exchange />
    //         </ConfirmProvider>
    //     ),
    // },
    {
        isMenu: true,
        type: "route",
        name: "Transaction History",
        key: "transaction",
        route: "/transaction",
        icon: <ArgonBox component="img" width="24px" src={historyIcon} />,
        component: <Transaction />,
    },
    {
        isMenu: true,
        type: "route",
        name: "Transfer Fund",
        key: "transfer-fund",
        route: "/transfer-fund",
        icon: <ArgonBox component="img" width="24px" src={transferIcon} />,
        component: <FundTansfer />,
    },
    // {
    //     isMenu: true,
    //     type: "route",
    //     name: "Subscription Plan",
    //     key: "subscription-plan",
    //     route: "/subscription-plan",
    //     icon: <ArgonBox component="img" width="24px" src={subscriptionPlanIcon} />,
    //     component: <SubscriptionPlan />,
    // },
    {
        isMenu: true,
        type: "route",
        name: "My Subscription",
        key: "subscription",
        route: "/subscription",
        icon: <ArgonBox component="img" width="24px" src={subscriptionIcon} />,
        component: <MySubscription />,
    },
    {
        isMenu: true,
        type: "route",
        name: "Withdrawal",
        key: "withdrawal",
        route: "/withdrawal",
        icon: <ArgonBox component="img" width="24px" src={withdrawalIcon} />,
        component: <Withdrawal />,
    },
    {
        isMenu: true,
        type: "route",
        name: "Affiliates",
        key: "affiliates",
        route: "/affiliates",
        icon: <ArgonBox component="img" width="24px" src={affiliateIcon} />,
        component: <Affiliates />,
    },
    {
        isMenu: true,
        type: "route",
        name: "Contact Support",
        key: "contact-support",
        route: "/contact-support",
        icon: <ArgonBox component="img" width="24px" src={contactIcon} />,
        component: <ContactSupport />,
    },
    {
        isMenu: true,
        type: "action",
        name: "Logout",
        key: "logout",
        route: "/logout",
        icon: <ArgonBox component="img" width="24px" src={logoutIcon} />,
    },
];

export default routes;
