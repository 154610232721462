import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import BigInviteCard from "components/Cards/InviteCard/BigInviteCard";
import { getAffiliates } from "service/apiCaller";
import moment from "moment";

function Affiliates() {
    const [data, setData] = useState([]);

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
            },
        },
        {
            name: "username",
            label: "Username",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
            },
        },
        {
            name: "sponsor",
            label: "Sponsor By",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "left" };
                },
                setCellProps: () => {
                    return { align: "left" };
                },
            },
        },
        {
            name: "user_level",
            label: "Level",
            options: {
                filter: true,
                filterOptions: { renderValue: (v) => (v == 0 ? "Direct Referral" : v) },
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => {
                    return { align: "right" };
                },
            },
        },
        {
            name: "percent",
            label: "Incentives",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => {
                    return { align: "right" };
                },
                customBodyRender: (value) => (value ? value + "%" : ""),
            },
        },
        {
            name: "earning",
            label: "Amount Earned",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => {
                    return { align: "right" };
                },
            },
        },
        {
            name: "subscription",
            label: "Fixed Term",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => {
                    return { align: "right" };
                },
            },
        },
        {
            name: "createdAt",
            label: "Date Registered",
            options: {
                filter: false,
                setCellHeaderProps: () => {
                    return { align: "center" };
                },
                setCellProps: () => {
                    return { align: "center" };
                },
                customBodyRender: (value) => {
                    return moment(value).format("DD-MM-YYYY");
                },
            },
        },
    ];

    const option = {
        responsive: "simple",
        selectableRows: "none",
        print: false,
        download: false,
        viewColumns: false,
        search: true,
        filter: true,
        pageSize: 10,
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none !important",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            paddingBottom: "8px !important",
                            "& > span": {
                                display: "unset !important",
                            },
                            "& > span > button": {
                                padding: "6px 8px !important",
                            },
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            padding: "5px 12px !important",
                        },
                    },
                },
                MUIDataTableFooter: {
                    styleOverrides: {
                        root: {
                            "& > tfoot > tr > td": {
                                borderBottom: 0,
                                padding: "0 16px !important",
                            },
                        },
                    },
                },
            },
        });

    useEffect(() => {
        updateTable();
    }, []);

    const mutation = useMutation(getAffiliates, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                setData(response?.data?.data);
            }
        },
    });

    const updateTable = () => {
        mutation.mutate();
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <ArgonBox>
                <BigInviteCard />
                <ArgonBox mt={3}>
                    <Card>
                        <ArgonBox px={4} py={5}>
                            <ArgonTypography
                                sx={{
                                    fontSize: "24px",
                                    lineHeight: "32px",
                                    fontWeight: "bold",
                                    color: "#000",
                                }}
                            >
                                Affiliate
                            </ArgonTypography>
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable data={data} columns={columns} options={option} />
                            </ThemeProvider>
                        </ArgonBox>
                    </Card>
                </ArgonBox>
            </ArgonBox>

            <Footer />
        </DashboardLayout>
    );
}

export default Affiliates;
