import * as React from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/PermIdentityOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToAppOutlined";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar } from "@mui/material";

import ArgonBox from "components/ArgonBox";

import { useAuthContext } from "context/auth";
import { logout } from "service/apiCaller";

export default function CustomizedMenus() {
    const { user } = useAuthContext();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const mutation = useMutation(logout, {
        onSuccess: (response) => {
            localStorage.clear();
            window.location.replace("/login");
        },
    });

    const handleLogout = () => {
        const payload = {
            access_token: user.token.token,
            refresh_token: user.refreshToken.token,
        };
        mutation.mutate(payload);
    };

    return (
        <ArgonBox ml={1}>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                sx={{
                    background: "white",
                    border: "1px solid #666666",
                    borderRadius: "12px",
                    padding: "3px 15px 3px 3px",
                    "&:hover": {
                        transform: "none",
                        background: "white",
                    },
                    "&:focus": {
                        boxShadow: "none",
                    },
                    "&:focus:not(:hover)": {
                        background: "white",
                        boxShadow: "none",
                    },
                }}
            >
                {user.avatar ? (
                    <Avatar sx={{ width: 30, height: 30 }} src={user.avatar} variant="rounded" />
                ) : (
                    <Avatar sx={{ width: 30, height: 30 }} variant="rounded" />
                )}
            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Link to="/profile">
                    <MenuItem onClick={handleClose} disableRipple>
                        <PersonIcon sx={{ mr: 1 }} />
                        Profile
                    </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleLogout} disableRipple>
                    <ExitToAppIcon sx={{ mr: 1 }} />
                    Logout
                </MenuItem>
            </Menu>
        </ArgonBox>
    );
}
