import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import SidenavItem from "components/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";

import { useArgonController, setMiniSidenav } from "context";

import { logout } from "service/apiCaller";
import { useAuthContext } from "context/auth";
import { useMutation } from "react-query";

function Sidenav({ color, brand, routes, ...rest }) {
    const { user } = useAuthContext();
    const [controller, dispatch] = useArgonController();
    const { miniSidenav, layout } = controller;
    const location = useLocation();
    const { pathname } = location;
    const itemName = pathname.split("/").slice(1)[0];

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    const mutation = useMutation(logout, {
        onSuccess: (response) => {
            localStorage.clear();
            window.location.replace("/login");
        },
    });

    const handleLogout = () => {
        const payload = {
            access_token: user.token.token,
            refresh_token: user.refreshToken.token,
        };
        mutation.mutate(payload);
    };

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ isMenu, type, name, icon, title, key, href, route }) => {
        let returnValue;

        if (isMenu) {
            if (type === "route") {
                if (href) {
                    returnValue = (
                        <Link href={href} key={key} target="_blank" rel="noreferrer">
                            <SidenavItem
                                name={name}
                                icon={icon}
                                active={key === itemName}
                                noCollapse={noCollapse}
                            />
                        </Link>
                    );
                } else {
                    returnValue = (
                        <NavLink to={route} key={key}>
                            <SidenavItem name={name} icon={icon} active={key === itemName} />
                        </NavLink>
                    );
                }
            } else if (type === "title") {
                returnValue = (
                    <ArgonTypography
                        key={key}
                        color={"dark"}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                        {title}
                    </ArgonTypography>
                );
            } else if (type === "divider") {
                returnValue = <Divider key={key} />;
            } else if (type === "action") {
                if (key === "logout") {
                    returnValue = (
                        <ArgonBox onClick={handleLogout} key={key}>
                            <SidenavItem name={name} icon={icon} active={key === itemName} />
                        </ArgonBox>
                    );
                }
            }
        }

        return returnValue;
    });

    return (
        <SidenavRoot {...rest} variant="permanent" ownerState={{ miniSidenav, layout }}>
            <ArgonBox
                display={{ xs: "block", xl: "none" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={closeSidenav}
                sx={{ cursor: "pointer" }}
            >
                <ArgonTypography variant="h6" color="secondary">
                    <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                </ArgonTypography>
            </ArgonBox>
            <ArgonBox component={NavLink} to="/">
                <ArgonBox display="flex" alignItems="center" justifyContent="center" height="80px">
                    {brand && (
                        <ArgonBox
                            component="img"
                            src={brand}
                            alt="USDFX Logo"
                            width="151px"
                            height="45px"
                        />
                    )}
                </ArgonBox>
            </ArgonBox>
            <ArgonBox py="20px" px="18px">
                <List>{renderRoutes}</List>
            </ArgonBox>
        </SidenavRoot>
    );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
