import { useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";


import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import { changePassword } from "service/apiCaller";

import SnackbarUtils from "utils/SnackbarUtils";
import "./../../styles/style.css";

function SecuritySettingLayout() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const mutation = useMutation(changePassword, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response.data.message);
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
            }
        },
    });

    const handleChangePassword = () => {
        const payload = {
            old_password: oldPassword,
            password: newPassword,
            confirm_password: confirmPassword,
        };
        mutation.mutate(payload);
    };

    return (
        <ArgonBox>
            <ArgonTypography fontSize="15px" fontWeight="medium" lineHeight="22px">
                Change Password
            </ArgonTypography>
            <ArgonBox width="300px">
                <ArgonBox mt={3}>
                    <InputLabel className="form-label">Old Password</InputLabel>
                    <input
                        type="password"
                        className="form-control"
                        value={oldPassword}
                        onChange={(event) => setOldPassword(event.target.value)}
                    />
                </ArgonBox>
                <ArgonBox mt={3}>
                    <InputLabel className="form-label">New Password</InputLabel>
                    <input
                        type="password"
                        className="form-control"
                        value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                    />
                </ArgonBox>
                <ArgonBox mt={3}>
                    <InputLabel className="form-label">Confirm Password</InputLabel>
                    <input
                        type="password"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                </ArgonBox>
                <ArgonBox mt={3}>
                    <ArgonButton color="primary" onClick={handleChangePassword}>
                        Change
                    </ArgonButton>
                </ArgonBox>
            </ArgonBox>
        </ArgonBox>
    );
}

export default SecuritySettingLayout;
