import { useEffect, useState } from "react";
import axios from "axios";

import {
    ErrorOutline,
    KeyboardArrowDown,
    ScheduleOutlined,
    ArrowBack,
    SearchOutlined,
    ArrowForwardIosOutlined,
} from "@mui/icons-material";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    List,
    ListItem,
    OutlinedInput,
} from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";

import CurrencyFlag from "react-currency-flags";

// icons
import BANK_ICON from "assets/images/icons/payment/bank.png";
import CARD_ICON from "assets/images/icons/payment/card.png";
import ARROW_ICON from "assets/images/icons/payment/arrow.png";
import PLUS_ICON from "assets/images/icons/payment/Plus.png";
import MINUS_ICON from "assets/images/icons/payment/Minus.png";

import cardIcon1 from "assets/images/icons/credit-card/ic_card_1.png";
import cardIcon2 from "assets/images/icons/credit-card/ic_card_2.png";
import cvvIcon from "assets/images/icons/credit-card/ic_cvv.png";
import calendarIcon from "assets/images/icons/credit-card/ic_calendar.png";

import SnackbarUtils from "utils/SnackbarUtils";
import { useMutation } from "react-query";
import { payByCard } from "service/apiCaller";

function DepositBankSide({ open, onClose, payment, network, asset }) {
    const [payAmount, setPayAmount] = useState("");
    const [currency, setCurrency] = useState("USD");
    const [receiveAmount, setReceiveAmount] = useState("");
    const [exchangeRate, setExchangeRate] = useState(0);

    const [cardInfo, setCardInfo] = useState({
        firstName: "",
        lastName: "",
        cardNumber: "",
        expDate: "",
        cvv: "",
    });

    const [paymentMethod, setPaymentMethod] = useState(payment);
    const [fiatCurrencies, setFiatCurrencies] = useState([]);
    const [step, setStep] = useState("main");
    const [searchCurrency, setSearchCurrency] = useState("");
    const [showPayConfirm, setShowPayConfirm] = useState(false);

    useEffect(() => {
        getFiatCurrencies();
    }, []);

    useEffect(() => {
        handleSetExchangeRate();
    }, [currency]);

    const handleSetExchangeRate = async () => {
        if (asset.symbol == "USDFX") {
            setExchangeRate(0.85);
        } else {
            const ret = await axios.get(
                `https://api.exchangerate.host/convert?from=USDT&to=${currency}&amount=1`
            );
            setExchangeRate(ret.data.info.rate);
        }
    };

    useEffect(() => {
        handleCalculateAmount();
    }, [payAmount, exchangeRate]);

    const handleCalculateAmount = () => {
        const amount = exchangeRate ? payAmount / exchangeRate : "";
        setReceiveAmount(amount);
    };

    const getFiatCurrencies = async () => {
        const ret = await axios(`https://api.exchangerate.host/symbols`);
        setFiatCurrencies(ret.data.symbols);
    };

    const handlePrevious = () => {
        setStep("main");
        switch (step) {
            case "currency-list":
                setSearchCurrency("");
                break;
            default:
                break;
        }
    };

    const handleCurrencySelect = async (fiatCurrency) => {
        setCurrency(fiatCurrency);
        handleCalculateAmount;
        handlePrevious();
    };

    const handleBuyNow = () => {
        if (payAmount == "" || receiveAmount == "") {
            SnackbarUtils.error("Please type correct amount.");
            return;
        }
        if (paymentMethod == "BANK") {
            setStep("bank-info");
        } else {
            setStep("card-info");
        }
    };

    const handleCardNumberChange = (event) => {
        let value = event.target.value;
        // Remove non-digit characters from the input
        value = value.replace(/\D/g, "");
        // Apply a space after every fourth digit
        value = value.replace(/(\d{4})/g, "$1 ").trim();
        // Limit the length to maxLength
        value = value.slice(0, 19);
        setCardInfo({ ...cardInfo, cardNumber: value });
    };

    const handleExpirationDateChange = (event) => {
        let value = event.target.value;
        // Remove non-digit characters from the input
        value = value.replace(/\D/g, "");
        // Add a slash after the second digit
        if (value.length > 2) {
            value = value.slice(0, 2) + "-" + value.slice(2);
        }
        // Limit the length to maxLength
        value = value.slice(0, 7);
        setCardInfo({ ...cardInfo, expDate: value });
    };

    const handleCVVChange = (event) => {
        let value = event.target.value;
        // Remove non-digit characters from the input
        value = value.replace(/\D/g, "");
        // Limit the length to maxLength
        value = value.slice(0, 3);
        setCardInfo({ ...cardInfo, cvv: value });
    };

    const handlePayConfirm = () => {
        if (cardInfo.firstName == "") {
            SnackbarUtils.error("Please type the first name of card holder.");
            return;
        }
        if (cardInfo.lastName == "") {
            SnackbarUtils.error("Please type the last name of card holder.");
            return;
        }
        if (cardInfo.cardNumber == "") {
            SnackbarUtils.error("Please type the card number.");
            return;
        }
        if (cardInfo.expDate == "") {
            SnackbarUtils.error("Please type the expiration date.");
            return;
        }
        if (cardInfo.cvv == "") {
            SnackbarUtils.error("Please type the CVV.");
            return;
        }
        setShowPayConfirm(true);
    };

    const handlePayConfirmClose = () => {
        setShowPayConfirm(false);
        setCardInfo({
            firstName: "",
            lastName: "",
            cardNumber: "",
            expDate: "",
            cvv: "",
        });
    };

    const payCardMutation = useMutation(payByCard, {
        onSuccess: (response) => {
            
        }
    });

    const handlePay = () => {};

    return (
        <Dialog className="dialog" open={open} onClose={onClose}>
            {step == "main" && (
                <>
                    <DialogTitle className="dialog-title">Buy crypto to your wallet</DialogTitle>
                    <DialogContent className="dialog-content">
                        <ArgonBox className="currency-box">
                            <ArgonBox className="input-box">
                                <ArgonBox className="label" component="label">
                                    You pay
                                </ArgonBox>
                                <ArgonInput
                                    className="input"
                                    value={payAmount}
                                    onChange={(e) => setPayAmount(e.target.value.replace(/\D/, ""))}
                                />
                            </ArgonBox>
                            <ArgonBox className="select-box">
                                <ArgonBox
                                    className="select"
                                    onClick={() => setStep("currency-list")}
                                >
                                    <CurrencyFlag currency={currency} width={20} />
                                    {currency}
                                    <KeyboardArrowDown
                                        sx={{
                                            fontSize: "20px !important",
                                            ml: 0.5,
                                            color: "#3772FF",
                                        }}
                                    />
                                </ArgonBox>
                            </ArgonBox>
                        </ArgonBox>
                        <ArgonBox className="payment-detail">
                            <ArgonBox className="payment-method">
                                <ArgonTypography className="desc">
                                    Using payment method
                                </ArgonTypography>
                                <ArgonBox className="payment-select">
                                    <ArgonBox
                                        className={paymentMethod == "BANK" ? "item active" : "item"}
                                        onClick={() => setPaymentMethod("BANK")}
                                    >
                                        <ArgonBox className="radio" />
                                        <ArgonBox component="img" src={BANK_ICON} />
                                    </ArgonBox>
                                    <ArgonBox
                                        className={paymentMethod == "CARD" ? "item active" : "item"}
                                        onClick={() => setPaymentMethod("CARD")}
                                    >
                                        <ArgonBox className="radio" />
                                        <ArgonBox component="img" src={CARD_ICON} />
                                    </ArgonBox>
                                </ArgonBox>
                            </ArgonBox>
                            <ArgonBox className="detail-info" mt={5}>
                                <ArgonBox className="icon" component="img" src={ARROW_ICON} />
                                <ArgonTypography className="desc">
                                    Transaction Details
                                </ArgonTypography>
                            </ArgonBox>
                            <ArgonBox className="detail-info" mt={4}>
                                <ArgonBox className="icon" component="img" src={MINUS_ICON} />
                                <ArgonTypography className="desc">2.49 USD</ArgonTypography>
                                <ArgonTypography className="property">
                                    Blockchain Fees
                                </ArgonTypography>
                            </ArgonBox>
                            <ArgonBox className="detail-info" mt={4}>
                                <ArgonBox className="icon" component="img" src={PLUS_ICON} />
                                <ArgonTypography className="desc">$0.85 = 1 USDFX</ArgonTypography>
                                <ArgonTypography className="property">
                                    Spot Rate <ErrorOutline sx={{ width: 20, height: 20 }} />
                                </ArgonTypography>
                            </ArgonBox>
                        </ArgonBox>
                        <ArgonBox className="currency-box">
                            <ArgonBox className="input-box">
                                <ArgonBox className="label" component="label">
                                    You receive ( estimate )
                                </ArgonBox>
                                <ArgonInput className="input" value={receiveAmount} readOnly />
                            </ArgonBox>
                        </ArgonBox>
                        <ArgonBox className="helper-info" mt={0.5}>
                            <ScheduleOutlined className="icon" />
                            <ArgonTypography className="text">
                                Average Processing Time: 3 minutes
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox mt={5}>
                            <ArgonButton className="dialog-button" fullWidth onClick={handleBuyNow}>
                                Buy Now
                            </ArgonButton>
                        </ArgonBox>
                    </DialogContent>
                </>
            )}
            {step == "currency-list" && (
                <>
                    <DialogTitle className="dialog-title">
                        <ArrowBack className="icon-back" onClick={handlePrevious} />
                        Select which currency
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <ArgonBox className="currency-list-box">
                            <ArgonBox className="search-box">
                                <SearchOutlined className="icon" />
                                <ArgonInput
                                    className="input"
                                    placeholder="Type a currency/country"
                                    value={searchCurrency}
                                    onChange={(e) => setSearchCurrency(e.target.value)}
                                />
                            </ArgonBox>
                            <ArgonBox className="currency-list">
                                <ArgonTypography className="title">All Currencies</ArgonTypography>
                                <List className="items">
                                    {Object.keys(fiatCurrencies).map(
                                        (fiatCurrency, idx) =>
                                            (fiatCurrencies[fiatCurrency].description
                                                .toLowerCase()
                                                .includes(searchCurrency.toLowerCase()) ||
                                                fiatCurrency
                                                    .toLowerCase()
                                                    .includes(searchCurrency.toLowerCase())) && (
                                                <ListItem
                                                    className="item"
                                                    key={idx}
                                                    onClick={() =>
                                                        handleCurrencySelect(fiatCurrency)
                                                    }
                                                >
                                                    <ArgonBox className="content">
                                                        <CurrencyFlag
                                                            currency={fiatCurrency}
                                                            width={20}
                                                        />
                                                        {fiatCurrency}
                                                        &nbsp;
                                                        {fiatCurrencies[fiatCurrency].description}
                                                    </ArgonBox>
                                                    <ArrowForwardIosOutlined className="icon" />
                                                </ListItem>
                                            )
                                    )}
                                </List>
                            </ArgonBox>
                        </ArgonBox>
                    </DialogContent>
                </>
            )}
            {step == "bank-info" && (
                <>
                    <DialogTitle className="dialog-title">
                        <ArrowBack className="icon-back" onClick={handlePrevious} />
                        Bank
                    </DialogTitle>
                    <DialogContent className="dialog-content">Coming soon</DialogContent>
                </>
            )}
            {step == "card-info" && (
                <>
                    <DialogTitle className="dialog-title">
                        <ArrowBack className="icon-back" onClick={handlePrevious} />
                        Credit/Debit Card
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <ArgonBox className="form-box">
                            <InputLabel className="label">Card Holder Name</InputLabel>
                            <Grid container spacing={1.5}>
                                <Grid item xs={6} lg={6}>
                                    <OutlinedInput
                                        className="input"
                                        name="firstName"
                                        value={cardInfo.firstName}
                                        placeholder="First Name"
                                        onChange={(e) =>
                                            setCardInfo({ ...cardInfo, firstName: e.target.value })
                                        }
                                        startAdornment={
                                            <ArgonBox className="input-icon">
                                                <ArgonBox component="img" src={cardIcon1} />
                                            </ArgonBox>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <OutlinedInput
                                        className="input"
                                        name="lastName"
                                        value={cardInfo.lastName}
                                        placeholder="Last Name"
                                        onChange={(e) =>
                                            setCardInfo({ ...cardInfo, lastName: e.target.value })
                                        }
                                        startAdornment={
                                            <ArgonBox className="input-icon">
                                                <ArgonBox component="img" src={cardIcon1} />
                                            </ArgonBox>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </ArgonBox>
                        <ArgonBox className="form-box" mt={2}>
                            <InputLabel className="label">Card Number</InputLabel>
                            <OutlinedInput
                                className="input"
                                name="cardNumber"
                                value={cardInfo.cardNumber}
                                placeholder="Card Number"
                                onChange={handleCardNumberChange}
                                startAdornment={
                                    <ArgonBox className="input-icon">
                                        <ArgonBox component="img" src={cardIcon2} />
                                    </ArgonBox>
                                }
                            />
                        </ArgonBox>
                        <ArgonBox className="form-box" mt={2}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={6} lg={6}>
                                    <InputLabel className="label">Expiry Date</InputLabel>
                                    <OutlinedInput
                                        className="input"
                                        name="expDate"
                                        value={cardInfo.expDate}
                                        placeholder="MM-YYYY"
                                        onChange={handleExpirationDateChange}
                                        startAdornment={
                                            <ArgonBox className="input-icon">
                                                <ArgonBox component="img" src={calendarIcon} />
                                            </ArgonBox>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <InputLabel className="label">CVV</InputLabel>
                                    <OutlinedInput
                                        className="input"
                                        name="cvv"
                                        value={cardInfo.cvv}
                                        placeholder="CVV"
                                        onChange={handleCVVChange}
                                        startAdornment={
                                            <ArgonBox className="input-icon">
                                                <ArgonBox component="img" src={cvvIcon} />
                                            </ArgonBox>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </ArgonBox>
                        <ArgonBox mt={10}>
                            <ArgonButton
                                className="dialog-button"
                                fullWidth
                                onClick={handlePayConfirm}
                            >
                                Continue
                            </ArgonButton>
                        </ArgonBox>
                    </DialogContent>
                </>
            )}

            <Dialog open={showPayConfirm} onClose={handlePayConfirmClose}>
                <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
                    Are you sure you want to deposit?
                </DialogTitle>
                <DialogActions>
                    <ArgonButton variant="text" color="secondary" onClick={handlePayConfirmClose}>
                        No
                    </ArgonButton>
                    <ArgonButton variant="text" color="primary" onClick={handlePay} autoFocus>
                        Yes
                    </ArgonButton>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}

export default DepositBankSide;
