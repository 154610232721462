import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { InputLabel } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";

import { resetPassword } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.token && params.token != "") {
            setToken(params.token);
        }
    }, []);

    const resetPasswordMutation = useMutation(resetPassword, {
        onSuccess: (response) => {
            if (response?.data?.status) {
                SnackbarUtils.success(response.data.message);
                window.location.replace("/login");
            }
        },
    });
    const handleResetPassword = () => {
        const payload = {
            token: token,
            password: password,
            confirm_password: confirmPassword,
        };
        resetPasswordMutation.mutate(payload);
    };

    return (
        <IllustrationLayout
            title="Reset Password"
            illustration={{
                image: bgImage,
            }}
        >
            <ArgonBox component="form" role="form">
                <ArgonBox>
                    <InputLabel
                        style={{
                            fontSize: "14px",
                            lineHeight: "19px",
                            fontWeight: "700",
                            color: "#717579",
                            marginBottom: "4px",
                        }}
                    >
                        Password
                    </InputLabel>
                    <ArgonInput
                        type="password"
                        placeholder="Password"
                        size="large"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </ArgonBox>
                <ArgonBox mt={1.5}>
                    <InputLabel
                        style={{
                            fontSize: "14px",
                            lineHeight: "19px",
                            fontWeight: "700",
                            color: "#717579",
                            marginBottom: "4px",
                        }}
                    >
                        Confirm Password
                    </InputLabel>
                    <ArgonInput
                        type="password"
                        placeholder="Confirm Password"
                        size="large"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </ArgonBox>
                <ArgonBox mt={3}>
                    <ArgonButton
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handleResetPassword}
                    >
                        Reset Password
                    </ArgonButton>
                </ArgonBox>
            </ArgonBox>
        </IllustrationLayout>
    );
}
