/* eslint-disable react/prop-types */

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonButton from "components/ArgonButton";
// Images
import usdt from "assets/images/usdt.png";
import bnb from "assets/images/bnb.png";

function Portfolio({ image, symbol, network }) {
    return (
        <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
            <ArgonBox mr={2}>
                {/* <ArgonAvatar src={image} alt={name} size="sm" variant="rounded" /> */}
                <ArgonBox component="img" className="coin-image" src={image} />
            </ArgonBox>
            <ArgonBox display="flex" flexDirection="column">
                <ArgonTypography variant="button" fontWeight="medium">
                    {symbol}
                </ArgonTypography>
                <ArgonTypography variant="caption" color="secondary">
                    {network}
                </ArgonTypography>
            </ArgonBox>
        </ArgonBox>
    );
}
function WalletAddress({ address }) {
    return (
        <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="caption" fontWeight="medium">
                {address}
            </ArgonTypography>
        </ArgonBox>
    );
}
function Balance({ balance }) {
    return (
        <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="caption" fontWeight="medium">
                {balance}
            </ArgonTypography>
        </ArgonBox>
    );
}
function Action({ onDeposit, onWithdraw, onTransfer, item }) {
    return (
        <ArgonBox display="flex" flexDirection="row">
            <ArgonButton
                size="medium"
                fullWidth
                style={{
                    marginLeft: "12px",
                    color: "white",
                    background: "#546BEA",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "700",
                }}
                onClick={(e) => onDeposit(item)}
            >
                Deposit
            </ArgonButton>
            <ArgonButton
                size="medium"
                fullWidth
                style={{
                    marginLeft: "12px",
                    color: "white",
                    background: "#546BEA",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "700",
                }}
                onClick={(e) => onWithdraw(item)}
            >
                Withdraw
            </ArgonButton>
            <ArgonButton
                size="medium"
                fullWidth
                style={{
                    marginLeft: "12px",
                    color: "white",
                    background: "#546BEA",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "700",
                }}
                onClick={(e) => onTransfer(item)}
            >
                Transfer
            </ArgonButton>
        </ArgonBox>
    );
}
export const generateRows = (rows, handleDeposit, handleWithdraw, handleTransfer) => {
    return rows.map((row) => ({
        Portfolio: <Portfolio image={row.image_url} symbol={row.symbol} network={row.name} />,
        "Wallet Address": <WalletAddress address={row.wallet} />,
        Balance: <Balance balance={"$ " + row.balance} />,
        "": (
            <Action
                onDeposit={handleDeposit}
                onWithdraw={handleWithdraw}
                onTransfer={handleTransfer}
                item={row}
            />
        ),
    }));
};
export const columns = [
    { name: "Portfolio", align: "left" },
    { name: "Wallet Address", align: "left" },
    { name: "Balance", align: "center" },
    { name: "", align: "center" },
];
