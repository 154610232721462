import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;
const { borderRadius } = borders;

const sidenav = {
    styleOverrides: {
        root: {
            width: pxToRem(256),
            whiteSpace: "nowrap",
            border: "none",
        },

        paper: {
            width: pxToRem(256),
            backgroundColor: white.main,
            height: `calc(100vh - ${pxToRem(32)})`,
            margin: `${pxToRem(16)} 0 ${pxToRem(16)} ${pxToRem(16)}`,
            borderRadius: borderRadius.xl,
            border: "none",
        },

        paperAnchorDockedLeft: {
            borderRight: "none",
        },
    },
};

export default sidenav;
